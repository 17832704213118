import $ from "jquery";
import { Dropdown } from 'bootstrap';

class NodesConnectionInstanceView {

    #fedgeTypeDropdownSelection = [];
    #sedgeTypeDropdownSelection = [];
    #textEditEventListeners = [];
    dropdownList;

    notifyFirstEdgeTypeSelectionEvent(selection) {
        this.#fedgeTypeDropdownSelection.forEach(listener => listener(selection));
    }

    notifySecondEdgeTypeSelectionEvent(selection) {
        this.#sedgeTypeDropdownSelection.forEach(listener => listener(selection));
    }

    notifyTextEditEventListeners(edgeIndex, key, value) {
        this.#textEditEventListeners.forEach(listener => listener(edgeIndex, key, value));
    }

    init() {
        var dropdownElementList = $("#nodes-connection-instance-page").find('.dropdown-toggle');
        this.dropdownList = dropdownElementList.each(function (index, dropdownToggleEl) {
            return new Dropdown(dropdownToggleEl);
        });
        $("#nodes-connection-instance-page").find("#nodes-connection-fedge-type-dropdown").find('.dropdown-item').click((e) => {
            var buttonText = $(e.target).text();
            $('#nodes-connection-fedge-type-dropdown-button').text(buttonText);

            this.notifyFirstEdgeTypeSelectionEvent(buttonText);
        });
        $("#nodes-connection-instance-page").find("#nodes-connection-sedge-type-dropdown").find('.dropdown-item').click((e) => {
            var buttonText = $(e.target).text();
            $('#nodes-connection-sedge-type-dropdown-button').text(buttonText);

            this.notifySecondEdgeTypeSelectionEvent(buttonText);
        });
        $("#nodes-connection-fedge-weight-input-field").on('input', (event) => {
            this.notifyTextEditEventListeners(0, 'weight', $(event.target).val());
        });
        $("#nodes-connection-fedge-name-input-field").on('input', (event) => {
            this.notifyTextEditEventListeners(0, 'name', $(event.target).val());
        });
        $("#nodes-connection-fedge-difficulty-input-field").on('input', (event) => {
            this.notifyTextEditEventListeners(0, 'difficulty', $(event.target).val());
        });


    }

    renderNodesConnectionDataFields(nodesConnection) {
        console.log(nodesConnection);
        const firstNodeID = nodesConnection.data.firstNode.id;
        const secondNodeID = nodesConnection.data.secondNode.id;
        
        const bidirectional = nodesConnection.data.bidirectional;

        const firstEdgeWeight = nodesConnection.data?.properties[0]?.weight || null;
        const firstEdgeType = nodesConnection.data?.properties[0]?.type || null;
        const firstEdgeName = nodesConnection.data?.properties[0]?.name || null;
        const firstEdgeDifficulty = nodesConnection.data.properties[0]?.difficulty;

        const secondEdgeWeight = nodesConnection.data?.properties[1]?.weight || null;
        const secondEdgeType = nodesConnection.data?.properties[1]?.type || null;
        const secondEdgeName = nodesConnection.data?.properties[1]?.name || null;

        $('#nodes-connection-fn-id-input-field').val(firstNodeID);
        $('#nodes-connection-sn-id-input-field').val(secondNodeID);

        $('#nodes-connection-fedge-id-input-field').val(firstNodeID + " ➡️ " + secondNodeID);
        $('#nodes-connection-fedge-weight-input-field').val(firstEdgeWeight);
        $('#nodes-connection-fedge-type-dropdown-button').text(firstEdgeType);
        $('#nodes-connection-fedge-name-input-field').val(firstEdgeName);
        $('#nodes-connection-fedge-difficulty-input-field').val(firstEdgeDifficulty);

        if (bidirectional) {
            $('#nodes-connection-sedge').show();
            $('#nodes-connection-sedge-id-input-field').val(secondNodeID + " ➡️ " + firstNodeID);
            $('#nodes-connection-sedge-weight-input-field').val(secondEdgeWeight);
            $('#nodes-connection-sedge-type-dropdown-button').text(firstEdgeType);
            $('#nodes-connection-sedge-name-input-field').val(secondEdgeName);
        }else{
            $('#nodes-connection-sedge').hide();
        }
    }

    setOnFirstEdgeTypeSelectionEvent(listener) {
        this.#fedgeTypeDropdownSelection.push(listener);
    }

    setOnSecondEdgeTypeSelectionEvent(listener) {
        this.#sedgeTypeDropdownSelection.push(listener);
    }

    setOnTextEditEvent(listener) {
        this.#textEditEventListeners.push(listener)
    }

}

export default NodesConnectionInstanceView;