
import SkiResortModel from './models/SkiResortModel.js';
import HomeController from './controllers/HomeController.js'
import MapController from './controllers/MapController.js'
import GraphModelController from './controllers/GraphModelController.js'
import SimulationController from './controllers/SimulationController.js';
import NavigationBarController from './controllers/NavigationBarController.js';
import NodesConnectionInstanceController from './controllers/NodesConnectionInstanceController.js';
import NodeInstanceController from './controllers/NodeInstanceController.js';
import InstanceNavigationController from './controllers/InstanceNavigationController.js';
import { Node, NodesConnection } from './models/SkiResortModel.js'

import * as GeoTIFF from 'geotiff';

import skiTrailsRawData from '../assets/lines.json';
import skiLiftsRawData from '../assets/points.json';
import kopaonikModelRawData from '../assets/Kopaonik_Model_2.1.json';

import elevationData from '../assets/KopaonikElevationClipped.tif';
import SkiResortSimulationModel from './models/SkiResortSimulationModel.js';

//ski trails
var skiResortModel = new SkiResortModel();
skiResortModel.skiTrails = skiTrailsRawData.features.map(skiTrailRawData => {
    skiTrailRawData.properties.visible = true;
    skiTrailRawData.properties.filtered = false;
    return skiTrailRawData;
});

//ski lifts
skiResortModel.skiLifts = Object.values(skiLiftsRawData.features.reduce(
    (skiLifts, skiLiftRaw) => {

        if (skiLifts[skiLiftRaw.properties.name]) {
            skiLifts[skiLiftRaw.properties.name].push(skiLiftRaw);
        }else{
            skiLifts[skiLiftRaw.properties.name] = [skiLiftRaw];
        }

        return skiLifts;
    },
    {}
)).map(circleFeatures => {
    var lineStringFeature = {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: circleFeatures.map(circleFeature => circleFeature.geometry.coordinates)
        },
        properties: {
            name: circleFeatures[0].properties.name,
            types: circleFeatures.map(cf => cf.properties['aerialway:access'])
        }
    };

    return lineStringFeature;
})

var skiResortSimulationModel = new SkiResortSimulationModel();

//elevation data
const loadElevationData = async() => {
    // Fetch the file as a Blob
    const response = await fetch(elevationData);
    const blob = await response.blob();

    // Load the GeoTIFF
    const tiff = await GeoTIFF.fromBlob(blob);

    const image = await tiff.getImage();
    const raster = await image.readRasters();

    const width = image.getWidth();
    const height = image.getHeight();

    const geoKeys = image.getGeoKeys();
    const tiepoints = image.fileDirectory.ModelTiepoint;
    const scales = image.fileDirectory.ModelPixelScale;

    if (!tiepoints || !scales) {
        console.error('GeoKeys are missing necessary values.');
        return;
    }

    const [i, j, k, x, y, z] = tiepoints;
    const [scaleX, scaleY, scaleZ] = scales;

    // Create a 2D array to hold the lat/long coordinates for each data point
    let coordinates = Array(height).fill().map(() => Array(width));

    for(let row = 0; row < height; row++) {
        for(let col = 0; col < width; col++) {
            const lat = y - (row * scaleY);
            const lng = x + (col * scaleX);
            const elevation = raster[0][row * width + col];
            coordinates[row][col] = { lat, lng, elevation };
        }
    }

    skiResortModel.elevationGrid = coordinates;
};

var mapController = new MapController();
const homeController = new HomeController();
const graphModelController = new GraphModelController();
const simulationController = new SimulationController();

homeController.setSkiResortModel(skiResortModel);
graphModelController.setSkiResortModel(skiResortModel);
simulationController.setSkiResortModel(skiResortModel);
mapController.setSkiResortModel(skiResortModel);

simulationController.setSkiResortSimulationModel(skiResortSimulationModel);
mapController.setSkiResortSimulationModel(skiResortSimulationModel)

var navigationBarController = new NavigationBarController([
    ['home-page', 'Home', homeController], 
    ['graph-model-page', 'Model', graphModelController],
    ['simulation-page', 'Simulation', simulationController]
]);

const nodesConnectionInstanceController = new NodesConnectionInstanceController();
const nodeInstanceController = new NodeInstanceController();

nodesConnectionInstanceController.setSkiResortModel(skiResortModel);
nodeInstanceController.setSkiResortModel(skiResortModel);

var instanceNavigationController = new InstanceNavigationController([
    ['nodes-connection-instance-page', 'Nodes Connection', nodesConnectionInstanceController, NodesConnection],
    ['node-instance-page', 'Node', nodeInstanceController, Node]
]);

instanceNavigationController.setSkiResortModel(skiResortModel);

document.addEventListener('DOMContentLoaded', async() => {
    await loadElevationData();

    mapController.init();
    navigationBarController.init();
    instanceNavigationController.init();

    skiResortModel.graph.subscribe(async (change) => {
        const graphJSON = await skiResortModel.graph.getJSON();
        localStorage.setItem('graph', graphJSON);
    });

    //Switch here
    // const graphJSON = localStorage.getItem('graph');
    const graphJSON = JSON.stringify(kopaonikModelRawData);
    skiResortModel.graph.loadFromJSON(graphJSON);
});
