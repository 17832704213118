class Observable {
    constructor(initialValue) {
        this.value = initialValue;
        this.listeners = [];
    }

    getValue() {
        return this.value;
    }

    setValue(newValue) {
        this.value = newValue;
        this.notify(newValue);
    }

    subscribe(listener) {
        if(typeof listener === 'function') {
            this.listeners.push(listener);
        } else {
            throw new Error("Listener should be a function!")
        }
    }

    unsubscribe(listener) {
        this.listeners = this.listeners.filter(l => l !== listener);
    }

    notify(data) {
        this.listeners.forEach(listener => listener(data));
    }
}

export default Observable;