
class InstanceNavigationModel {
    pages
    #activePageChangeListeners = [];

    constructor(pages) {
        this.pages = pages;
    }
    
    init() {
    }

    setOnPageChangeEventListener(listener) {
        this.#activePageChangeListeners.push(listener);
    }

    setActivePage(pageID) {
        this.#activePageChangeListeners.forEach(listener => listener(pageID));
    }

}

export default InstanceNavigationModel;