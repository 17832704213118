import $ from "jquery";

class NavigationBarView {
    #clickEventListeners = [];

    init() {
    }

    renderButtons(pages) {
        //create button for each page
        for (const page of pages) {
            const pageID = page[0];
            const pageTitle = page[1];

            var buttonElement = $(`
            <button id="${pageID}-navigation-bar-button" data-page-id="${pageID}" type="button" class="btn btn-primary mx-2">${pageTitle}</button>
            `)

            buttonElement.on('click', (e) => this.onNavBarButtonClick($(e.target)));

            $('#navigation-bar-buttons').append(buttonElement);
        }
    }

    renderPage(pages, pageID) {
        for (const page of pages) {
            let pageID = page[0];
            
            const pageElement = $(`#${pageID}`);
            pageElement.css("visibility", "hidden");
            pageElement.hide();

            const buttonElement = $(`#${pageID}-navigation-bar-button`);
            buttonElement.addClass('btn-dark');
            buttonElement.removeClass('btn-primary');
        }

        const activePageElement = $(`#${pageID}`);
        activePageElement.css("visibility", "visible");
        activePageElement.show();
        
        const activeButtonElement = $(`#${pageID}-navigation-bar-button`);
        activeButtonElement.addClass('btn-primary');
        activeButtonElement.removeClass('btn-dark');
    }

    setOnClickEventListener(listener) {
        this.#clickEventListeners.push(listener);
    }

    onNavBarButtonClick(button) {
        const pageID = button.data('page-id');
        this.#clickEventListeners.forEach(listener => listener(pageID));
    }

}

export default NavigationBarView;