{
  "nodes": {
    "2ykjwcb5": {
      "coords": [
        43.2807336,
        20.8116405
      ]
    },
    "ephltv8y": {
      "coords": [
        43.2861949,
        20.8240412
      ]
    },
    "5rolwty6": {
      "coords": [
        43.3162257,
        20.8241917
      ]
    },
    "bh159i7r": {
      "coords": [
        43.2928983,
        20.8351099
      ]
    },
    "gstm5po8": {
      "coords": [
        43.2935779,
        20.8351398
      ]
    },
    "gzcnfsc": {
      "coords": [
        43.2984727,
        20.8398175
      ]
    },
    "7q6c58vx": {
      "coords": [
        43.2723051,
        20.8061416
      ]
    },
    "g7hxx6mo": {
      "coords": [
        43.27272,
        20.8057927
      ]
    },
    "kyj3ixoc": {
      "coords": [
        43.2859158,
        20.8222742
      ]
    },
    "ap9b4f93": {
      "coords": [
        43.279521,
        20.832837
      ]
    },
    "fldtmpp8": {
      "coords": [
        43.3209096,
        20.8333893
      ]
    },
    "c2owc27d": {
      "coords": [
        43.2843948,
        20.8077541
      ]
    },
    "3whp6ks1": {
      "coords": [
        43.2953751,
        20.8349191
      ]
    },
    "8q9twuc8": {
      "coords": [
        43.2931496,
        20.8289105
      ]
    },
    "hpy8swel": {
      "coords": [
        43.2966127,
        20.842409
      ]
    },
    "kqowivk1": {
      "coords": [
        43.2879769,
        20.8314041
      ]
    },
    "9zv9bf65": {
      "coords": [
        43.3004369,
        20.8377464
      ]
    },
    "dabqvt0g": {
      "coords": [
        43.2780866,
        20.81833
      ]
    },
    "8cmo6s4e": {
      "coords": [
        43.2741578,
        20.8121756
      ]
    },
    "h5dbsifb": {
      "coords": [
        43.283806,
        20.8106451
      ]
    },
    "6hl8klnx": {
      "coords": [
        43.2791662,
        20.8206246
      ]
    },
    "a873p1z0": {
      "coords": [
        43.2815811,
        20.8161959
      ]
    },
    "aupi9mvn": {
      "coords": [
        43.2946733,
        20.8256923
      ]
    },
    "2ipssokt": {
      "coords": [
        43.2757165,
        20.8198419
      ]
    },
    "60rt1k6r": {
      "coords": [
        43.2966036,
        20.8282103
      ]
    },
    "f584dhmv": {
      "coords": [
        43.2803261,
        20.806546
      ]
    },
    "csx8lpzt": {
      "coords": [
        43.280351,
        20.8066641
      ]
    },
    "1pav65go": {
      "coords": [
        43.280936,
        20.8068291
      ]
    },
    "5c3t92pd": {
      "coords": [
        43.2817643,
        20.8071302
      ]
    },
    "4zq9055n": {
      "coords": [
        43.2819779,
        20.8072102
      ]
    },
    "h5m9t68z": {
      "coords": [
        43.2821955,
        20.8072899
      ]
    },
    "fzhf9wci": {
      "coords": [
        43.2826785,
        20.8074564
      ]
    },
    "ea8kd6il": {
      "coords": [
        43.2834194,
        20.8076537
      ]
    },
    "5tcqox1v": {
      "coords": [
        43.2843478,
        20.8079362
      ]
    },
    "518ozcjv": {
      "coords": [
        43.2844476,
        20.8078993
      ]
    },
    "abvxq1yi": {
      "coords": [
        43.2730525,
        20.8203203
      ]
    },
    "ilgu2x77": {
      "coords": [
        43.2733939,
        20.8205933
      ]
    },
    "6jvv73dh": {
      "coords": [
        43.2736537,
        20.8208203
      ]
    },
    "1wv4qa68": {
      "coords": [
        43.2738806,
        20.8210565
      ]
    },
    "e1oyct6p": {
      "coords": [
        43.2747721,
        20.8219968
      ]
    },
    "jyb7ynpc": {
      "coords": [
        43.2752202,
        20.8225265
      ]
    },
    "gnpkdjj9": {
      "coords": [
        43.2756596,
        20.8230796
      ]
    },
    "9i3ktzuh": {
      "coords": [
        43.2761872,
        20.8238998
      ]
    },
    "dov5tqo0": {
      "coords": [
        43.2764773,
        20.8242352
      ]
    },
    "gabh6vgj": {
      "coords": [
        43.2770227,
        20.8248196
      ]
    },
    "fxkmhb80": {
      "coords": [
        43.2771215,
        20.824937
      ]
    },
    "6dhihpqz": {
      "coords": [
        43.2774296,
        20.8253079
      ]
    },
    "8z7ruz8c": {
      "coords": [
        43.2776501,
        20.8256145
      ]
    },
    "ep8odf65": {
      "coords": [
        43.277924,
        20.8259827
      ]
    },
    "18npl1sd": {
      "coords": [
        43.2780748,
        20.8262215
      ]
    },
    "kg9zvjir": {
      "coords": [
        43.2781941,
        20.826447
      ]
    },
    "rvcawtm": {
      "coords": [
        43.2783668,
        20.826938
      ]
    },
    "kajfle5i": {
      "coords": [
        43.2784591,
        20.8271741
      ]
    },
    "ac7nublz": {
      "coords": [
        43.2786454,
        20.8276937
      ]
    },
    "7vuzm0zq": {
      "coords": [
        43.2788402,
        20.8282204
      ]
    },
    "j4fe0n9f": {
      "coords": [
        43.2791261,
        20.8289051
      ]
    },
    "j8d0qdg8": {
      "coords": [
        43.2793079,
        20.8292713
      ]
    },
    "6f8uq1l7": {
      "coords": [
        43.2794246,
        20.8295835
      ]
    },
    "bski1ru2": {
      "coords": [
        43.2795484,
        20.8300032
      ]
    },
    "3jst6zae": {
      "coords": [
        43.2796249,
        20.8306
      ]
    },
    "e6nq1y6p": {
      "coords": [
        43.2796353,
        20.8312125
      ]
    },
    "8t2bm4da": {
      "coords": [
        43.2796864,
        20.8320962
      ]
    },
    "ee1zvvob": {
      "coords": [
        43.2797525,
        20.8324011
      ]
    },
    "ldo3e65u": {
      "coords": [
        43.2798918,
        20.8326404
      ]
    },
    "bysh2ubi": {
      "coords": [
        43.2782504,
        20.8116018
      ]
    },
    "1glb3ejn": {
      "coords": [
        43.278551,
        20.8115921
      ]
    },
    "bgz7bcie": {
      "coords": [
        43.27905,
        20.8114921
      ]
    },
    "80ja29au": {
      "coords": [
        43.2794489,
        20.8113888
      ]
    },
    "7yhub2um": {
      "coords": [
        43.2797942,
        20.8113558
      ]
    },
    "cb3au05l": {
      "coords": [
        43.2801545,
        20.8114523
      ]
    },
    "41b1hjcg": {
      "coords": [
        43.2804502,
        20.811585
      ]
    },
    "9eac0dyq": {
      "coords": [
        43.2766002,
        20.8140033
      ]
    },
    "2ik1j3yg": {
      "coords": [
        43.2771155,
        20.8139478
      ]
    },
    "37b3gh74": {
      "coords": [
        43.2776207,
        20.8137672
      ]
    },
    "c4p4v35u": {
      "coords": [
        43.2782451,
        20.8135647
      ]
    },
    "7qm9v12m": {
      "coords": [
        43.2787586,
        20.8135102
      ]
    },
    "jjs5yrsq": {
      "coords": [
        43.2792317,
        20.813647
      ]
    },
    "lkqdxb91": {
      "coords": [
        43.2796494,
        20.8136891
      ]
    },
    "9o42fyhm": {
      "coords": [
        43.2799606,
        20.8136782
      ]
    },
    "bgzs1lxn": {
      "coords": [
        43.2801518,
        20.8136715
      ]
    },
    "bzj317ix": {
      "coords": [
        43.2806761,
        20.8135459
      ]
    },
    "l3ff3eck": {
      "coords": [
        43.2808082,
        20.8134724
      ]
    },
    "7twos5nr": {
      "coords": [
        43.280988,
        20.8132773
      ]
    },
    "389nkr8h": {
      "coords": [
        43.2814847,
        20.8127264
      ]
    },
    "kd793kp4": {
      "coords": [
        43.2823706,
        20.8118973
      ]
    },
    "68zkfrsm": {
      "coords": [
        43.2825612,
        20.8115897
      ]
    },
    "ez06xdpa": {
      "coords": [
        43.2827265,
        20.8111905
      ]
    },
    "1nvh0hi3": {
      "coords": [
        43.2743495,
        20.817706
      ]
    },
    "ghcio01u": {
      "coords": [
        43.2742652,
        20.8168637
      ]
    },
    "xgoy3u3": {
      "coords": [
        43.274242,
        20.8161693
      ]
    },
    "elxuickb": {
      "coords": [
        43.2743115,
        20.8157933
      ]
    },
    "ig7yxb79": {
      "coords": [
        43.2755952,
        20.8128437
      ]
    },
    "hgrbx0nh": {
      "coords": [
        43.2763365,
        20.8113562
      ]
    },
    "40afn7py": {
      "coords": [
        43.2769248,
        20.8103576
      ]
    },
    "6ho2m33e": {
      "coords": [
        43.2771406,
        20.8099621
      ]
    },
    "4a1ewbaj": {
      "coords": [
        43.2778701,
        20.8088797
      ]
    },
    "6ztp9onh": {
      "coords": [
        43.2784036,
        20.8081331
      ]
    },
    "bbhpn5uj": {
      "coords": [
        43.2787452,
        20.8077917
      ]
    },
    "6hrv2zqz": {
      "coords": [
        43.2789948,
        20.8075422
      ]
    },
    "29fuek5": {
      "coords": [
        43.2791937,
        20.8072665
      ]
    },
    "3q3vpwab": {
      "coords": [
        43.2914086,
        20.829784
      ]
    },
    "h57898bw": {
      "coords": [
        43.2911184,
        20.8300197
      ]
    },
    "6csu3vmq": {
      "coords": [
        43.2908995,
        20.830137
      ]
    },
    "bn1ufltc": {
      "coords": [
        43.2907506,
        20.8303176
      ]
    },
    "7sy1hg0e": {
      "coords": [
        43.2903627,
        20.8308637
      ]
    },
    "4cdn3zz9": {
      "coords": [
        43.2895212,
        20.8319872
      ]
    },
    "ikg54l5k": {
      "coords": [
        43.2887589,
        20.833031
      ]
    },
    "liqv0u2g": {
      "coords": [
        43.2882348,
        20.8337669
      ]
    },
    "9bmduhk8": {
      "coords": [
        43.2878733,
        20.8343142
      ]
    },
    "7bx5ujcd": {
      "coords": [
        43.2874117,
        20.8350272
      ]
    },
    "h80ck36y": {
      "coords": [
        43.2870154,
        20.8355989
      ]
    },
    "l9kx47i0": {
      "coords": [
        43.2866962,
        20.8360193
      ]
    },
    "h8e8wx1r": {
      "coords": [
        43.2864277,
        20.8364847
      ]
    },
    "caoh7i39": {
      "coords": [
        43.286355,
        20.8366044
      ]
    },
    "whyzceg": {
      "coords": [
        43.2865023,
        20.8366235
      ]
    },
    "liqpda8": {
      "coords": [
        43.2923689,
        20.8289765
      ]
    },
    "8wlz2cen": {
      "coords": [
        43.2923576,
        20.8287783
      ]
    },
    "7oery7cy": {
      "coords": [
        43.2923323,
        20.8285193
      ]
    },
    "765mpfo4": {
      "coords": [
        43.2923088,
        20.8282577
      ]
    },
    "b3tjb4h5": {
      "coords": [
        43.2922646,
        20.8279939
      ]
    },
    "ayuvbpxl": {
      "coords": [
        43.2922161,
        20.8276984
      ]
    },
    "5lta0whb": {
      "coords": [
        43.2921276,
        20.8266327
      ]
    },
    "gm5tfs9f": {
      "coords": [
        43.2919451,
        20.8259356
      ]
    },
    "fdr8dv1s": {
      "coords": [
        43.2919203,
        20.8258346
      ]
    },
    "lb42p5co": {
      "coords": [
        43.2917596,
        20.8254106
      ]
    },
    "34ty9hx": {
      "coords": [
        43.2916475,
        20.825109
      ]
    },
    "ayqd93u": {
      "coords": [
        43.2915092,
        20.824508
      ]
    },
    "fz9nqobt": {
      "coords": [
        43.2914262,
        20.8241955
      ]
    },
    "79ru61t3": {
      "coords": [
        43.2913187,
        20.8239453
      ]
    },
    "a6aytwrd": {
      "coords": [
        43.2912582,
        20.8238489
      ]
    },
    "8lji11gh": {
      "coords": [
        43.2910606,
        20.8235341
      ]
    },
    "40unytur": {
      "coords": [
        43.2908987,
        20.8233164
      ]
    },
    "juacx9r8": {
      "coords": [
        43.2907995,
        20.8231467
      ]
    },
    "adw4myou": {
      "coords": [
        43.2906981,
        20.8228186
      ]
    },
    "c7ofrfij": {
      "coords": [
        43.2906327,
        20.8224403
      ]
    },
    "dd2bzsk3": {
      "coords": [
        43.2906035,
        20.8220687
      ]
    },
    "hu7x2di": {
      "coords": [
        43.2905633,
        20.8217267
      ]
    },
    "a65e4nig": {
      "coords": [
        43.2905055,
        20.8214809
      ]
    },
    "8bagyrbm": {
      "coords": [
        43.2904198,
        20.8211495
      ]
    },
    "j8mplg34": {
      "coords": [
        43.290334,
        20.8209474
      ]
    },
    "1spi2im8": {
      "coords": [
        43.2901894,
        20.8206709
      ]
    },
    "795o02v1": {
      "coords": [
        43.2899859,
        20.8202545
      ]
    },
    "bclp740y": {
      "coords": [
        43.2899018,
        20.8200933
      ]
    },
    "kg0uwh6y": {
      "coords": [
        43.2897827,
        20.8196971
      ]
    },
    "adpdqli1": {
      "coords": [
        43.2897411,
        20.8195476
      ]
    },
    "buj5xf55": {
      "coords": [
        43.2896425,
        20.8191929
      ]
    },
    "sefzzxj": {
      "coords": [
        43.2894723,
        20.8185078
      ]
    },
    "jqqnfutb": {
      "coords": [
        43.2892129,
        20.8173345
      ]
    },
    "6jb3b4d9": {
      "coords": [
        43.2891777,
        20.8171365
      ]
    },
    "73cbylrc": {
      "coords": [
        43.2917718,
        20.8348787
      ]
    },
    "dbx9vytn": {
      "coords": [
        43.2924211,
        20.8350173
      ]
    },
    "8c6bbspx": {
      "coords": [
        43.2932303,
        20.835126
      ]
    },
    "466c2pkb": {
      "coords": [
        43.2937013,
        20.8351447
      ]
    },
    "l5vatklh": {
      "coords": [
        43.2941232,
        20.8350908
      ]
    },
    "dn2h6clx": {
      "coords": [
        43.294698,
        20.8349206
      ]
    },
    "u6rtx0v": {
      "coords": [
        43.2950473,
        20.8348728
      ]
    },
    "hscr0jqw": {
      "coords": [
        43.2922741,
        20.8296441
      ]
    },
    "k6vuqg96": {
      "coords": [
        43.2922253,
        20.829913
      ]
    },
    "7aji5bza": {
      "coords": [
        43.2924227,
        20.8301903
      ]
    },
    "kivq7m1j": {
      "coords": [
        43.2926223,
        20.8305034
      ]
    },
    "7scvxfyp": {
      "coords": [
        43.2928942,
        20.8311287
      ]
    },
    "1fieb0e3": {
      "coords": [
        43.2932788,
        20.8318705
      ]
    },
    "fgxiykwt": {
      "coords": [
        43.2939706,
        20.8326421
      ]
    },
    "hgtuatyk": {
      "coords": [
        43.2945301,
        20.833437
      ]
    },
    "jxufn07v": {
      "coords": [
        43.2950045,
        20.8342642
      ]
    },
    "9uecbnti": {
      "coords": [
        43.2951972,
        20.8346409
      ]
    },
    "456vd27o": {
      "coords": [
        43.2955171,
        20.8351555
      ]
    },
    "3ga18i6": {
      "coords": [
        43.2958842,
        20.8357811
      ]
    },
    "efehflci": {
      "coords": [
        43.2961722,
        20.8363378
      ]
    },
    "k3qtzyvq": {
      "coords": [
        43.2962141,
        20.8364297
      ]
    },
    "e64jkg4j": {
      "coords": [
        43.2962796,
        20.8365134
      ]
    },
    "ixpn7uzl": {
      "coords": [
        43.2965372,
        20.8366715
      ]
    },
    "7t0besa7": {
      "coords": [
        43.2966659,
        20.83672
      ]
    },
    "c31oal3b": {
      "coords": [
        43.2967947,
        20.8367889
      ]
    },
    "aw72ptgh": {
      "coords": [
        43.2975092,
        20.8372449
      ]
    },
    "q2g9lyv": {
      "coords": [
        43.2976448,
        20.8372858
      ]
    },
    "ax2znljy": {
      "coords": [
        43.2983243,
        20.8374745
      ]
    },
    "gx96cbp0": {
      "coords": [
        43.2986817,
        20.8376004
      ]
    },
    "2wpkct5g": {
      "coords": [
        43.2994175,
        20.8376894
      ]
    },
    "7dcuhzm7": {
      "coords": [
        43.3001734,
        20.8377071
      ]
    },
    "2ors0yrd": {
      "coords": [
        43.3006284,
        20.8378703
      ]
    },
    "hptxsexu": {
      "coords": [
        43.3007879,
        20.8380301
      ]
    },
    "318fftrk": {
      "coords": [
        43.3009335,
        20.8383037
      ]
    },
    "4uggog47": {
      "coords": [
        43.3007614,
        20.8384112
      ]
    },
    "h0bikx45": {
      "coords": [
        43.2961808,
        20.8266832
      ]
    },
    "gjsyw06e": {
      "coords": [
        43.2963579,
        20.8265185
      ]
    },
    "ifn1oyd": {
      "coords": [
        43.2965112,
        20.8264287
      ]
    },
    "gid84gig": {
      "coords": [
        43.2966274,
        20.8263375
      ]
    },
    "jovuiw82": {
      "coords": [
        43.296741,
        20.826188
      ]
    },
    "62lnpl47": {
      "coords": [
        43.2968017,
        20.8260081
      ]
    },
    "4i7zt29l": {
      "coords": [
        43.29682,
        20.8257555
      ]
    },
    "4igyrg3": {
      "coords": [
        43.2967736,
        20.8255013
      ]
    },
    "9gz9q4mv": {
      "coords": [
        43.2965953,
        20.8249437
      ]
    },
    "lnpl8tl": {
      "coords": [
        43.2964912,
        20.8245999
      ]
    },
    "jj1jvziw": {
      "coords": [
        43.2963974,
        20.8242608
      ]
    },
    "d2wu0y46": {
      "coords": [
        43.2962773,
        20.8239049
      ]
    },
    "f45yg9ui": {
      "coords": [
        43.2961145,
        20.8235357
      ]
    },
    "3v9axeu5": {
      "coords": [
        43.2959097,
        20.8231591
      ]
    },
    "eobclln3": {
      "coords": [
        43.2955315,
        20.8223283
      ]
    },
    "2hhdjc2z": {
      "coords": [
        43.2953465,
        20.8218916
      ]
    },
    "epl9a13s": {
      "coords": [
        43.295265,
        20.8216196
      ]
    },
    "g6ca8sip": {
      "coords": [
        43.2952085,
        20.8213817
      ]
    },
    "b0pd5anj": {
      "coords": [
        43.2951169,
        20.8211591
      ]
    },
    "93yhf8s8": {
      "coords": [
        43.2950036,
        20.8208735
      ]
    },
    "ghqe38zm": {
      "coords": [
        43.2949346,
        20.8205855
      ]
    },
    "u8x5ec3": {
      "coords": [
        43.2948914,
        20.8202098
      ]
    },
    "ion5r95m": {
      "coords": [
        43.2948791,
        20.8199849
      ]
    },
    "ip6iq9rj": {
      "coords": [
        43.2948278,
        20.8197604
      ]
    },
    "20mae6pk": {
      "coords": [
        43.2946993,
        20.8195083
      ]
    },
    "klvq9ixr": {
      "coords": [
        43.2944801,
        20.8192374
      ]
    },
    "gyu8hpec": {
      "coords": [
        43.2943589,
        20.8191244
      ]
    },
    "4wskd5kh": {
      "coords": [
        43.2942819,
        20.8190072
      ]
    },
    "l0k3lack": {
      "coords": [
        43.2942086,
        20.8188435
      ]
    },
    "9c3od4un": {
      "coords": [
        43.2940899,
        20.818491
      ]
    },
    "jbn3gigu": {
      "coords": [
        43.2939605,
        20.817996
      ]
    },
    "jhwg2lz4": {
      "coords": [
        43.2938683,
        20.8177784
      ]
    },
    "eyfqd1f3": {
      "coords": [
        43.2937484,
        20.8175577
      ]
    },
    "ka8856a9": {
      "coords": [
        43.2792475,
        20.8077546
      ]
    },
    "bz21em7g": {
      "coords": [
        43.2793143,
        20.8074405
      ]
    },
    "f9l8j3dy": {
      "coords": [
        43.2797468,
        20.8067571
      ]
    },
    "1obg4sd6": {
      "coords": [
        43.2801954,
        20.805605
      ]
    },
    "jlltzjfw": {
      "coords": [
        43.2804267,
        20.8048088
      ]
    },
    "adtk0s1h": {
      "coords": [
        43.2806764,
        20.8040481
      ]
    },
    "498iojn9": {
      "coords": [
        43.2808827,
        20.8034811
      ]
    },
    "3e5pf1yk": {
      "coords": [
        43.2809162,
        20.803291
      ]
    },
    "1u3whqi7": {
      "coords": [
        43.2809438,
        20.8028698
      ]
    },
    "ekcc3793": {
      "coords": [
        43.2809081,
        20.8018438
      ]
    },
    "h253bz28": {
      "coords": [
        43.28093,
        20.800845
      ]
    },
    "8ws9tlgv": {
      "coords": [
        43.2810092,
        20.8000696
      ]
    },
    "9xxexrak": {
      "coords": [
        43.2811528,
        20.799274
      ]
    },
    "7zgamxfj": {
      "coords": [
        43.2812516,
        20.7988577
      ]
    },
    "9wje0rg4": {
      "coords": [
        43.2813902,
        20.7984747
      ]
    },
    "2q19qnzo": {
      "coords": [
        43.2814596,
        20.7983062
      ]
    },
    "jr3xzvrt": {
      "coords": [
        43.2814972,
        20.7980446
      ]
    },
    "bomz1c7i": {
      "coords": [
        43.2816315,
        20.7973611
      ]
    },
    "97adle0h": {
      "coords": [
        43.2817451,
        20.8148414
      ]
    },
    "2bomvkd": {
      "coords": [
        43.2818351,
        20.8148881
      ]
    },
    "6sdrw3b7": {
      "coords": [
        43.2818965,
        20.8146087
      ]
    },
    "43wozpjt": {
      "coords": [
        43.2819776,
        20.8142676
      ]
    },
    "9umeqerg": {
      "coords": [
        43.2820762,
        20.8138928
      ]
    },
    "gg762zhu": {
      "coords": [
        43.2822624,
        20.8133842
      ]
    },
    "hifuyeol": {
      "coords": [
        43.2824885,
        20.8128203
      ]
    },
    "ce9hngz": {
      "coords": [
        43.2825274,
        20.8126875
      ]
    },
    "4xsilxe2": {
      "coords": [
        43.2826821,
        20.8126243
      ]
    },
    "du1i6h2r": {
      "coords": [
        43.2828401,
        20.8125493
      ]
    },
    "8b2a1rrb": {
      "coords": [
        43.2829198,
        20.8125347
      ]
    },
    "bmalbd2d": {
      "coords": [
        43.2830116,
        20.8125562
      ]
    },
    "1in2pdzf": {
      "coords": [
        43.2831653,
        20.8126378
      ]
    },
    "ghupt1f9": {
      "coords": [
        43.2832736,
        20.8127682
      ]
    },
    "k6tlis2k": {
      "coords": [
        43.2834019,
        20.8128169
      ]
    },
    "2u4ts622": {
      "coords": [
        43.2835751,
        20.8128224
      ]
    },
    "bvwjj5nq": {
      "coords": [
        43.2855455,
        20.8266269
      ]
    },
    "2ygfrl5": {
      "coords": [
        43.2857312,
        20.8270201
      ]
    },
    "4v6l0l60": {
      "coords": [
        43.2857866,
        20.8267207
      ]
    },
    "1y3zlkpo": {
      "coords": [
        43.2859531,
        20.8261876
      ]
    },
    "dafkb3vy": {
      "coords": [
        43.2861294,
        20.8254098
      ]
    },
    "7qh0bj3z": {
      "coords": [
        43.286196,
        20.824864
      ]
    },
    "2x3mwkju": {
      "coords": [
        43.2862071,
        20.8243744
      ]
    },
    "g2s7aqkx": {
      "coords": [
        43.2860213,
        20.8229764
      ]
    },
    "7j5chg4p": {
      "coords": [
        43.2859117,
        20.822067
      ]
    },
    "kec1fb6c": {
      "coords": [
        43.2859023,
        20.8219153
      ]
    },
    "gknacfrh": {
      "coords": [
        43.2858604,
        20.8217537
      ]
    },
    "3uye6a8z": {
      "coords": [
        43.2856247,
        20.8212396
      ]
    },
    "hgl94oa1": {
      "coords": [
        43.285191,
        20.8204585
      ]
    },
    "5etqjvu4": {
      "coords": [
        43.2849235,
        20.8198626
      ]
    },
    "32a5p3te": {
      "coords": [
        43.2843815,
        20.818474
      ]
    },
    "8oj1h6v5": {
      "coords": [
        43.2843248,
        20.8183773
      ]
    },
    "2b42i3hw": {
      "coords": [
        43.2841728,
        20.8181184
      ]
    },
    "g2o8uwy4": {
      "coords": [
        43.2839474,
        20.8178518
      ]
    },
    "eb120vxw": {
      "coords": [
        43.2837992,
        20.8112406
      ]
    },
    "g0iqn11t": {
      "coords": [
        43.2838349,
        20.8107803
      ]
    },
    "as3n81ut": {
      "coords": [
        43.2837621,
        20.8104402
      ]
    },
    "5uqqf8id": {
      "coords": [
        43.2837095,
        20.8101438
      ]
    },
    "cycqnrkf": {
      "coords": [
        43.2836433,
        20.8099328
      ]
    },
    "3ln2nlgt": {
      "coords": [
        43.2835826,
        20.809601
      ]
    },
    "lb9np291": {
      "coords": [
        43.2834845,
        20.809386
      ]
    },
    "65e7apf8": {
      "coords": [
        43.2834724,
        20.8091657
      ]
    },
    "3q0bcb74": {
      "coords": [
        43.2834988,
        20.8090008
      ]
    },
    "gxnabfni": {
      "coords": [
        43.2836314,
        20.8088536
      ]
    },
    "jdiqx1va": {
      "coords": [
        43.2838112,
        20.8086424
      ]
    },
    "80kz9ut7": {
      "coords": [
        43.2839607,
        20.8084266
      ]
    },
    "ev99f1t9": {
      "coords": [
        43.2840598,
        20.8082451
      ]
    },
    "i31kjfkz": {
      "coords": [
        43.2841766,
        20.8080525
      ]
    },
    "9w8f4x3b": {
      "coords": [
        43.2733772,
        20.8195057
      ]
    },
    "83elhbi8": {
      "coords": [
        43.2736807,
        20.8195012
      ]
    },
    "2xr7kj25": {
      "coords": [
        43.2741533,
        20.8193514
      ]
    },
    "j5ppim7e": {
      "coords": [
        43.2744414,
        20.8193284
      ]
    },
    "8smfl41": {
      "coords": [
        43.2746564,
        20.8193885
      ]
    },
    "82aygi08": {
      "coords": [
        43.2748749,
        20.8194825
      ]
    },
    "ijbgql30": {
      "coords": [
        43.2751396,
        20.8196208
      ]
    },
    "9rkw1itv": {
      "coords": [
        43.2754185,
        20.8196912
      ]
    },
    "9y8j3bkr": {
      "coords": [
        43.2758439,
        20.8196795
      ]
    },
    "exk5brmg": {
      "coords": [
        43.2763655,
        20.819408
      ]
    },
    "4d5zt80z": {
      "coords": [
        43.2768299,
        20.819212
      ]
    },
    "f4zv4sln": {
      "coords": [
        43.2774977,
        20.8187856
      ]
    },
    "3cgzzop6": {
      "coords": [
        43.2778921,
        20.8185681
      ]
    },
    "19a0indk": {
      "coords": [
        43.2784777,
        20.8183791
      ]
    },
    "7vqhnkv8": {
      "coords": [
        43.27885,
        20.8183657
      ]
    },
    "hovhviqa": {
      "coords": [
        43.2793422,
        20.8183129
      ]
    },
    "h09wbiz0": {
      "coords": [
        43.2794958,
        20.818504
      ]
    },
    "gxbh47nv": {
      "coords": [
        43.2796632,
        20.8186777
      ]
    },
    "579cwd0y": {
      "coords": [
        43.2798756,
        20.8188026
      ]
    },
    "5wkr6zsw": {
      "coords": [
        43.2968083,
        20.8281564
      ]
    },
    "1qxsa8oi": {
      "coords": [
        43.2964508,
        20.8282523
      ]
    },
    "h59hco7k": {
      "coords": [
        43.2963215,
        20.8282657
      ]
    },
    "7qxyktm1": {
      "coords": [
        43.2961422,
        20.8282575
      ]
    },
    "cgkjm0dn": {
      "coords": [
        43.2958896,
        20.8282202
      ]
    },
    "wk25thj": {
      "coords": [
        43.2953207,
        20.8281427
      ]
    },
    "8e6be1aw": {
      "coords": [
        43.2951292,
        20.8281388
      ]
    },
    "l9h5mgg9": {
      "coords": [
        43.2950151,
        20.8281349
      ]
    },
    "azfnvldk": {
      "coords": [
        43.2949251,
        20.828139
      ]
    },
    "abc63x3w": {
      "coords": [
        43.2948372,
        20.8281452
      ]
    },
    "98vd675s": {
      "coords": [
        43.2924684,
        20.8292271
      ]
    },
    "1rc5qf70": {
      "coords": [
        43.292674,
        20.8291346
      ]
    },
    "3ebl8p5a": {
      "coords": [
        43.2929437,
        20.8289907
      ]
    },
    "culffcsd": {
      "coords": [
        43.2937649,
        20.8287225
      ]
    },
    "ezqc2oqt": {
      "coords": [
        43.2941954,
        20.8285159
      ]
    },
    "bi3eqvds": {
      "coords": [
        43.297001,
        20.8281131
      ]
    },
    "2pjonz82": {
      "coords": [
        43.2972798,
        20.8279273
      ]
    },
    "gtzfg54h": {
      "coords": [
        43.2978395,
        20.8276386
      ]
    },
    "3vpkh8sp": {
      "coords": [
        43.2979324,
        20.827572
      ]
    },
    "888guui4": {
      "coords": [
        43.2983131,
        20.827212
      ]
    },
    "atmh0706": {
      "coords": [
        43.2987638,
        20.8269024
      ]
    },
    "dhsk5774": {
      "coords": [
        43.2992099,
        20.8266361
      ]
    },
    "1qihaie4": {
      "coords": [
        43.2995926,
        20.8264752
      ]
    },
    "j9w9fpgn": {
      "coords": [
        43.2997692,
        20.8264244
      ]
    },
    "1e5ixud6": {
      "coords": [
        43.299947,
        20.8263975
      ]
    },
    "3j7qlkwb": {
      "coords": [
        43.3001721,
        20.8264143
      ]
    },
    "j1xm6ytx": {
      "coords": [
        43.3004721,
        20.8264314
      ]
    },
    "irjyk3va": {
      "coords": [
        43.3007689,
        20.8265592
      ]
    },
    "ej1n2jfd": {
      "coords": [
        43.3010886,
        20.8266348
      ]
    },
    "jtc6bsjs": {
      "coords": [
        43.3016585,
        20.8267066
      ]
    },
    "8caiu3p7": {
      "coords": [
        43.3023848,
        20.8267854
      ]
    },
    "ldbg65eq": {
      "coords": [
        43.3027025,
        20.8267485
      ]
    },
    "fyus8rz6": {
      "coords": [
        43.3030594,
        20.8266678
      ]
    },
    "dc2gxao1": {
      "coords": [
        43.3033631,
        20.8265597
      ]
    },
    "e4b8j5o1": {
      "coords": [
        43.3037183,
        20.8264852
      ]
    },
    "drz1liz": {
      "coords": [
        43.3042514,
        20.8263872
      ]
    },
    "3r9dezf6": {
      "coords": [
        43.3108292,
        20.8261694
      ]
    },
    "5o7i7scz": {
      "coords": [
        43.31078,
        20.8260482
      ]
    },
    "enpriihr": {
      "coords": [
        43.3106776,
        20.8260083
      ]
    },
    "3fyfudkq": {
      "coords": [
        43.3104866,
        20.826026
      ]
    },
    "rw1oxc9": {
      "coords": [
        43.3102006,
        20.8260226
      ]
    },
    "a6z1wdsk": {
      "coords": [
        43.3099884,
        20.8260137
      ]
    },
    "9v5kgzjy": {
      "coords": [
        43.3095931,
        20.8259213
      ]
    },
    "llzy5ozv": {
      "coords": [
        43.3091568,
        20.8258437
      ]
    },
    "i1ztbwu9": {
      "coords": [
        43.3090152,
        20.8258286
      ]
    },
    "ev0d4rvv": {
      "coords": [
        43.3081712,
        20.8258847
      ]
    },
    "kswyuue5": {
      "coords": [
        43.3072858,
        20.8259995
      ]
    },
    "2uhdgsh1": {
      "coords": [
        43.3066035,
        20.8260899
      ]
    },
    "b3lsjfy0": {
      "coords": [
        43.3058107,
        20.8261548
      ]
    },
    "7jct38ik": {
      "coords": [
        43.3056694,
        20.826165
      ]
    },
    "bonea9nq": {
      "coords": [
        43.3057473,
        20.8266302
      ]
    },
    "ii5o8v2i": {
      "coords": [
        43.2903815,
        20.8266969
      ]
    },
    "6kot4qu5": {
      "coords": [
        43.2899662,
        20.8261668
      ]
    },
    "7670f7d0": {
      "coords": [
        43.2896536,
        20.8257677
      ]
    },
    "d71nitbt": {
      "coords": [
        43.2890281,
        20.8252785
      ]
    },
    "47hkucy8": {
      "coords": [
        43.2888085,
        20.8251068
      ]
    },
    "4oyw6gso": {
      "coords": [
        43.2881202,
        20.8244788
      ]
    },
    "azwaeoiu": {
      "coords": [
        43.287806,
        20.8241531
      ]
    },
    "4y0da8bv": {
      "coords": [
        43.2873551,
        20.8232943
      ]
    },
    "fu7l2wvh": {
      "coords": [
        43.2870337,
        20.82279
      ]
    },
    "48j3024i": {
      "coords": [
        43.2866438,
        20.8222122
      ]
    },
    "6c37kiad": {
      "coords": [
        43.2864279,
        20.8220132
      ]
    },
    "ijfdoukp": {
      "coords": [
        43.2862094,
        20.821836
      ]
    },
    "iqyevlcd": {
      "coords": [
        43.2860895,
        20.8217976
      ]
    },
    "j34svbtn": {
      "coords": [
        43.2917574,
        20.8291557
      ]
    },
    "fdb8qeim": {
      "coords": [
        43.291801,
        20.8294857
      ]
    },
    "cjo41isq": {
      "coords": [
        43.2908952,
        20.8290134
      ]
    },
    "gp5p6e9": {
      "coords": [
        43.2898275,
        20.8287896
      ]
    },
    "bliq7b27": {
      "coords": [
        43.2884057,
        20.8284874
      ]
    },
    "57s96g1q": {
      "coords": [
        43.2866536,
        20.8280573
      ]
    },
    "7m5y3xkn": {
      "coords": [
        43.2860805,
        20.8276888
      ]
    },
    "ex24ttfe": {
      "coords": [
        43.2858585,
        20.8275312
      ]
    },
    "krzpq3pu": {
      "coords": [
        43.2861632,
        20.8270176
      ]
    },
    "34eo5vvd": {
      "coords": [
        43.2866443,
        20.8272493
      ]
    },
    "bup86w22": {
      "coords": [
        43.2872316,
        20.8276699
      ]
    },
    "e9sn83ol": {
      "coords": [
        43.2879751,
        20.8278072
      ]
    },
    "jbfhthd8": {
      "coords": [
        43.2887748,
        20.8279789
      ]
    },
    "b5hphptd": {
      "coords": [
        43.2897307,
        20.8281849
      ]
    },
    "4pipxyzq": {
      "coords": [
        43.2900743,
        20.8284424
      ]
    },
    "4wb15q20": {
      "coords": [
        43.2906553,
        20.8286569
      ]
    },
    "h30y202k": {
      "coords": [
        43.2949424,
        20.8429269
      ]
    },
    "21bf7axp": {
      "coords": [
        43.2950348,
        20.8435174
      ]
    },
    "cjuz0pgo": {
      "coords": [
        43.2952171,
        20.8439689
      ]
    },
    "h3j50msu": {
      "coords": [
        43.295458,
        20.8443959
      ]
    },
    "1449aiak": {
      "coords": [
        43.2957938,
        20.8448953
      ]
    },
    "58ufvqsl": {
      "coords": [
        43.2960636,
        20.8454635
      ]
    },
    "3t5ym5h7": {
      "coords": [
        43.2961161,
        20.8459984
      ]
    },
    "eln6k08g": {
      "coords": [
        43.2960056,
        20.8462805
      ]
    },
    "5spj4ain": {
      "coords": [
        43.2956754,
        20.8469421
      ]
    },
    "gk0or15n": {
      "coords": [
        43.2954706,
        20.8472738
      ]
    },
    "6r9voij3": {
      "coords": [
        43.2953454,
        20.8476055
      ]
    },
    "affvxi7h": {
      "coords": [
        43.2952421,
        20.8480663
      ]
    },
    "2pfr4ta3": {
      "coords": [
        43.2952271,
        20.8485149
      ]
    },
    "1khb9v3h": {
      "coords": [
        43.2954702,
        20.8503693
      ]
    },
    "giiitu8u": {
      "coords": [
        43.2955665,
        20.8514917
      ]
    },
    "3114hmde": {
      "coords": [
        43.295728,
        20.8520788
      ]
    },
    "j5m7p19v": {
      "coords": [
        43.2961436,
        20.8528725
      ]
    },
    "43ru024x": {
      "coords": [
        43.2963381,
        20.8531134
      ]
    },
    "80xtn5a9": {
      "coords": [
        43.2967583,
        20.8534293
      ]
    },
    "k1vgn901": {
      "coords": [
        43.2970109,
        20.8538323
      ]
    },
    "dhxj0god": {
      "coords": [
        43.2972527,
        20.8542773
      ]
    },
    "3crvf88p": {
      "coords": [
        43.2972738,
        20.8547057
      ]
    },
    "aybr4tsm": {
      "coords": [
        43.2970731,
        20.8550669
      ]
    },
    "6o971f1d": {
      "coords": [
        43.2966163,
        20.8555268
      ]
    },
    "2fu5e3sy": {
      "coords": [
        43.2963189,
        20.8560327
      ]
    },
    "117l2rq4": {
      "coords": [
        43.2961099,
        20.8566125
      ]
    },
    "80gpahv4": {
      "coords": [
        43.2959031,
        20.8569721
      ]
    },
    "u56t4sv": {
      "coords": [
        43.2956165,
        20.857216
      ]
    },
    "jw6xq0l5": {
      "coords": [
        43.2953738,
        20.8573541
      ]
    },
    "96aaxxw1": {
      "coords": [
        43.294845,
        20.8577025
      ]
    },
    "jnsky1i3": {
      "coords": [
        43.29469,
        20.8579194
      ]
    },
    "3s845jx": {
      "coords": [
        43.294669,
        20.8579838
      ]
    },
    "8lezntcj": {
      "coords": [
        43.2946147,
        20.8581503
      ]
    },
    "73h632je": {
      "coords": [
        43.316108,
        20.8242627
      ]
    },
    "a5qx89br": {
      "coords": [
        43.3160689,
        20.8244941
      ]
    },
    "69kgedqu": {
      "coords": [
        43.3160873,
        20.8247945
      ]
    },
    "jts3vj7u": {
      "coords": [
        43.3162973,
        20.8258098
      ]
    },
    "5mu1epx8": {
      "coords": [
        43.3165997,
        20.8268983
      ]
    },
    "6crfil1": {
      "coords": [
        43.3168139,
        20.8275895
      ]
    },
    "b2n0nmfq": {
      "coords": [
        43.3170645,
        20.8286864
      ]
    },
    "8uwltaqu": {
      "coords": [
        43.3174234,
        20.8299363
      ]
    },
    "8wbsl0k5": {
      "coords": [
        43.3175343,
        20.8303858
      ]
    },
    "b2gx2vgb": {
      "coords": [
        43.317677,
        20.831001
      ]
    },
    "a1qjvlnd": {
      "coords": [
        43.3177899,
        20.8313713
      ]
    },
    "l48crzsu": {
      "coords": [
        43.3178799,
        20.831651
      ]
    },
    "9rcghnb9": {
      "coords": [
        43.3160127,
        20.8243325
      ]
    },
    "lkksc4q6": {
      "coords": [
        43.3158385,
        20.8246088
      ]
    },
    "9unk2o6p": {
      "coords": [
        43.3156039,
        20.8250687
      ]
    },
    "ik2tpwe0": {
      "coords": [
        43.3154148,
        20.8256008
      ]
    },
    "ggma6b7j": {
      "coords": [
        43.3153541,
        20.8259914
      ]
    },
    "1jelqwm8": {
      "coords": [
        43.3152884,
        20.8263212
      ]
    },
    "aetdl2nr": {
      "coords": [
        43.3152839,
        20.8266922
      ]
    },
    "28muxpb9": {
      "coords": [
        43.3153366,
        20.8268881
      ]
    },
    "4tf5lud9": {
      "coords": [
        43.3155842,
        20.8275228
      ]
    },
    "2mgw6xk5": {
      "coords": [
        43.3159492,
        20.8284207
      ]
    },
    "jdak3u4i": {
      "coords": [
        43.3160988,
        20.8288328
      ]
    },
    "5g9hd829": {
      "coords": [
        43.3162078,
        20.8292629
      ]
    },
    "9rligajj": {
      "coords": [
        43.3162653,
        20.829767
      ]
    },
    "8f3t68vu": {
      "coords": [
        43.3162892,
        20.8299903
      ]
    },
    "gusz2p4o": {
      "coords": [
        43.3163328,
        20.8302189
      ]
    },
    "elp1546z": {
      "coords": [
        43.3164069,
        20.8303825
      ]
    },
    "b07447ev": {
      "coords": [
        43.316506,
        20.8305262
      ]
    },
    "75rj9fhn": {
      "coords": [
        43.316726,
        20.8307329
      ]
    },
    "iy0j85gb": {
      "coords": [
        43.3173036,
        20.8310476
      ]
    },
    "hhe686ig": {
      "coords": [
        43.3174766,
        20.83115
      ]
    },
    "c897bgc0": {
      "coords": [
        43.3176655,
        20.831323
      ]
    },
    "8bglz01e": {
      "coords": [
        43.3180511,
        20.8318862
      ]
    },
    "2o2q42e1": {
      "coords": [
        43.318239,
        20.832031
      ]
    },
    "cms2kz8i": {
      "coords": [
        43.3110375,
        20.8264971
      ]
    },
    "b6n81qih": {
      "coords": [
        43.3113955,
        20.8269457
      ]
    },
    "35nlmj7p": {
      "coords": [
        43.3117153,
        20.8272707
      ]
    },
    "czshx8pk": {
      "coords": [
        43.3123721,
        20.827915
      ]
    },
    "cwdhq5v9": {
      "coords": [
        43.3133062,
        20.8289942
      ]
    },
    "e4wemq2s": {
      "coords": [
        43.3139223,
        20.8296272
      ]
    },
    "jbkv7n5s": {
      "coords": [
        43.3142422,
        20.8300014
      ]
    },
    "78sjxfv1": {
      "coords": [
        43.3145131,
        20.830359
      ]
    },
    "ev5j8fup": {
      "coords": [
        43.3149065,
        20.8313452
      ]
    },
    "3xpsokog": {
      "coords": [
        43.3151332,
        20.8315709
      ]
    },
    "dtkjph9m": {
      "coords": [
        43.3156846,
        20.8317768
      ]
    },
    "5xq0sbym": {
      "coords": [
        43.3161537,
        20.8317756
      ]
    },
    "i34u9l5": {
      "coords": [
        43.3164802,
        20.8317353
      ]
    },
    "1wf5dnsp": {
      "coords": [
        43.3167867,
        20.8316482
      ]
    },
    "c0jvxd2y": {
      "coords": [
        43.3172807,
        20.8315995
      ]
    },
    "1hmfsz2o": {
      "coords": [
        43.3177273,
        20.8316069
      ]
    },
    "do25a38z": {
      "coords": [
        43.3183226,
        20.8321097
      ]
    },
    "b4i4g27d": {
      "coords": [
        43.3184069,
        20.8321407
      ]
    },
    "69qodj4z": {
      "coords": [
        43.3185945,
        20.8321868
      ]
    },
    "d9x03lf7": {
      "coords": [
        43.3188105,
        20.8321616
      ]
    },
    "5sulqzdv": {
      "coords": [
        43.3190243,
        20.832139
      ]
    },
    "kkuism1p": {
      "coords": [
        43.3192131,
        20.8321433
      ]
    },
    "hi7pv5lb": {
      "coords": [
        43.3194024,
        20.8321948
      ]
    },
    "bcul0e6g": {
      "coords": [
        43.3197766,
        20.8323295
      ]
    },
    "ap1yjg1l": {
      "coords": [
        43.3200232,
        20.832447
      ]
    },
    "ic49exm0": {
      "coords": [
        43.3202938,
        20.832658
      ]
    },
    "8udy9evv": {
      "coords": [
        43.3206422,
        20.8330708
      ]
    },
    "5aoxjt80": {
      "coords": [
        43.3210357,
        20.8336212
      ]
    },
    "gbvk14cl": {
      "coords": [
        43.3212287,
        20.8335501
      ]
    },
    "e9b3r2bg": {
      "coords": [
        43.3184909,
        20.8239535
      ]
    },
    "1tmniq08": {
      "coords": [
        43.3182968,
        20.8240167
      ]
    },
    "1zjyhn8z": {
      "coords": [
        43.3182309,
        20.8241422
      ]
    },
    "dhsy4u8p": {
      "coords": [
        43.3182467,
        20.8242597
      ]
    },
    "6yjnsref": {
      "coords": [
        43.3182988,
        20.8243858
      ]
    },
    "i1tugba2": {
      "coords": [
        43.3184663,
        20.8248041
      ]
    },
    "agvps0tj": {
      "coords": [
        43.3185623,
        20.8249028
      ]
    },
    "20u8xh0p": {
      "coords": [
        43.3187056,
        20.8250805
      ]
    },
    "k7sotkta": {
      "coords": [
        43.3189577,
        20.8252726
      ]
    },
    "45bipu1n": {
      "coords": [
        43.3191964,
        20.8254385
      ]
    },
    "i21k84qm": {
      "coords": [
        43.3194021,
        20.8255914
      ]
    },
    "bouetxrk": {
      "coords": [
        43.3197183,
        20.8257501
      ]
    },
    "2u2ewqbq": {
      "coords": [
        43.320293,
        20.8260086
      ]
    },
    "9ov54skg": {
      "coords": [
        43.3204164,
        20.8260532
      ]
    },
    "9hks78ek": {
      "coords": [
        43.3211817,
        20.8263766
      ]
    },
    "kpwcar0x": {
      "coords": [
        43.3216714,
        20.8265731
      ]
    },
    "65pu1es8": {
      "coords": [
        43.322059,
        20.8268041
      ]
    },
    "87uf91r3": {
      "coords": [
        43.3222499,
        20.8269753
      ]
    },
    "jl4o29my": {
      "coords": [
        43.3224791,
        20.8272526
      ]
    },
    "j2i2cfo8": {
      "coords": [
        43.322675,
        20.827497
      ]
    },
    "j6bvsvsa": {
      "coords": [
        43.3228068,
        20.8276619
      ]
    },
    "hau96cac": {
      "coords": [
        43.3231488,
        20.8281706
      ]
    },
    "d74whrqq": {
      "coords": [
        43.3232907,
        20.8284321
      ]
    },
    "77f5hmn": {
      "coords": [
        43.3234226,
        20.8286865
      ]
    },
    "3yioagcj": {
      "coords": [
        43.3234787,
        20.8290142
      ]
    },
    "dhx0vcy3": {
      "coords": [
        43.3234844,
        20.8292502
      ]
    },
    "l60z7cmu": {
      "coords": [
        43.3234341,
        20.8294775
      ]
    },
    "b1wm5ar7": {
      "coords": [
        43.323311,
        20.8299355
      ]
    },
    "dmjz8hg1": {
      "coords": [
        43.3229936,
        20.8304753
      ]
    },
    "8p1nf9dz": {
      "coords": [
        43.3225289,
        20.8314293
      ]
    },
    "161ovlyz": {
      "coords": [
        43.3222374,
        20.8319552
      ]
    },
    "k24xudj2": {
      "coords": [
        43.3219186,
        20.8325263
      ]
    },
    "1wtk00xz": {
      "coords": [
        43.3216222,
        20.8331206
      ]
    },
    "374ccf9w": {
      "coords": [
        43.321407,
        20.8335388
      ]
    },
    "3blpuu53": {
      "coords": [
        43.2695951,
        20.8258449
      ]
    },
    "b7qtkk01": {
      "coords": [
        43.2695647,
        20.8261794
      ]
    },
    "jheks940": {
      "coords": [
        43.2697831,
        20.8264235
      ]
    },
    "aaqnqm0q": {
      "coords": [
        43.2700188,
        20.8266941
      ]
    },
    "gdlufm6e": {
      "coords": [
        43.271305,
        20.8280745
      ]
    },
    "3h5eesm0": {
      "coords": [
        43.2716381,
        20.8284377
      ]
    },
    "17mqgbuf": {
      "coords": [
        43.2726666,
        20.8297278
      ]
    },
    "5dksqh3m": {
      "coords": [
        43.273034,
        20.8300313
      ]
    },
    "d8zwd9aa": {
      "coords": [
        43.2734865,
        20.8305462
      ]
    },
    "axgynr98": {
      "coords": [
        43.2739435,
        20.8311261
      ]
    },
    "ji3fmw5t": {
      "coords": [
        43.2742348,
        20.8315374
      ]
    },
    "1wpinp4v": {
      "coords": [
        43.2743548,
        20.8317879
      ]
    },
    "f53h89e9": {
      "coords": [
        43.2746045,
        20.8323717
      ]
    },
    "c8vfy27c": {
      "coords": [
        43.2748418,
        20.8329278
      ]
    },
    "jazs0dgc": {
      "coords": [
        43.2751797,
        20.8336164
      ]
    },
    "iy7d0f2c": {
      "coords": [
        43.2754913,
        20.8340217
      ]
    },
    "8ezlrmu2": {
      "coords": [
        43.2761531,
        20.8346183
      ]
    },
    "fc7w1r27": {
      "coords": [
        43.2767355,
        20.8349488
      ]
    },
    "12ib00n8": {
      "coords": [
        43.2770148,
        20.8349871
      ]
    },
    "7owvbg4u": {
      "coords": [
        43.2776405,
        20.8350406
      ]
    },
    "13ahd3ge": {
      "coords": [
        43.2779557,
        20.8350246
      ]
    },
    "cpzayvfj": {
      "coords": [
        43.2781928,
        20.8350559
      ]
    },
    "j5kzi59h": {
      "coords": [
        43.2783228,
        20.8351641
      ]
    },
    "5sa0lir2": {
      "coords": [
        43.2785962,
        20.8355618
      ]
    },
    "2rzmhdif": {
      "coords": [
        43.2789903,
        20.8360289
      ]
    },
    "iad5p7ry": {
      "coords": [
        43.2793495,
        20.8364604
      ]
    },
    "iujehuc6": {
      "coords": [
        43.2800827,
        20.836677
      ]
    },
    "8giijbme": {
      "coords": [
        43.2808961,
        20.8369409
      ]
    },
    "50o6lzwt": {
      "coords": [
        43.2812867,
        20.8372997
      ]
    },
    "cgryav2": {
      "coords": [
        43.2815705,
        20.8376965
      ]
    },
    "kgtco7xr": {
      "coords": [
        43.2817738,
        20.8378877
      ]
    },
    "8p8knzgn": {
      "coords": [
        43.2865054,
        20.8358135
      ]
    },
    "cdigx6xk": {
      "coords": [
        43.2864373,
        20.835144
      ]
    },
    "ksi3yvyr": {
      "coords": [
        43.2863519,
        20.8346321
      ]
    },
    "552i4fof": {
      "coords": [
        43.2862542,
        20.8342897
      ]
    },
    "j7539th3": {
      "coords": [
        43.2860731,
        20.8340102
      ]
    },
    "cmt1inuq": {
      "coords": [
        43.2857616,
        20.8338061
      ]
    },
    "dhxt3vw6": {
      "coords": [
        43.285229,
        20.8335253
      ]
    },
    "7o2btsv1": {
      "coords": [
        43.2846197,
        20.8332615
      ]
    },
    "ghmzi8du": {
      "coords": [
        43.284407,
        20.8332443
      ]
    },
    "1j2fx3lg": {
      "coords": [
        43.283927,
        20.8336194
      ]
    },
    "dla9hm8x": {
      "coords": [
        43.2837509,
        20.8337807
      ]
    },
    "5xityey6": {
      "coords": [
        43.2835348,
        20.8340926
      ]
    },
    "bt7dc9nn": {
      "coords": [
        43.2858585,
        20.8279824
      ]
    },
    "ib2qvqev": {
      "coords": [
        43.2860199,
        20.8283529
      ]
    },
    "15mjyarc": {
      "coords": [
        43.2864442,
        20.8289932
      ]
    },
    "k8cil99t": {
      "coords": [
        43.2867501,
        20.8295111
      ]
    },
    "93kckhsr": {
      "coords": [
        43.2876991,
        20.8310104
      ]
    },
    "2uelshpo": {
      "coords": [
        43.2881792,
        20.8318
      ]
    },
    "kmqy14oh": {
      "coords": [
        43.2883843,
        20.8323678
      ]
    },
    "laeof1f5": {
      "coords": [
        43.2884323,
        20.8330444
      ]
    },
    "corohkw5": {
      "coords": [
        43.2946781,
        20.8281762
      ]
    },
    "l7burrwq": {
      "coords": [
        43.2945051,
        20.8281659
      ]
    },
    "7902lzuq": {
      "coords": [
        43.2943477,
        20.8281439
      ]
    },
    "gdgcpsop": {
      "coords": [
        43.2942017,
        20.8281108
      ]
    },
    "3a3bz3rl": {
      "coords": [
        43.294101,
        20.8280559
      ]
    },
    "9o1yzo86": {
      "coords": [
        43.2939415,
        20.8279388
      ]
    },
    "jdez01co": {
      "coords": [
        43.2936277,
        20.8275901
      ]
    },
    "e6cjztat": {
      "coords": [
        43.2934533,
        20.827332
      ]
    },
    "7kr7l864": {
      "coords": [
        43.2945838,
        20.8589589
      ]
    },
    "5uewh7q": {
      "coords": [
        43.2945618,
        20.859353
      ]
    },
    "9j6p4292": {
      "coords": [
        43.2945536,
        20.8597872
      ]
    },
    "af6kes82": {
      "coords": [
        43.2944567,
        20.8601011
      ]
    },
    "6uv8a9ye": {
      "coords": [
        43.2943012,
        20.8603854
      ]
    },
    "nckbq7e": {
      "coords": [
        43.2940675,
        20.8605817
      ]
    },
    "crdy97f9": {
      "coords": [
        43.2938087,
        20.8607167
      ]
    },
    "f5dce9i2": {
      "coords": [
        43.2937012,
        20.8608202
      ]
    },
    "1vs8ts21": {
      "coords": [
        43.2936594,
        20.8610218
      ]
    },
    "1h7dneg2": {
      "coords": [
        43.29368,
        20.8614984
      ]
    },
    "4gug9bkh": {
      "coords": [
        43.2937543,
        20.8618278
      ]
    },
    "joriyaps": {
      "coords": [
        43.2939067,
        20.8621205
      ]
    },
    "5rluwdww": {
      "coords": [
        43.2941239,
        20.8625182
      ]
    },
    "ag3gonqk": {
      "coords": [
        43.2942394,
        20.8628498
      ]
    },
    "91qorsbb": {
      "coords": [
        43.2943223,
        20.8630869
      ]
    },
    "bl52d2bo": {
      "coords": [
        43.2943774,
        20.8632987
      ]
    },
    "3m9ud7al": {
      "coords": [
        43.2944381,
        20.8636619
      ]
    },
    "dkbqaykv": {
      "coords": [
        43.2944455,
        20.8640319
      ]
    },
    "jsp0hc9s": {
      "coords": [
        43.2944964,
        20.8648187
      ]
    },
    "b9fu8hil": {
      "coords": [
        43.2945363,
        20.8653165
      ]
    },
    "7p4cc1xi": {
      "coords": [
        43.294612,
        20.8656064
      ]
    },
    "52pmqmm0": {
      "coords": [
        43.29483,
        20.8661056
      ]
    },
    "g9p9fs3r": {
      "coords": [
        43.2952487,
        20.8667608
      ]
    },
    "2u7o47sq": {
      "coords": [
        43.2954759,
        20.8672341
      ]
    },
    "9txeax49": {
      "coords": [
        43.2956508,
        20.8676371
      ]
    },
    "o3fmkms": {
      "coords": [
        43.2958149,
        20.8681987
      ]
    },
    "1n82xiia": {
      "coords": [
        43.295895,
        20.8686079
      ]
    },
    "b95g3ipe": {
      "coords": [
        43.2959297,
        20.8690066
      ]
    },
    "csaf9spc": {
      "coords": [
        43.2959,
        20.8694143
      ]
    },
    "5bjnvf5d": {
      "coords": [
        43.2957438,
        20.8700106
      ]
    },
    "by821chu": {
      "coords": [
        43.2956159,
        20.8704758
      ]
    },
    "nsq8awo": {
      "coords": [
        43.2954499,
        20.8710318
      ]
    },
    "7r9qe95j": {
      "coords": [
        43.2952366,
        20.8714477
      ]
    },
    "ga3vxrmo": {
      "coords": [
        43.2949296,
        20.8717411
      ]
    },
    "5ocsi2wk": {
      "coords": [
        43.2939818,
        20.8725299
      ]
    },
    "kkwq1ctr": {
      "coords": [
        43.2936374,
        20.8730969
      ]
    },
    "dw1yztm6": {
      "coords": [
        43.2935002,
        20.873456
      ]
    },
    "h1slerck": {
      "coords": [
        43.2932004,
        20.8746032
      ]
    },
    "c26qmk1t": {
      "coords": [
        43.2929765,
        20.8756104
      ]
    },
    "2rlsaqsj": {
      "coords": [
        43.2929555,
        20.8757511
      ]
    },
    "5sibsyd6": {
      "coords": [
        43.2928911,
        20.8759086
      ]
    },
    "l5c7smvt": {
      "coords": [
        43.2763421,
        20.8238421
      ]
    },
    "hcloda6k": {
      "coords": [
        43.2764395,
        20.8237166
      ]
    },
    "1sny2ngo": {
      "coords": [
        43.2766094,
        20.8235412
      ]
    },
    "2tqedtjw": {
      "coords": [
        43.2768072,
        20.8231905
      ]
    },
    "f3kikh1p": {
      "coords": [
        43.2769673,
        20.8228118
      ]
    },
    "e43f3iui": {
      "coords": [
        43.2771073,
        20.8224713
      ]
    },
    "hzo5tb8m": {
      "coords": [
        43.2773553,
        20.8217644
      ]
    },
    "gm10vge4": {
      "coords": [
        43.2775019,
        20.8214478
      ]
    },
    "4i0s7la8": {
      "coords": [
        43.2775448,
        20.8211824
      ]
    },
    "8ihnk12r": {
      "coords": [
        43.2776673,
        20.8207326
      ]
    },
    "foeajxo": {
      "coords": [
        43.2778734,
        20.8201593
      ]
    },
    "28v6f55n": {
      "coords": [
        43.2780567,
        20.8197999
      ]
    },
    "9datk41k": {
      "coords": [
        43.2781474,
        20.8196579
      ]
    },
    "9qkus7rs": {
      "coords": [
        43.2784385,
        20.8193799
      ]
    },
    "4lfedt1u": {
      "coords": [
        43.2787528,
        20.8192028
      ]
    },
    "ljzkzmcm": {
      "coords": [
        43.2793838,
        20.8189322
      ]
    },
    "7ceydulw": {
      "coords": [
        43.2795849,
        20.818846
      ]
    },
    "7aih0kz": {
      "coords": [
        43.3185398,
        20.8251396
      ]
    },
    "6jxf1dio": {
      "coords": [
        43.3185823,
        20.8254914
      ]
    },
    "jblzp6hn": {
      "coords": [
        43.3187868,
        20.8260209
      ]
    },
    "es6ieu9": {
      "coords": [
        43.3188963,
        20.8262377
      ]
    },
    "hj4eqnsh": {
      "coords": [
        43.3189836,
        20.8264771
      ]
    },
    "b2y0au41": {
      "coords": [
        43.3190597,
        20.8269314
      ]
    },
    "fay3bxc5": {
      "coords": [
        43.3191608,
        20.8276325
      ]
    },
    "6ej25z6n": {
      "coords": [
        43.3193866,
        20.8287491
      ]
    },
    "jsqic5qd": {
      "coords": [
        43.3195874,
        20.8297778
      ]
    },
    "7677ft6z": {
      "coords": [
        43.31969,
        20.8305349
      ]
    },
    "1cu6v441": {
      "coords": [
        43.3197462,
        20.83141
      ]
    },
    "jhxfwltz": {
      "coords": [
        43.3198194,
        20.8319104
      ]
    },
    "eib9yqso": {
      "coords": [
        43.3203922,
        20.8264846
      ]
    },
    "f2ngtvf": {
      "coords": [
        43.3205045,
        20.8272704
      ]
    },
    "dsneyk9y": {
      "coords": [
        43.320549,
        20.8282925
      ]
    },
    "6r8oex1r": {
      "coords": [
        43.3206327,
        20.8296097
      ]
    },
    "alwvdu3l": {
      "coords": [
        43.320655,
        20.8299554
      ]
    },
    "lfdluvb1": {
      "coords": [
        43.320656,
        20.8303411
      ]
    },
    "3eilv361": {
      "coords": [
        43.3208439,
        20.8311943
      ]
    },
    "3xyncn46": {
      "coords": [
        43.3212402,
        20.8326089
      ]
    },
    "afz5h66v": {
      "coords": [
        43.3213484,
        20.8331185
      ]
    },
    "6ysdrnuy": {
      "coords": [
        43.296459,
        20.8284093
      ]
    },
    "q1k21em": {
      "coords": [
        43.2955448,
        20.8267015
      ]
    },
    "gttuv59p": {
      "coords": [
        43.2952201,
        20.8263788
      ]
    },
    "4dg36zyo": {
      "coords": [
        43.2950803,
        20.8261589
      ]
    },
    "dnv9j69b": {
      "coords": [
        43.2948768,
        20.8259841
      ]
    },
    "41gkrenj": {
      "coords": [
        43.2947268,
        20.8258033
      ]
    },
    "3ed74ycv": {
      "coords": [
        43.2945664,
        20.8253346
      ]
    },
    "3xxn8wn3": {
      "coords": [
        43.2943621,
        20.824906
      ]
    },
    "79lq2fw9": {
      "coords": [
        43.2941101,
        20.824533
      ]
    },
    "da5irfwf": {
      "coords": [
        43.293995,
        20.8243166
      ]
    },
    "533yvq3o": {
      "coords": [
        43.2938754,
        20.8241257
      ]
    },
    "609k63v9": {
      "coords": [
        43.2937237,
        20.8238075
      ]
    },
    "kl8mrvsb": {
      "coords": [
        43.2935353,
        20.8233659
      ]
    },
    "hy7p9dly": {
      "coords": [
        43.2931852,
        20.8228003
      ]
    },
    "hft4zfu5": {
      "coords": [
        43.2929509,
        20.8224817
      ]
    },
    "82vsfcdg": {
      "coords": [
        43.2928569,
        20.822357
      ]
    },
    "4hlmbrtx": {
      "coords": [
        43.2924653,
        20.8212971
      ]
    },
    "cmt8crtp": {
      "coords": [
        43.2923026,
        20.8207678
      ]
    },
    "e3bot2gu": {
      "coords": [
        43.2923571,
        20.8202778
      ]
    },
    "lkp1a6ad": {
      "coords": [
        43.2925838,
        20.8194001
      ]
    },
    "jyrurd3x": {
      "coords": [
        43.2927948,
        20.818661
      ]
    },
    "i5hr6t2v": {
      "coords": [
        43.3180233,
        20.8239714
      ]
    },
    "3cx00roz": {
      "coords": [
        43.3178766,
        20.8239379
      ]
    },
    "bgmlrrz1": {
      "coords": [
        43.3177303,
        20.8239054
      ]
    },
    "6s24pcky": {
      "coords": [
        43.3175927,
        20.8238929
      ]
    },
    "cbmfc5tk": {
      "coords": [
        43.3174659,
        20.8238921
      ]
    },
    "khj6mkur": {
      "coords": [
        43.3173294,
        20.8238558
      ]
    },
    "i1jdivc9": {
      "coords": [
        43.3170997,
        20.8238262
      ]
    },
    "bhk4wi4o": {
      "coords": [
        43.3169113,
        20.8237875
      ]
    },
    "4skd5gjx": {
      "coords": [
        43.3167381,
        20.8238157
      ]
    },
    "gcfw596b": {
      "coords": [
        43.3166207,
        20.8238734
      ]
    },
    "6yfzn0dl": {
      "coords": [
        43.3164182,
        20.8240229
      ]
    },
    "5ocoe8h": {
      "coords": [
        43.2742376,
        20.8180156
      ]
    },
    "bb0d3nee": {
      "coords": [
        43.2745161,
        20.8178944
      ]
    },
    "3uln3nmf": {
      "coords": [
        43.274747,
        20.8178608
      ]
    },
    "9mtbnzgo": {
      "coords": [
        43.2750383,
        20.8178159
      ]
    },
    "bkkzz04w": {
      "coords": [
        43.2752896,
        20.817787
      ]
    },
    "b2h1wcyw": {
      "coords": [
        43.2760754,
        20.8178234
      ]
    },
    "9jjz6qlo": {
      "coords": [
        43.276658,
        20.8179313
      ]
    },
    "hw97pzt9": {
      "coords": [
        43.2771314,
        20.8180154
      ]
    },
    "5ne5vk6r": {
      "coords": [
        43.2776333,
        20.8181265
      ]
    },
    "gpf0d2zb": {
      "coords": [
        43.2788491,
        20.8173546
      ]
    },
    "4okgfs6x": {
      "coords": [
        43.2790708,
        20.8171368
      ]
    },
    "eaery6vk": {
      "coords": [
        43.2793171,
        20.8167433
      ]
    },
    "k1x68vvb": {
      "coords": [
        43.279916,
        20.8154746
      ]
    },
    "a3sno0i": {
      "coords": [
        43.3220147,
        20.8270739
      ]
    },
    "3j7ea93t": {
      "coords": [
        43.3218991,
        20.8272684
      ]
    },
    "avpuktvg": {
      "coords": [
        43.32177,
        20.8274353
      ]
    },
    "28crlqj": {
      "coords": [
        43.3215548,
        20.8277527
      ]
    },
    "gqnbwb35": {
      "coords": [
        43.3213174,
        20.8281533
      ]
    },
    "11yeypau": {
      "coords": [
        43.3211744,
        20.82864
      ]
    },
    "f0zii7rb": {
      "coords": [
        43.320989,
        20.8294745
      ]
    },
    "9gkoyu69": {
      "coords": [
        43.3208775,
        20.8300712
      ]
    },
    "8lu4zrpo": {
      "coords": [
        43.3208499,
        20.8304094
      ]
    },
    "gdllk9vj": {
      "coords": [
        43.3207389,
        20.8321824
      ]
    },
    "5gxsx66a": {
      "coords": [
        43.3207069,
        20.832468
      ]
    },
    "gpy4evov": {
      "coords": [
        43.3207389,
        20.8328498
      ]
    },
    "fmjismuo": {
      "coords": [
        43.3207578,
        20.8329983
      ]
    },
    "2sh2pyc3": {
      "coords": [
        43.3207966,
        20.8331438
      ]
    },
    "gg486568": {
      "coords": [
        43.2775485,
        20.8097522
      ]
    },
    "azrits2m": {
      "coords": [
        43.2774302,
        20.809631
      ]
    },
    "9ap85u7k": {
      "coords": [
        43.2774396,
        20.8094411
      ]
    },
    "22iee70u": {
      "coords": [
        43.2775936,
        20.8094224
      ]
    },
    "7uuvz9w7": {
      "coords": [
        43.2777615,
        20.8093889
      ]
    },
    "gl4nq0vf": {
      "coords": [
        43.2779667,
        20.809404
      ]
    },
    "5yaiei4a": {
      "coords": [
        43.2781871,
        20.8094071
      ]
    },
    "8cvr282u": {
      "coords": [
        43.2782595,
        20.8094239
      ]
    },
    "8n3scb28": {
      "coords": [
        43.2785542,
        20.809596
      ]
    },
    "lb0zh1cy": {
      "coords": [
        43.2789926,
        20.8098153
      ]
    },
    "8p2iqhkw": {
      "coords": [
        43.2792173,
        20.8099654
      ]
    },
    "akk6fb5r": {
      "coords": [
        43.2794359,
        20.8102083
      ]
    },
    "31qb5uxs": {
      "coords": [
        43.279819,
        20.8105777
      ]
    },
    "9dk1hrka": {
      "coords": [
        43.2801258,
        20.8108094
      ]
    },
    "g4oujoev": {
      "coords": [
        43.2804715,
        20.8109098
      ]
    },
    "jb6hsme9": {
      "coords": [
        43.2808437,
        20.810923
      ]
    },
    "hlb21etv": {
      "coords": [
        43.2812711,
        20.8109223
      ]
    },
    "idqovtzk": {
      "coords": [
        43.2815828,
        20.810946
      ]
    },
    "axed4pk1": {
      "coords": [
        43.2820542,
        20.8109893
      ]
    },
    "6w27k8z4": {
      "coords": [
        43.2823623,
        20.8111031
      ]
    },
    "8bm04vmk": {
      "coords": [
        43.2825536,
        20.8111665
      ]
    },
    "xyxc8o2": {
      "coords": [
        43.2829077,
        20.8111127
      ]
    },
    "5tzo1zlh": {
      "coords": [
        43.2831338,
        20.8108771
      ]
    },
    "gw4ctf5e": {
      "coords": [
        43.2827581,
        20.8084612
      ]
    },
    "fgaoze61": {
      "coords": [
        43.2828358,
        20.8090994
      ]
    },
    "7690bomv": {
      "coords": [
        43.280469,
        20.8081306
      ]
    },
    "5zidvttn": {
      "coords": [
        43.2806564,
        20.8085859
      ]
    },
    "5djdi751": {
      "coords": [
        43.2807844,
        20.8089363
      ]
    },
    "3r17bj6j": {
      "coords": [
        43.2808653,
        20.8092277
      ]
    },
    "he6y2773": {
      "coords": [
        43.2809614,
        20.8096158
      ]
    },
    "5jdhlzv0": {
      "coords": [
        43.2810693,
        20.8100701
      ]
    },
    "cpemj2gv": {
      "coords": [
        43.2812547,
        20.8107844
      ]
    },
    "ixxk5xon": {
      "coords": [
        43.2783826,
        20.8092707
      ]
    },
    "9fihhtnz": {
      "coords": [
        43.2785687,
        20.8091152
      ]
    },
    "hi7ubu1i": {
      "coords": [
        43.2788474,
        20.808845
      ]
    },
    "fdk7ser7": {
      "coords": [
        43.2790119,
        20.8086856
      ]
    },
    "a7fs71hk": {
      "coords": [
        43.2793457,
        20.8084036
      ]
    },
    "8c3ogwyy": {
      "coords": [
        43.2795665,
        20.8082611
      ]
    },
    "dzv985rb": {
      "coords": [
        43.2797832,
        20.8081611
      ]
    },
    "jtrkmlfc": {
      "coords": [
        43.2801286,
        20.8080926
      ]
    },
    "a2ridem6": {
      "coords": [
        43.2804241,
        20.8081216
      ]
    },
    "74kek3zl": {
      "coords": [
        43.2808289,
        20.8082613
      ]
    },
    "ineq3sle": {
      "coords": [
        43.2815791,
        20.808675
      ]
    },
    "eyvv6ikp": {
      "coords": [
        43.2818508,
        20.8087627
      ]
    },
    "5g0cvuza": {
      "coords": [
        43.2824444,
        20.8089122
      ]
    },
    "edrw0n56": {
      "coords": [
        43.2833097,
        20.8094499
      ]
    },
    "3rzssonl": {
      "coords": [
        43.2851274,
        20.8256887
      ]
    },
    "kg615n2": {
      "coords": [
        43.2841912,
        20.8244287
      ]
    },
    "6rh89srm": {
      "coords": [
        43.2841096,
        20.8242259
      ]
    },
    "6jn59bjo": {
      "coords": [
        43.2839928,
        20.8237315
      ]
    },
    "bn4315ra": {
      "coords": [
        43.2837432,
        20.8227586
      ]
    },
    "6irtg3r5": {
      "coords": [
        43.2836596,
        20.8222897
      ]
    },
    "hyx0ok8e": {
      "coords": [
        43.2836216,
        20.8217813
      ]
    },
    "49myjtxi": {
      "coords": [
        43.2836655,
        20.8214451
      ]
    },
    "kjup23m": {
      "coords": [
        43.2837221,
        20.8211584
      ]
    },
    "4ik6ch3v": {
      "coords": [
        43.2801575,
        20.8193605
      ]
    },
    "jp06hv5d": {
      "coords": [
        43.2799792,
        20.8191177
      ]
    },
    "dvpr3vp1": {
      "coords": [
        43.2799616,
        20.8185817
      ]
    },
    "iz2qoz4a": {
      "coords": [
        43.2800634,
        20.818417
      ]
    },
    "z1bm0ml": {
      "coords": [
        43.280483,
        20.8176893
      ]
    },
    "1gh7u66f": {
      "coords": [
        43.3162708,
        20.8237268
      ]
    },
    "9mny8ap1": {
      "coords": [
        43.3160366,
        20.8237649
      ]
    },
    "ldupjuw6": {
      "coords": [
        43.3157345,
        20.8239979
      ]
    },
    "cqeoa1gw": {
      "coords": [
        43.3153698,
        20.8243053
      ]
    },
    "39cltbg": {
      "coords": [
        43.3150483,
        20.8244944
      ]
    },
    "j9udbnfi": {
      "coords": [
        43.3147853,
        20.8247241
      ]
    },
    "8ni84fu5": {
      "coords": [
        43.3138875,
        20.8251833
      ]
    },
    "k5dk3n7c": {
      "coords": [
        43.3135473,
        20.8253439
      ]
    },
    "aqd6tj96": {
      "coords": [
        43.3131349,
        20.8255135
      ]
    },
    "ftm3sy1o": {
      "coords": [
        43.3123974,
        20.8256448
      ]
    },
    "6uyvuise": {
      "coords": [
        43.3119261,
        20.8257521
      ]
    },
    "eihqrdj8": {
      "coords": [
        43.3112851,
        20.8258499
      ]
    },
    "2hrrfu7w": {
      "coords": [
        43.2793473,
        20.8046298
      ]
    },
    "4dndyt7y": {
      "coords": [
        43.2795761,
        20.8035759
      ]
    },
    "63dogroh": {
      "coords": [
        43.2797082,
        20.8028738
      ]
    },
    "4kkwo0t9": {
      "coords": [
        43.2798259,
        20.8022591
      ]
    },
    "axmxf21b": {
      "coords": [
        43.2798973,
        20.8017977
      ]
    },
    "7adigb65": {
      "coords": [
        43.2800964,
        20.8010072
      ]
    },
    "1gphltns": {
      "coords": [
        43.2802233,
        20.8003595
      ]
    },
    "le5b13iz": {
      "coords": [
        43.2803384,
        20.799718
      ]
    },
    "6ss5eu51": {
      "coords": [
        43.2803921,
        20.7994973
      ]
    },
    "ecv75l5o": {
      "coords": [
        43.2805122,
        20.7989268
      ]
    },
    "k26k9h8w": {
      "coords": [
        43.2806517,
        20.7984188
      ]
    },
    "jiwixkfu": {
      "coords": [
        43.2807696,
        20.7978266
      ]
    },
    "3bnuo0ys": {
      "coords": [
        43.2808282,
        20.7972101
      ]
    },
    "3q9xa281": {
      "coords": [
        43.2808796,
        20.796814
      ]
    },
    "k5vf081p": {
      "coords": [
        43.2791917,
        20.8070707
      ]
    },
    "8dx3701p": {
      "coords": [
        43.2792503,
        20.8060478
      ]
    },
    "in8j3mqv": {
      "coords": [
        43.2793153,
        20.8050426
      ]
    },
    "k9b4x9cq": {
      "coords": [
        43.2792763,
        20.8040196
      ]
    },
    "eb31gqqy": {
      "coords": [
        43.2789998,
        20.8026348
      ]
    },
    "idgfjk5y": {
      "coords": [
        43.2789088,
        20.8021568
      ]
    },
    "7hyw3vsj": {
      "coords": [
        43.2788326,
        20.8012309
      ]
    },
    "agq0ghgk": {
      "coords": [
        43.2788933,
        20.8007392
      ]
    },
    "4z6prbtj": {
      "coords": [
        43.2790129,
        20.8002895
      ]
    },
    "bd9d6nm6": {
      "coords": [
        43.279221,
        20.7996641
      ]
    },
    "dz7tomq1": {
      "coords": [
        43.2793836,
        20.7990119
      ]
    },
    "hdjo12ky": {
      "coords": [
        43.2794682,
        20.7985562
      ]
    },
    "ap0jiy5p": {
      "coords": [
        43.2796893,
        20.7981095
      ]
    },
    "3r7ans2w": {
      "coords": [
        43.2800015,
        20.7976271
      ]
    },
    "5glem6bq": {
      "coords": [
        43.2803616,
        20.7972086
      ]
    },
    "l6ywjvk1": {
      "coords": [
        43.2806422,
        20.7970508
      ]
    },
    "85lqv9jx": {
      "coords": [
        43.2810595,
        20.7964872
      ]
    },
    "hudf1p98": {
      "coords": [
        43.2812831,
        20.7962099
      ]
    },
    "kxi24qoz": {
      "coords": [
        43.2800494,
        20.806627
      ]
    },
    "b4lg41q4": {
      "coords": [
        43.2774964,
        20.8099395
      ]
    },
    "60r8wiqg": {
      "coords": [
        43.2775223,
        20.8103664
      ]
    },
    "3p30qk4o": {
      "coords": [
        43.277696,
        20.8109101
      ]
    },
    "136xotjx": {
      "coords": [
        43.2778577,
        20.8111882
      ]
    },
    "llk13st7": {
      "coords": [
        43.2780201,
        20.8114272
      ]
    },
    "f07743kg": {
      "coords": [
        43.2854602,
        20.8268516
      ]
    },
    "2o8ckc3j": {
      "coords": [
        43.2852775,
        20.8263901
      ]
    },
    "duo0mcy4": {
      "coords": [
        43.2851844,
        20.8259502
      ]
    },
    "g211i42i": {
      "coords": [
        43.2849909,
        20.8248524
      ]
    },
    "crexflq8": {
      "coords": [
        43.2847197,
        20.8233363
      ]
    },
    "1xxk1pps": {
      "coords": [
        43.2845952,
        20.8229481
      ]
    },
    "g1yj3wun": {
      "coords": [
        43.2844012,
        20.8224295
      ]
    },
    "dw34cgr0": {
      "coords": [
        43.2842296,
        20.8221149
      ]
    },
    "g9i8a42c": {
      "coords": [
        43.2839837,
        20.8216893
      ]
    },
    "ld3rusit": {
      "coords": [
        43.2838128,
        20.82138
      ]
    },
    "kwmgfq6h": {
      "coords": [
        43.2836898,
        20.8203559
      ]
    },
    "95eme1rw": {
      "coords": [
        43.2836923,
        20.8199093
      ]
    },
    "bzaammeg": {
      "coords": [
        43.2837173,
        20.8188845
      ]
    },
    "6ummpusf": {
      "coords": [
        43.2837837,
        20.8183576
      ]
    },
    "jm7i5he8": {
      "coords": [
        43.2838752,
        20.8180748
      ]
    },
    "fxevz5mf": {
      "coords": [
        43.3176668,
        20.824204
      ]
    },
    "kxzn0tae": {
      "coords": [
        43.3175787,
        20.8244076
      ]
    },
    "kzm4fob0": {
      "coords": [
        43.3174185,
        20.8251397
      ]
    },
    "ggywmby2": {
      "coords": [
        43.3173865,
        20.8258112
      ]
    },
    "63gfgb7v": {
      "coords": [
        43.3174513,
        20.8267071
      ]
    },
    "hdw2re3w": {
      "coords": [
        43.3174666,
        20.8269175
      ]
    },
    "5p05fe83": {
      "coords": [
        43.3175026,
        20.8276386
      ]
    },
    "7cat9w1p": {
      "coords": [
        43.3176308,
        20.8287339
      ]
    },
    "g3cj22a8": {
      "coords": [
        43.3180723,
        20.8303026
      ]
    },
    "9m58ntuc": {
      "coords": [
        43.3184876,
        20.8310754
      ]
    },
    "d9qkstcf": {
      "coords": [
        43.3186186,
        20.8313192
      ]
    },
    "e6495glh": {
      "coords": [
        43.3188842,
        20.8316569
      ]
    },
    "l01dlujj": {
      "coords": [
        43.3191457,
        20.8319307
      ]
    },
    "8vqmtqov": {
      "coords": [
        43.3182974,
        20.8255105
      ]
    },
    "kiozw15i": {
      "coords": [
        43.3180907,
        20.8257246
      ]
    },
    "5krszm0l": {
      "coords": [
        43.3178798,
        20.8259873
      ]
    },
    "gzbxtu3b": {
      "coords": [
        43.3176815,
        20.8261897
      ]
    },
    "4h334y13": {
      "coords": [
        43.3175442,
        20.8264777
      ]
    },
    "fs7grt5y": {
      "coords": [
        43.2860685,
        20.8215718
      ]
    },
    "50hvdc9z": {
      "coords": [
        43.2862324,
        20.8212824
      ]
    },
    "gwdofor3": {
      "coords": [
        43.2864735,
        20.8209424
      ]
    },
    "ene9xd1v": {
      "coords": [
        43.2868112,
        20.8204843
      ]
    },
    "c2hwy52d": {
      "coords": [
        43.2870931,
        20.8201839
      ]
    },
    "h32hj4lv": {
      "coords": [
        43.2872313,
        20.8199373
      ]
    },
    "2spexg50": {
      "coords": [
        43.2872988,
        20.8198296
      ]
    },
    "i1phz8wp": {
      "coords": [
        43.2873499,
        20.8196086
      ]
    },
    "gcwdnaks": {
      "coords": [
        43.2835469,
        20.8013782
      ]
    },
    "8p238m6e": {
      "coords": [
        43.2843105,
        20.8008262
      ]
    },
    "jq0gqlnk": {
      "coords": [
        43.2847066,
        20.8004769
      ]
    },
    "56222ilw": {
      "coords": [
        43.2848386,
        20.8003493
      ]
    },
    "j1cfwu96": {
      "coords": [
        43.2849119,
        20.800215
      ]
    },
    "fb6xsjhj": {
      "coords": [
        43.2849902,
        20.8000806
      ]
    },
    "3apxvd8p": {
      "coords": [
        43.2850733,
        20.7999732
      ]
    },
    "18oszkh6": {
      "coords": [
        43.2851613,
        20.7998657
      ]
    },
    "eai4rt48": {
      "coords": [
        43.2853373,
        20.7997381
      ]
    },
    "j97hhldo": {
      "coords": [
        43.2854889,
        20.7996911
      ]
    },
    "isaxvq6l": {
      "coords": [
        43.2856258,
        20.7996508
      ]
    },
    "9p0k50jv": {
      "coords": [
        43.2856991,
        20.7994896
      ]
    },
    "em9982vr": {
      "coords": [
        43.2856943,
        20.7993687
      ]
    },
    "bhge753a": {
      "coords": [
        43.2856356,
        20.7992746
      ]
    },
    "4fxch5gn": {
      "coords": [
        43.28544,
        20.799194
      ]
    },
    "e8gaewmt": {
      "coords": [
        43.2852102,
        20.7990933
      ]
    },
    "hpdf3vkb": {
      "coords": [
        43.2850635,
        20.7990798
      ]
    },
    "j6idntt4": {
      "coords": [
        43.2848875,
        20.7990933
      ]
    },
    "6qzyj9lx": {
      "coords": [
        43.2847066,
        20.7990395
      ]
    },
    "1i9pqwcc": {
      "coords": [
        43.284555,
        20.7990261
      ]
    },
    "jogrkj7d": {
      "coords": [
        43.2844181,
        20.7990059
      ]
    },
    "kzc6n28r": {
      "coords": [
        43.284291,
        20.7989724
      ]
    },
    "jfsjojhq": {
      "coords": [
        43.2840044,
        20.7989806
      ]
    },
    "fnknjcqh": {
      "coords": [
        43.2838414,
        20.7988381
      ]
    },
    "8v5ygpvz": {
      "coords": [
        43.2835385,
        20.7985958
      ]
    },
    "dis6fftm": {
      "coords": [
        43.2834038,
        20.7984344
      ]
    },
    "7se7mspv": {
      "coords": [
        43.2832818,
        20.7983502
      ]
    },
    "aywewbjb": {
      "coords": [
        43.2831212,
        20.7983313
      ]
    },
    "hegpnhar": {
      "coords": [
        43.2825159,
        20.7983359
      ]
    },
    "7w8pu4s8": {
      "coords": [
        43.2823294,
        20.7982749
      ]
    },
    "azethp1d": {
      "coords": [
        43.2821812,
        20.7983087
      ]
    },
    "b0wul0hj": {
      "coords": [
        43.282033,
        20.7983494
      ]
    },
    "1zds3ln8": {
      "coords": [
        43.2818884,
        20.798324
      ]
    },
    "e5o0zhxp": {
      "coords": [
        43.2817647,
        20.7982895
      ]
    },
    "b6ob4mov": {
      "coords": [
        43.2816344,
        20.7981907
      ]
    },
    "5llur0k8": {
      "coords": [
        43.2816105,
        20.7980816
      ]
    },
    "bxjvgphc": {
      "coords": [
        43.2816179,
        20.7978251
      ]
    },
    "hxkxr56h": {
      "coords": [
        43.2744863,
        20.8113446
      ]
    },
    "3jg3kkf": {
      "coords": [
        43.2741663,
        20.8113771
      ]
    },
    "7bcxdcfp": {
      "coords": [
        43.2739197,
        20.8110996
      ]
    },
    "1xl93j1o": {
      "coords": [
        43.2733661,
        20.8098251
      ]
    },
    "isrlki1d": {
      "coords": [
        43.272954,
        20.808429
      ]
    },
    "hmqnhmc1": {
      "coords": [
        43.2727083,
        20.8074005
      ]
    },
    "h86b6ez": {
      "coords": [
        43.2719732,
        20.8056488
      ]
    },
    "efnbm29": {
      "coords": [
        43.2714649,
        20.8051041
      ]
    },
    "ft7adlzf": {
      "coords": [
        43.2712272,
        20.8046787
      ]
    },
    "iwr5zmha": {
      "coords": [
        43.2710957,
        20.8039618
      ]
    },
    "btgsp8n": {
      "coords": [
        43.2710151,
        20.8034897
      ]
    },
    "o1vf3fy": {
      "coords": [
        43.270804,
        20.8031495
      ]
    },
    "jgbr82en": {
      "coords": [
        43.2702431,
        20.802578
      ]
    },
    "cngwk0p3": {
      "coords": [
        43.2700814,
        20.8021317
      ]
    },
    "hve6ec83": {
      "coords": [
        43.2699753,
        20.8016829
      ]
    },
    "l5q1uzho": {
      "coords": [
        43.2700433,
        20.8008063
      ]
    },
    "habk8k3c": {
      "coords": [
        43.274639,
        20.8115136
      ]
    },
    "f99rzyr4": {
      "coords": [
        43.2747484,
        20.8114941
      ]
    },
    "x66xhw5": {
      "coords": [
        43.2751674,
        20.8115183
      ]
    },
    "ao0p7op2": {
      "coords": [
        43.2753997,
        20.8116124
      ]
    },
    "b31ozod0": {
      "coords": [
        43.275526,
        20.8116873
      ]
    },
    "8t4r3ty7": {
      "coords": [
        43.275666,
        20.811696
      ]
    },
    "jvfb4qy": {
      "coords": [
        43.275857,
        20.8116698
      ]
    },
    "3tpg707p": {
      "coords": [
        43.2760225,
        20.8115911
      ]
    },
    "iypxt9c7": {
      "coords": [
        43.2744269,
        20.8148725
      ]
    },
    "gxcnu4jc": {
      "coords": [
        43.2744465,
        20.8138446
      ]
    },
    "1zl9x3ap": {
      "coords": [
        43.2743566,
        20.8130806
      ]
    },
    "ju1ejb6a": {
      "coords": [
        43.2740068,
        20.8116203
      ]
    },
    "df6zaiaq": {
      "coords": [
        43.2733078,
        20.8196418
      ]
    },
    "bp2nltu2": {
      "coords": [
        43.273534,
        20.8198185
      ]
    },
    "cgbjykf7": {
      "coords": [
        43.274113,
        20.8202822
      ]
    },
    "inub27i8": {
      "coords": [
        43.2741993,
        20.8203513
      ]
    },
    "8ln1kklk": {
      "coords": [
        43.2752506,
        20.8205625
      ]
    },
    "ebmtg69t": {
      "coords": [
        43.2756996,
        20.8206994
      ]
    },
    "jkxz7941": {
      "coords": [
        43.2768174,
        20.8210555
      ]
    },
    "3aqymzd9": {
      "coords": [
        43.2776922,
        20.8212157
      ]
    },
    "hkvuh7fk": {
      "coords": [
        43.2783427,
        20.8212342
      ]
    },
    "daxbmmpx": {
      "coords": [
        43.2787555,
        20.821493
      ]
    },
    "e1s8zjsm": {
      "coords": [
        43.2790336,
        20.8217826
      ]
    },
    "6i0y17kx": {
      "coords": [
        43.2792803,
        20.8223126
      ]
    },
    "jx645nkd": {
      "coords": [
        43.2795495,
        20.8229288
      ]
    },
    "3hp761b": {
      "coords": [
        43.2797339,
        20.8235259
      ]
    },
    "dnrajfg0": {
      "coords": [
        43.279851,
        20.824133
      ]
    },
    "1cdf2y3o": {
      "coords": [
        43.2799368,
        20.8247721
      ]
    },
    "cx7y9ggw": {
      "coords": [
        43.2799546,
        20.8254438
      ]
    },
    "3rzpjcah": {
      "coords": [
        43.2799293,
        20.8262756
      ]
    },
    "4jhf4ff8": {
      "coords": [
        43.2797769,
        20.8280905
      ]
    },
    "7w1irp4s": {
      "coords": [
        43.2796179,
        20.8297274
      ]
    },
    "8zfku9ox": {
      "coords": [
        43.2760493,
        20.8198857
      ]
    },
    "4nm18bpk": {
      "coords": [
        43.2762836,
        20.8199286
      ]
    },
    "gpbqq4fm": {
      "coords": [
        43.2765765,
        20.8199179
      ]
    },
    "49ew8s3i": {
      "coords": [
        43.2768734,
        20.8198321
      ]
    },
    "i8dykd0q": {
      "coords": [
        43.2774446,
        20.8196723
      ]
    },
    "2orl4eys": {
      "coords": [
        43.2775959,
        20.819639
      ]
    },
    "i7csse9k": {
      "coords": [
        43.2778649,
        20.8196904
      ]
    },
    "fbmma5qb": {
      "coords": [
        43.2784882,
        20.820091
      ]
    },
    "fkl7icxc": {
      "coords": [
        43.2787842,
        20.8203005
      ]
    },
    "ktlt4zhb": {
      "coords": [
        43.2795686,
        20.8209657
      ]
    },
    "15umcuzm": {
      "coords": [
        43.2798787,
        20.8192232
      ]
    },
    "86pci5sg": {
      "coords": [
        43.2796579,
        20.8192873
      ]
    },
    "k0i6jrp7": {
      "coords": [
        43.2794905,
        20.8195529
      ]
    },
    "40ntl6ot": {
      "coords": [
        43.2794599,
        20.8200197
      ]
    },
    "7gky0ua": {
      "coords": [
        43.2795166,
        20.8206285
      ]
    },
    "g9rz4pi8": {
      "coords": [
        43.2796086,
        20.8212763
      ]
    },
    "44bp2dq4": {
      "coords": [
        43.2796662,
        20.8221995
      ]
    },
    "nu4anvo": {
      "coords": [
        43.2846566,
        20.8177492
      ]
    },
    "5ikazxyw": {
      "coords": [
        43.2852423,
        20.8176741
      ]
    },
    "7usuodmt": {
      "coords": [
        43.2857968,
        20.8176527
      ]
    },
    "ljvs62sh": {
      "coords": [
        43.2861405,
        20.8176312
      ]
    },
    "id4cj5f1": {
      "coords": [
        43.2864763,
        20.8175883
      ]
    },
    "k9wwiwc4": {
      "coords": [
        43.2867106,
        20.8175132
      ]
    },
    "h4g0k3jy": {
      "coords": [
        43.2869918,
        20.8173415
      ]
    },
    "xt4a03d": {
      "coords": [
        43.2875353,
        20.8172267
      ]
    },
    "a8quxc2k": {
      "coords": [
        43.2916079,
        20.8289795
      ]
    },
    "1mvepu1y": {
      "coords": [
        43.2913529,
        20.828499
      ]
    },
    "j9h2xoxw": {
      "coords": [
        43.2908259,
        20.8274114
      ]
    },
    "jbm2rgt4": {
      "coords": [
        43.2897553,
        20.825093
      ]
    },
    "9aoei0zt": {
      "coords": [
        43.2896561,
        20.8248806
      ]
    },
    "9g667nbj": {
      "coords": [
        43.2889721,
        20.8234154
      ]
    },
    "dn547h9q": {
      "coords": [
        43.287921,
        20.8214193
      ]
    },
    "k1vci1rg": {
      "coords": [
        43.2874727,
        20.8202868
      ]
    },
    "cmwsz7tr": {
      "coords": [
        43.2872615,
        20.8189065
      ]
    },
    "2jfra0yt": {
      "coords": [
        43.287191,
        20.8183349
      ]
    },
    "k35tyid9": {
      "coords": [
        43.2872203,
        20.8178093
      ]
    },
    "iyxl6z7t": {
      "coords": [
        43.2873336,
        20.8174412
      ]
    },
    "i7wv6asb": {
      "coords": [
        43.2877922,
        20.8171015
      ]
    },
    "a2qskqqk": {
      "coords": [
        43.2883748,
        20.8170083
      ]
    },
    "3csnf121": {
      "coords": [
        43.2885967,
        20.8170095
      ]
    },
    "197otgrg": {
      "coords": [
        43.2888144,
        20.8169817
      ]
    },
    "fe3n4fwa": {
      "coords": [
        43.2889257,
        20.8169489
      ]
    },
    "b91gpjhf": {
      "coords": [
        43.2891394,
        20.8169213
      ]
    },
    "2egofl2f": {
      "coords": [
        43.2919673,
        20.8288914
      ]
    },
    "5f1bwos9": {
      "coords": [
        43.2920488,
        20.8286369
      ]
    },
    "dcxe2pyv": {
      "coords": [
        43.2923992,
        20.8270673
      ]
    },
    "gs73s4c5": {
      "coords": [
        43.292513,
        20.8264424
      ]
    },
    "1uq7453v": {
      "coords": [
        43.2925383,
        20.8263032
      ]
    },
    "8en4gq7v": {
      "coords": [
        43.2926369,
        20.8257671
      ]
    },
    "drq0jtjc": {
      "coords": [
        43.2928522,
        20.8248058
      ]
    },
    "a36etj2i": {
      "coords": [
        43.2929733,
        20.824165
      ]
    },
    "dchoc1ns": {
      "coords": [
        43.2930361,
        20.8237644
      ]
    },
    "61j5zb2s": {
      "coords": [
        43.2930092,
        20.822951
      ]
    },
    "2pmp1ffq": {
      "coords": [
        43.3045214,
        20.8266699
      ]
    },
    "8uowrytu": {
      "coords": [
        43.3044016,
        20.8274404
      ]
    },
    "4a0j1vjg": {
      "coords": [
        43.3042387,
        20.828214
      ]
    },
    "4brt3hk5": {
      "coords": [
        43.3039939,
        20.8293307
      ]
    },
    "bgvevydz": {
      "coords": [
        43.3037763,
        20.8318827
      ]
    },
    "j694vfjl": {
      "coords": [
        43.3040406,
        20.8331835
      ]
    },
    "8qnvzvt7": {
      "coords": [
        43.304063,
        20.8334093
      ]
    },
    "b5wvg5bv": {
      "coords": [
        43.304064,
        20.8337106
      ]
    },
    "k342uz0q": {
      "coords": [
        43.3039806,
        20.8341262
      ]
    },
    "etwqw3ab": {
      "coords": [
        43.3038594,
        20.8344844
      ]
    },
    "ah14imvq": {
      "coords": [
        43.3037305,
        20.8346705
      ]
    },
    "8f63tni5": {
      "coords": [
        43.3036327,
        20.8349149
      ]
    },
    "b2nshjs7": {
      "coords": [
        43.3034088,
        20.8351454
      ]
    },
    "icvnimxf": {
      "coords": [
        43.3033451,
        20.8351841
      ]
    },
    "8ukevjdj": {
      "coords": [
        43.3032615,
        20.8352869
      ]
    },
    "gx4xuj0c": {
      "coords": [
        43.3032591,
        20.8353657
      ]
    },
    "hx1zj14c": {
      "coords": [
        43.3032862,
        20.8353937
      ]
    },
    "asfgdlmw": {
      "coords": [
        43.3033468,
        20.8353841
      ]
    },
    "agjj5e4w": {
      "coords": [
        43.3035703,
        20.835375
      ]
    },
    "2qz3kfvu": {
      "coords": [
        43.3036297,
        20.835424
      ]
    },
    "by5q7ils": {
      "coords": [
        43.3036241,
        20.8355268
      ]
    },
    "2mow5k7l": {
      "coords": [
        43.3035324,
        20.8356126
      ]
    },
    "9fq24bg": {
      "coords": [
        43.3034294,
        20.8356834
      ]
    },
    "25il83h3": {
      "coords": [
        43.303258,
        20.8357657
      ]
    },
    "g5499lbg": {
      "coords": [
        43.3029869,
        20.8356534
      ]
    },
    "9ecjqcyy": {
      "coords": [
        43.3026502,
        20.8354002
      ]
    },
    "lnjsl5js": {
      "coords": [
        43.3024821,
        20.8352768
      ]
    },
    "e8gxxrjn": {
      "coords": [
        43.302328,
        20.8352288
      ]
    },
    "gaszx84s": {
      "coords": [
        43.3017123,
        20.8352318
      ]
    },
    "hbqf1tfb": {
      "coords": [
        43.3013689,
        20.8353861
      ]
    },
    "hdj9qfz": {
      "coords": [
        43.301109,
        20.8355642
      ]
    },
    "afuc91zk": {
      "coords": [
        43.3008374,
        20.8355995
      ]
    },
    "g0kfnow9": {
      "coords": [
        43.3002923,
        20.8353325
      ]
    },
    "erq493av": {
      "coords": [
        43.2999009,
        20.8351048
      ]
    },
    "dvyox3le": {
      "coords": [
        43.2997338,
        20.8350482
      ]
    },
    "bpr5cm2i": {
      "coords": [
        43.2995333,
        20.8351396
      ]
    },
    "vbsu4ix": {
      "coords": [
        43.2992493,
        20.8354714
      ]
    },
    "2tnwri10": {
      "coords": [
        43.298782,
        20.8362935
      ]
    },
    "aznu01ga": {
      "coords": [
        43.2986457,
        20.8364735
      ]
    },
    "6eb8gjzk": {
      "coords": [
        43.2983821,
        20.836634
      ]
    },
    "4qr1zb49": {
      "coords": [
        43.2979884,
        20.8369659
      ]
    },
    "dcfd3pqc": {
      "coords": [
        43.2977368,
        20.8371514
      ]
    },
    "eu954rlr": {
      "coords": [
        43.2950966,
        20.8429358
      ]
    },
    "jeyhhgda": {
      "coords": [
        43.2954858,
        20.8426977
      ]
    },
    "fkwvkfeq": {
      "coords": [
        43.2960824,
        20.8426549
      ]
    },
    "fegwgrbj": {
      "coords": [
        43.2969311,
        20.8423463
      ]
    },
    "hbo9j2ts": {
      "coords": [
        43.2975703,
        20.8423341
      ]
    },
    "81xq1e3j": {
      "coords": [
        43.2978868,
        20.842554
      ]
    },
    "7vxdkrdq": {
      "coords": [
        43.2984848,
        20.8438442
      ]
    },
    "dy36l6mx": {
      "coords": [
        43.2984135,
        20.8445904
      ]
    },
    "61myda9": {
      "coords": [
        43.2982804,
        20.8449156
      ]
    },
    "k4vomxyv": {
      "coords": [
        43.2982893,
        20.8450579
      ]
    },
    "fbioq0yd": {
      "coords": [
        43.2983632,
        20.8450986
      ]
    },
    "8ffh80mw": {
      "coords": [
        43.2984431,
        20.8450376
      ]
    },
    "j7d3nz9l": {
      "coords": [
        43.2992116,
        20.8441717
      ]
    },
    "71rsy3x3": {
      "coords": [
        43.2993719,
        20.8432553
      ]
    },
    "k7rsdfvx": {
      "coords": [
        43.2992398,
        20.8424763
      ]
    },
    "cebf3fpd": {
      "coords": [
        43.2988104,
        20.8417042
      ]
    },
    "6wrcw542": {
      "coords": [
        43.2986769,
        20.8403623
      ]
    },
    "jgjjms6n": {
      "coords": [
        43.2978267,
        20.8393959
      ]
    },
    "d9qlcxlf": {
      "coords": [
        43.2969219,
        20.8381138
      ]
    },
    "4mzc21k6": {
      "coords": [
        43.2968749,
        20.8378905
      ]
    },
    "frelq81l": {
      "coords": [
        43.2968631,
        20.8377807
      ]
    },
    "7okj9x0v": {
      "coords": [
        43.2969153,
        20.8376671
      ]
    },
    "iei3pu0v": {
      "coords": [
        43.2971827,
        20.8373945
      ]
    },
    "dbdj7p0u": {
      "coords": [
        43.3183125,
        20.8311209
      ]
    },
    "cgcoug5r": {
      "coords": [
        43.3182581,
        20.8315225
      ]
    },
    "6j7lmqly": {
      "coords": [
        43.3182096,
        20.8318051
      ]
    },
    "gjvomdx0": {
      "coords": [
        43.3191785,
        20.8256242
      ]
    },
    "8vzqhajy": {
      "coords": [
        43.3190987,
        20.8258613
      ]
    },
    "bhp0dyf3": {
      "coords": [
        43.3190292,
        20.8261374
      ]
    },
    "grfdlu2p": {
      "coords": [
        43.3189957,
        20.8263002
      ]
    },
    "51hphu7t": {
      "coords": [
        43.2947404,
        20.8424971
      ]
    },
    "9vxy2km4": {
      "coords": [
        43.2943898,
        20.8420362
      ]
    },
    "ayd4iiw9": {
      "coords": [
        43.2936526,
        20.8410276
      ]
    },
    "9yu8s8qd": {
      "coords": [
        43.2930324,
        20.8401952
      ]
    },
    "g7ph42re": {
      "coords": [
        43.2926739,
        20.8398388
      ]
    },
    "jeyy5wmo": {
      "coords": [
        43.2923116,
        20.8397605
      ]
    },
    "bmpevj7u": {
      "coords": [
        43.2918221,
        20.8395363
      ]
    },
    "36y6oeyg": {
      "coords": [
        43.2911423,
        20.8392001
      ]
    },
    "fcfuvlcn": {
      "coords": [
        43.2906596,
        20.8390226
      ]
    },
    "avs0r2c2": {
      "coords": [
        43.2903401,
        20.8388452
      ]
    },
    "b0ot5gvq": {
      "coords": [
        43.2901837,
        20.8386023
      ]
    },
    "4dat3878": {
      "coords": [
        43.2898574,
        20.8384529
      ]
    },
    "606wovs5": {
      "coords": [
        43.2895378,
        20.8381634
      ]
    },
    "jjv0wkul": {
      "coords": [
        43.2891979,
        20.8377617
      ]
    },
    "dv7s1sw5": {
      "coords": [
        43.2889532,
        20.8373601
      ]
    },
    "ivqnid63": {
      "coords": [
        43.2887492,
        20.8370986
      ]
    },
    "kogb1pf8": {
      "coords": [
        43.2884568,
        20.8369118
      ]
    },
    "92gc5s46": {
      "coords": [
        43.2880829,
        20.836725
      ]
    },
    "rke4uvh": {
      "coords": [
        43.2877022,
        20.8366409
      ]
    },
    "cvqhjyab": {
      "coords": [
        43.2873623,
        20.8366036
      ]
    },
    "6tr9ekmm": {
      "coords": [
        43.2870903,
        20.836669
      ]
    },
    "i6359h85": {
      "coords": [
        43.2868727,
        20.8366503
      ]
    },
    "8kmo3ec3": {
      "coords": [
        43.2867065,
        20.8365794
      ]
    },
    "2lq8behv": {
      "coords": [
        43.2918068,
        20.8358879
      ]
    },
    "7ef7uu7r": {
      "coords": [
        43.2919156,
        20.8372469
      ]
    },
    "1kstn08w": {
      "coords": [
        43.2919846,
        20.8377409
      ]
    },
    "2cb65scp": {
      "coords": [
        43.2920777,
        20.8380931
      ]
    },
    "39grsnbq": {
      "coords": [
        43.2923055,
        20.8389323
      ]
    },
    "1i9tjcfh": {
      "coords": [
        43.2918679,
        20.8297055
      ]
    },
    "54z8wbp4": {
      "coords": [
        43.2919316,
        20.8306497
      ]
    },
    "bvvodch1": {
      "coords": [
        43.2918789,
        20.8328344
      ]
    },
    "88neq4c6": {
      "coords": [
        43.2918474,
        20.8338649
      ]
    },
    "fmg1f4wo": {
      "coords": [
        43.2923463,
        20.8319449
      ]
    },
    "3lq2p5nb": {
      "coords": [
        43.292659,
        20.832637
      ]
    },
    "4fl0qt2h": {
      "coords": [
        43.2929057,
        20.8333949
      ]
    },
    "321c6h4g": {
      "coords": [
        43.2931075,
        20.8338139
      ]
    },
    "6zo01em0": {
      "coords": [
        43.2933497,
        20.8343377
      ]
    },
    "1v5x65ba": {
      "coords": [
        43.2934798,
        20.8346704
      ]
    },
    "eep9jbu4": {
      "coords": [
        43.2929485,
        20.829668
      ]
    },
    "k94w91t7": {
      "coords": [
        43.2938928,
        20.8300315
      ]
    },
    "6j9vl4vq": {
      "coords": [
        43.2949433,
        20.8303606
      ]
    },
    "62c6ox7u": {
      "coords": [
        43.2955526,
        20.8306525
      ]
    },
    "9z11rpjo": {
      "coords": [
        43.2958423,
        20.8308718
      ]
    },
    "h7lmw2": {
      "coords": [
        43.2959721,
        20.8310067
      ]
    },
    "clqdbgof": {
      "coords": [
        43.2961659,
        20.8315426
      ]
    },
    "bmv6a3mx": {
      "coords": [
        43.2962964,
        20.8323129
      ]
    },
    "x2haw9s": {
      "coords": [
        43.2963827,
        20.8330133
      ]
    },
    "dwcjdse1": {
      "coords": [
        43.2964524,
        20.8338761
      ]
    },
    "7jlvgcua": {
      "coords": [
        43.2965248,
        20.8342802
      ]
    },
    "31xrhq28": {
      "coords": [
        43.2966326,
        20.8346479
      ]
    },
    "40gpld0v": {
      "coords": [
        43.2970702,
        20.8360245
      ]
    },
    "8n082ya8": {
      "coords": [
        43.2973133,
        20.8366673
      ]
    },
    "l9u30jfj": {
      "coords": [
        43.2963399,
        20.8312691
      ]
    },
    "5ftzal0g": {
      "coords": [
        43.2967821,
        20.8314091
      ]
    },
    "k409vji2": {
      "coords": [
        43.2972948,
        20.8314265
      ]
    },
    "1ls646po": {
      "coords": [
        43.2975474,
        20.8314353
      ]
    },
    "8mf42hnl": {
      "coords": [
        43.2977629,
        20.8315665
      ]
    },
    "2yikqdkh": {
      "coords": [
        43.2980583,
        20.8319262
      ]
    },
    "l4l2a2zd": {
      "coords": [
        43.2982832,
        20.8322654
      ]
    },
    "bskgqs87": {
      "coords": [
        43.2985284,
        20.8328009
      ]
    },
    "aibz2baq": {
      "coords": [
        43.2987995,
        20.8333618
      ]
    },
    "vvkqwyd": {
      "coords": [
        43.2990636,
        20.83371
      ]
    },
    "hhg9igzp": {
      "coords": [
        43.2993276,
        20.8340634
      ]
    },
    "ffbiumfk": {
      "coords": [
        43.2995697,
        20.83452
      ]
    },
    "jjy32h1": {
      "coords": [
        43.2996601,
        20.834807
      ]
    },
    "4wq86unw": {
      "coords": [
        43.2997804,
        20.8353104
      ]
    },
    "jfbls12o": {
      "coords": [
        43.2998386,
        20.8357334
      ]
    },
    "4lpcfdt0": {
      "coords": [
        43.2999349,
        20.836608
      ]
    },
    "8fcpkld6": {
      "coords": [
        43.3000387,
        20.8374419
      ]
    },
    "9j9fz24d": {
      "coords": [
        43.2824331,
        20.8402021
      ]
    },
    "2lmzix5k": {
      "coords": [
        43.2826083,
        20.8400118
      ]
    },
    "hkxdham3": {
      "coords": [
        43.2807169,
        20.8175072
      ]
    },
    "9rmz43l6": {
      "coords": [
        43.2810029,
        20.8175282
      ]
    },
    "33zsej83": {
      "coords": [
        43.2816152,
        20.8176303
      ]
    },
    "7s4rg3ca": {
      "coords": [
        43.2820643,
        20.8175874
      ]
    },
    "hox2k7q4": {
      "coords": [
        43.282283,
        20.8175981
      ]
    },
    "fkigteih": {
      "coords": [
        43.2824838,
        20.8175881
      ]
    },
    "jfx7bv5e": {
      "coords": [
        43.2826072,
        20.817582
      ]
    },
    "9on9pynu": {
      "coords": [
        43.2827946,
        20.8175981
      ]
    },
    "gw7f86as": {
      "coords": [
        43.283068,
        20.8176625
      ]
    },
    "khkjgqgj": {
      "coords": [
        43.2832125,
        20.8176732
      ]
    },
    "8i010i4n": {
      "coords": [
        43.2834427,
        20.8176919
      ]
    },
    "k9we1i9k": {
      "coords": [
        43.2836167,
        20.8177429
      ]
    },
    "97klopd": {
      "coords": [
        43.2730075,
        20.8304929
      ]
    },
    "6opg81bh": {
      "coords": [
        43.2730943,
        20.8307043
      ]
    },
    "2mvrqwp6": {
      "coords": [
        43.2739521,
        20.8317755
      ]
    },
    "ajn18ob4": {
      "coords": [
        43.2741375,
        20.8321967
      ]
    },
    "iwy3u9of": {
      "coords": [
        43.2746844,
        20.8335729
      ]
    },
    "9ras1j26": {
      "coords": [
        43.274983,
        20.8342332
      ]
    },
    "apsb376u": {
      "coords": [
        43.2752475,
        20.8345995
      ]
    },
    "p71qn2o": {
      "coords": [
        43.2758098,
        20.8350813
      ]
    },
    "hggh1qlu": {
      "coords": [
        43.2765116,
        20.8355433
      ]
    },
    "focr1pc5": {
      "coords": [
        43.2770369,
        20.8358492
      ]
    },
    "5ay7ob9s": {
      "coords": [
        43.2777528,
        20.8363135
      ]
    },
    "jjng42wg": {
      "coords": [
        43.2782222,
        20.8364268
      ]
    },
    "4qcya9wn": {
      "coords": [
        43.2785452,
        20.8364083
      ]
    },
    "4pvuk88k": {
      "coords": [
        43.284059,
        20.8828982
      ]
    },
    "7nbt7hpt": {
      "coords": [
        43.2841848,
        20.8828748
      ]
    },
    "a27u1o4l": {
      "coords": [
        43.284331,
        20.8827534
      ]
    },
    "2mlolel8": {
      "coords": [
        43.2845078,
        20.882562
      ]
    },
    "4pqzbgy8": {
      "coords": [
        43.2845995,
        20.8825153
      ]
    },
    "emuj86dy": {
      "coords": [
        43.2847491,
        20.8824078
      ]
    },
    "slqnru8": {
      "coords": [
        43.2849769,
        20.8821557
      ]
    },
    "ccdd0uqv": {
      "coords": [
        43.2851333,
        20.8819222
      ]
    },
    "7qh24eq6": {
      "coords": [
        43.2852863,
        20.881754
      ]
    },
    "gmzovcq7": {
      "coords": [
        43.285412,
        20.881628
      ]
    },
    "fd24viuf": {
      "coords": [
        43.2854528,
        20.8815065
      ]
    },
    "7irk0hzd": {
      "coords": [
        43.2854732,
        20.8813057
      ]
    },
    "ivms958w": {
      "coords": [
        43.2839468,
        20.8098832
      ]
    },
    "3n39agsf": {
      "coords": [
        43.2838392,
        20.8093532
      ]
    },
    "g7tywqgw": {
      "coords": [
        43.2837495,
        20.8089681
      ]
    },
    "cmt1d5in": {
      "coords": [
        43.2832882,
        20.8207805
      ]
    },
    "9vgp76bq": {
      "coords": [
        43.282944,
        20.820193
      ]
    },
    "gk9cvwef": {
      "coords": [
        43.2825926,
        20.8191975
      ]
    },
    "aax8hn5o": {
      "coords": [
        43.2827183,
        20.8178384
      ]
    },
    "ey1lqthi": {
      "coords": [
        43.2827655,
        20.817172
      ]
    },
    "5e825lbl": {
      "coords": [
        43.2830701,
        20.815483
      ]
    },
    "58wshfsk": {
      "coords": [
        43.2836269,
        20.8142153
      ]
    },
    "3fvieb21": {
      "coords": [
        43.2820532,
        20.8179332
      ]
    },
    "ko7ovmey": {
      "coords": [
        43.2817928,
        20.8165393
      ]
    },
    "avu8ub61": {
      "coords": [
        43.2817132,
        20.8154762
      ]
    },
    "4rqlg2ky": {
      "coords": [
        43.2814191,
        20.8159842
      ]
    },
    "k2exxyum": {
      "coords": [
        43.2813753,
        20.8157896
      ]
    },
    "a7lnzgjr": {
      "coords": [
        43.2811769,
        20.8155029
      ]
    },
    "2wn9318w": {
      "coords": [
        43.2810223,
        20.8153861
      ]
    },
    "ipnu822h": {
      "coords": [
        43.280878,
        20.8153578
      ]
    },
    "41m2kxsh": {
      "coords": [
        43.2827581,
        20.8098066
      ]
    },
    "h4mt38qx": {
      "coords": [
        43.2831999,
        20.8096063
      ]
    },
    "i2e3nn04": {
      "coords": [
        43.2833372,
        20.8096955
      ]
    },
    "j7i28atl": {
      "coords": [
        43.2755747,
        20.8154294
      ]
    },
    "gt65se4a": {
      "coords": [
        43.2765599,
        20.8157136
      ]
    },
    "7lmn4kz7": {
      "coords": [
        43.2773407,
        20.8157779
      ]
    },
    "d78spy6": {
      "coords": [
        43.2788871,
        20.8155696
      ]
    },
    "9uwfyal3": {
      "coords": [
        43.280874,
        20.8173638
      ]
    },
    "2fie5x7n": {
      "coords": [
        43.281044,
        20.8172004
      ]
    },
    "fvfd9l1": {
      "coords": [
        43.2812445,
        20.8169574
      ]
    },
    "b7uhssn2": {
      "coords": [
        43.2814247,
        20.8166446
      ]
    },
    "kn4kcq1": {
      "coords": [
        43.2815312,
        20.8163831
      ]
    },
    "6iquvmv4": {
      "coords": [
        43.2816797,
        20.81572
      ]
    },
    "cdm94zp0": {
      "coords": [
        43.2817037,
        20.8155995
      ]
    },
    "bkatee76": {
      "coords": [
        43.2731012,
        20.8198877
      ]
    },
    "8bp0uut9": {
      "coords": [
        43.2736569,
        20.8189842
      ]
    },
    "2m8iiaac": {
      "coords": [
        43.2738624,
        20.8186697
      ]
    },
    "gczff9ys": {
      "coords": [
        43.2739901,
        20.8184582
      ]
    },
    "j27t8k3n": {
      "coords": [
        43.2745439,
        20.8170739
      ]
    },
    "f2w27tzk": {
      "coords": [
        43.2831287,
        20.8114079
      ]
    },
    "5s63elxr": {
      "coords": [
        43.2835318,
        20.8112598
      ]
    },
    "g1acrjf6": {
      "coords": [
        43.2834032,
        20.8105464
      ]
    },
    "9tk5l7bj": {
      "coords": [
        43.2836132,
        20.8102124
      ]
    },
    "b0s65bau": {
      "coords": [
        43.2856207,
        20.827265
      ]
    },
    "dyb9la1p": {
      "coords": [
        43.2850775,
        20.8272766
      ]
    },
    "ar1f590y": {
      "coords": [
        43.2845974,
        20.8272999
      ]
    },
    "jn9aplbe": {
      "coords": [
        43.284212,
        20.8274939
      ]
    },
    "5hel6hwj": {
      "coords": [
        43.2838881,
        20.8278015
      ]
    },
    "1o7c9p1f": {
      "coords": [
        43.2835881,
        20.8283832
      ]
    },
    "g8vhasxy": {
      "coords": [
        43.2834978,
        20.8290837
      ]
    },
    "j3xowgvk": {
      "coords": [
        43.2836527,
        20.8295605
      ]
    },
    "ifrz46yy": {
      "coords": [
        43.2837417,
        20.8300352
      ]
    },
    "a36afis9": {
      "coords": [
        43.2837127,
        20.8303812
      ]
    },
    "8h398evj": {
      "coords": [
        43.2836616,
        20.8306863
      ]
    },
    "703ovo7i": {
      "coords": [
        43.2834622,
        20.8313583
      ]
    },
    "9f3m7rol": {
      "coords": [
        43.2833911,
        20.8320207
      ]
    },
    "6jicf6eg": {
      "coords": [
        43.2833034,
        20.8327444
      ]
    },
    "hpx4nq8": {
      "coords": [
        43.2833703,
        20.8333939
      ]
    },
    "74et4w3d": {
      "coords": [
        43.2832608,
        20.8344864
      ]
    },
    "kx7vaz21": {
      "coords": [
        43.2831365,
        20.8348032
      ]
    },
    "hmyiuujq": {
      "coords": [
        43.2829632,
        20.8348359
      ]
    },
    "g0zyoxqp": {
      "coords": [
        43.2826962,
        20.8347726
      ]
    },
    "dvdnjr9s": {
      "coords": [
        43.2811442,
        20.8331487
      ]
    },
    "ky182t0c": {
      "coords": [
        43.2804372,
        20.832585
      ]
    },
    "1hmp20bk": {
      "coords": [
        43.2802328,
        20.8324677
      ]
    },
    "2uuwxw61": {
      "coords": [
        43.2800756,
        20.8324783
      ]
    },
    "igckwk80": {
      "coords": [
        43.2744937,
        20.810858
      ]
    },
    "fq2dfuv6": {
      "coords": [
        43.2743124,
        20.810169
      ]
    },
    "2cg5tbvl": {
      "coords": [
        43.2741766,
        20.8096969
      ]
    },
    "eocwvx94": {
      "coords": [
        43.2738677,
        20.8091473
      ]
    },
    "3yam76c5": {
      "coords": [
        43.2736072,
        20.8087863
      ]
    },
    "a15swmiq": {
      "coords": [
        43.2734594,
        20.8080475
      ]
    },
    "kiduor0n": {
      "coords": [
        43.2732448,
        20.8076384
      ]
    },
    "huwmlvn9": {
      "coords": [
        43.272926,
        20.8067425
      ]
    },
    "b2h5j80o": {
      "coords": [
        43.272872,
        20.8062912
      ]
    },
    "h83bzc9m": {
      "coords": [
        43.2723792,
        20.8049899
      ]
    },
    "gxpl19vd": {
      "coords": [
        43.2722001,
        20.8047559
      ]
    },
    "hraq8kit": {
      "coords": [
        43.27195,
        20.8045532
      ]
    },
    "1nxfx410": {
      "coords": [
        43.2718372,
        20.8042636
      ]
    },
    "fmkclq4r": {
      "coords": [
        43.2716508,
        20.8034619
      ]
    },
    "4m0mov9t": {
      "coords": [
        43.2714301,
        20.8026266
      ]
    },
    "fcuy5ji8": {
      "coords": [
        43.2710166,
        20.8015588
      ]
    },
    "9fvb3tre": {
      "coords": [
        43.2707562,
        20.8011
      ]
    },
    "ffhnh9gs": {
      "coords": [
        43.2704589,
        20.8007876
      ]
    },
    "7zigiy2o": {
      "coords": [
        43.2747456,
        20.8211244
      ]
    },
    "hb69lcl7": {
      "coords": [
        43.2754175,
        20.8217576
      ]
    },
    "fw7qiy74": {
      "coords": [
        43.2762954,
        20.8223639
      ]
    },
    "ho9sn0j9": {
      "coords": [
        43.2767662,
        20.8226198
      ]
    },
    "gga0xui1": {
      "coords": [
        43.2775901,
        20.8229769
      ]
    },
    "4dcuv7yn": {
      "coords": [
        43.2783111,
        20.8233272
      ]
    },
    "a6q3ewcv": {
      "coords": [
        43.2791399,
        20.8236842
      ]
    },
    "7h7j3az7": {
      "coords": [
        43.279542,
        20.823856
      ]
    },
    "51osgdfe": {
      "coords": [
        43.2696643,
        20.8266481
      ]
    },
    "8xmmcxyt": {
      "coords": [
        43.2698165,
        20.8270245
      ]
    },
    "eskb31kc": {
      "coords": [
        43.2706208,
        20.8279348
      ]
    },
    "6yvm245o": {
      "coords": [
        43.2716505,
        20.8291001
      ]
    },
    "b7nb1vvg": {
      "coords": [
        43.2725955,
        20.8301696
      ]
    },
    "5dhndshg": {
      "coords": [
        43.2770991,
        20.8251534
      ]
    },
    "eyqligsy": {
      "coords": [
        43.27727,
        20.8098834
      ]
    },
    "5461f3r1": {
      "coords": [
        43.2773995,
        20.8099592
      ]
    },
    "gw1uludv": {
      "coords": [
        43.2742233,
        20.8120166
      ]
    },
    "al0i92gm": {
      "coords": [
        43.2743378,
        20.8119102
      ]
    },
    "jdmb8led": {
      "coords": [
        43.2744566,
        20.8118767
      ]
    },
    "72gdvrhn": {
      "coords": [
        43.2745691,
        20.8118738
      ]
    },
    "1bc4lw38": {
      "coords": [
        43.2746603,
        20.8118417
      ]
    },
    "jv1cwiz1": {
      "coords": [
        43.2839851,
        20.8241009
      ]
    },
    "a1cfxm3p": {
      "coords": [
        43.283823,
        20.8239508
      ]
    },
    "88xaaebt": {
      "coords": [
        43.2831858,
        20.8234374
      ]
    },
    "dqpmunvj": {
      "coords": [
        43.2823766,
        20.8223485
      ]
    },
    "5y60xf7z": {
      "coords": [
        43.2816757,
        20.8212868
      ]
    },
    "f0ufcpfx": {
      "coords": [
        43.2814593,
        20.8209612
      ]
    },
    "a7qm1la3": {
      "coords": [
        43.2808334,
        20.8203541
      ]
    },
    "8cjmlc6t": {
      "coords": [
        43.2801339,
        20.8201124
      ]
    },
    "kpav4ab4": {
      "coords": [
        43.2798533,
        20.8201083
      ]
    },
    "2hq5vys1": {
      "coords": [
        43.2796981,
        20.820219
      ]
    },
    "imw9gne1": {
      "coords": [
        43.2769502,
        20.8130043
      ]
    },
    "1ge5zg51": {
      "coords": [
        43.277422,
        20.8124583
      ]
    },
    "d5pewfjs": {
      "coords": [
        43.2778321,
        20.8120313
      ]
    },
    "k5si1z82": {
      "coords": [
        43.2808412,
        20.81165
      ]
    },
    "88e2i5w8": {
      "coords": [
        43.2810544,
        20.8116084
      ]
    },
    "b39six26": {
      "coords": [
        43.2812388,
        20.8115825
      ]
    },
    "1gkaifsm": {
      "coords": [
        43.2814884,
        20.8115348
      ]
    },
    "2xdxurtq": {
      "coords": [
        43.2817559,
        20.8114549
      ]
    },
    "8c5kjlii": {
      "coords": [
        43.2820535,
        20.8113884
      ]
    },
    "fmtd7xml": {
      "coords": [
        43.2827949,
        20.8117734
      ]
    },
    "j9w84bq1": {
      "coords": [
        43.2828633,
        20.811586
      ]
    },
    "5dknj17m": {
      "coords": [
        43.2857514,
        20.8275473
      ]
    },
    "jz7bz0y6": {
      "coords": [
        43.2794384,
        20.8326249
      ]
    },
    "f1ih2ftq": {
      "coords": [
        43.2825249,
        20.840312
      ]
    },
    "kqag8w3z": {
      "coords": [
        43.2826331,
        20.8406593
      ]
    },
    "essn030k": {
      "coords": [
        43.2702867,
        20.8007231
      ]
    },
    "jqb46oql": {
      "coords": [
        43.2930043,
        20.8170375
      ]
    },
    "1esdyltu": {
      "coords": [
        43.2930361,
        20.8170207
      ]
    },
    "kpuilnz1": {
      "coords": [
        43.2931568,
        20.8269679
      ]
    },
    "4m3ngptb": {
      "coords": [
        43.2929381,
        20.8267874
      ]
    },
    "jfp3rhdh": {
      "coords": [
        43.292687,
        20.826588
      ]
    },
    "8nlefve6": {
      "coords": [
        43.2923385,
        20.8262965
      ]
    },
    "1aybzh5t": {
      "coords": [
        43.292155,
        20.8261778
      ]
    },
    "lmaoliv8": {
      "coords": [
        43.2936793,
        20.8174566
      ]
    },
    "82udj5l4": {
      "coords": [
        43.2935964,
        20.8173718
      ]
    },
    "ll4lz0k7": {
      "coords": [
        43.2933677,
        20.8171657
      ]
    },
    "1bp4r3bt": {
      "coords": [
        43.2932228,
        20.816989
      ]
    },
    "1cod4djw": {
      "coords": [
        43.2930975,
        20.8168086
      ]
    },
    "jd76578v": {
      "coords": [
        43.2928581,
        20.81841
      ]
    },
    "cqj2vz1d": {
      "coords": [
        43.2929195,
        20.817673
      ]
    },
    "72jb6at4": {
      "coords": [
        43.2929037,
        20.8175055
      ]
    },
    "91mgatwo": {
      "coords": [
        43.292862,
        20.8170626
      ]
    },
    "9qnnsc45": {
      "coords": [
        43.2958586,
        20.8266589
      ]
    },
    "l6e1y1jx": {
      "coords": [
        43.2962349,
        20.8270232
      ]
    },
    "jdsjfx01": {
      "coords": [
        43.2962821,
        20.8271958
      ]
    },
    "6monyzn4": {
      "coords": [
        43.2966411,
        20.8277795
      ]
    },
    "6s85fmfr": {
      "coords": [
        43.2967541,
        20.8279937
      ]
    },
    "jym80435": {
      "coords": [
        43.29712,
        20.8283321
      ]
    },
    "b55p2tnd": {
      "coords": [
        43.2970149042815,
        20.82828375456029
      ]
    },
    "3l2t06xj": {
      "coords": [
        43.2921807,
        20.8290521
      ]
    },
    "6lqfeau1": {
      "coords": [
        43.2916286,
        20.8298576
      ]
    },
    "8xix11cj": {
      "coords": [
        43.2745924,
        20.811636
      ]
    },
    "a2ou6ahx": {
      "coords": [
        43.2788643,
        20.808742
      ]
    },
    "bevpi6ji": {
      "coords": [
        43.2835528,
        20.8099898
      ]
    },
    "8kydsfp2": {
      "coords": [
        43.2835177,
        20.8115282
      ]
    },
    "b6m9ull2": {
      "coords": [
        43.2836212,
        20.8107598
      ]
    },
    "a3wuj786": {
      "coords": [
        43.2729357,
        20.8203979
      ]
    },
    "fn2wae99": {
      "coords": [
        43.2958358,
        20.8266722
      ]
    },
    "1jfd7j89": {
      "coords": [
        43.2893435,
        20.8171233
      ]
    },
    "kemgymg": {
      "coords": [
        43.2889529,
        20.8170815
      ]
    },
    "ft7q1mc8": {
      "coords": [
        43.3181497,
        20.8317952
      ]
    },
    "i0ahxljr": {
      "coords": [
        43.2945749,
        20.8578776
      ]
    },
    "e81fjyon": {
      "coords": [
        43.2944695,
        20.8578279
      ]
    },
    "6qqv6hb5": {
      "coords": [
        43.2793744,
        20.807846
      ]
    },
    "kb1dgab4": {
      "coords": [
        43.281513,
        20.7961499
      ]
    },
    "dlzqq3il": {
      "coords": [
        43.2815602,
        20.7964588
      ]
    },
    "etgink5a": {
      "coords": [
        43.3043426,
        20.8265569
      ]
    },
    "fn3mh5lj": {
      "coords": [
        43.3042202,
        20.826594
      ]
    },
    "ks822sf6": {
      "coords": [
        43.2731466,
        20.8200776
      ]
    },
    "fksa888j": {
      "coords": [
        43.3045533,
        20.8262504
      ]
    },
    "27fdwji1": {
      "coords": [
        43.3047763,
        20.826206
      ]
    },
    "e10iuits": {
      "coords": [
        43.3050391,
        20.8262959
      ]
    },
    "723zt8j0": {
      "coords": [
        43.2838483,
        20.817639
      ]
    },
    "alravs2": {
      "coords": [
        43.2837301,
        20.8172705
      ]
    },
    "7de9aou6": {
      "coords": [
        43.2836399,
        20.8166451
      ]
    },
    "l6i4aprw": {
      "coords": [
        43.2835991,
        20.8158727
      ]
    },
    "8n2ydvtx": {
      "coords": [
        43.2835855,
        20.8147483
      ]
    },
    "juae8ays": {
      "coords": [
        43.2836558,
        20.8138437
      ]
    },
    "aqeqrdte": {
      "coords": [
        43.2836723,
        20.8134906
      ]
    },
    "iab8oo1c": {
      "coords": [
        43.2836806,
        20.8133005
      ]
    },
    "kjvj1o8w": {
      "coords": [
        43.2836898,
        20.8131175
      ]
    },
    "2sd76qzx": {
      "coords": [
        43.2837449,
        20.8129109
      ]
    },
    "e2t5je04": {
      "coords": [
        43.2837497,
        20.8125419
      ]
    },
    "ijjg0s9s": {
      "coords": [
        43.2837659,
        20.8123222
      ]
    },
    "8gkdewv1": {
      "coords": [
        43.2837707,
        20.8118107
      ]
    },
    "jhq1wrhh": {
      "coords": [
        43.2796593,
        20.818214
      ]
    },
    "9s0bpy88": {
      "coords": [
        43.2800033,
        20.8180557
      ]
    },
    "5wkob4my": {
      "coords": [
        43.2803035,
        20.8152046
      ]
    },
    "kaw3antr": {
      "coords": [
        43.280533,
        20.8149908
      ]
    },
    "crmursnb": {
      "coords": [
        43.2806554,
        20.8146726
      ]
    },
    "6p2qbtas": {
      "coords": [
        43.2807459,
        20.8141807
      ]
    },
    "1p07f0ri": {
      "coords": [
        43.3185119,
        20.8260205
      ]
    },
    "l2k9taby": {
      "coords": [
        43.3184984,
        20.8265012
      ]
    },
    "1cfqjb19": {
      "coords": [
        43.3184984,
        20.8269264
      ]
    },
    "j3d1pxve": {
      "coords": [
        43.3185253,
        20.8275857
      ]
    },
    "2ridqei5": {
      "coords": [
        43.3184805,
        20.8281526
      ]
    },
    "jooe9org": {
      "coords": [
        43.3184581,
        20.8286394
      ]
    },
    "7zd8hbw8": {
      "coords": [
        43.3184267,
        20.8292865
      ]
    },
    "fv7cl5i1": {
      "coords": [
        43.3184401,
        20.8297856
      ]
    },
    "bp8in94j": {
      "coords": [
        43.3184536,
        20.8302662
      ]
    },
    "awm95no5": {
      "coords": [
        43.3184625,
        20.8307222
      ]
    },
    "3rzm7mco": {
      "coords": [
        43.2816749,
        20.7969628
      ]
    },
    "79j0qar9": {
      "coords": [
        43.281669,
        20.7964584
      ]
    },
    "hhxtiawx": {
      "coords": [
        43.2816349,
        20.7962686
      ]
    },
    "15vpgnix": {
      "coords": [
        43.2814549,
        20.8028681
      ]
    },
    "grhk1xcq": {
      "coords": [
        43.2823742,
        20.8022233
      ]
    },
    "egreq0hv": {
      "coords": [
        43.3157286,
        20.8237564
      ]
    },
    "endxfvoo": {
      "coords": [
        43.3155691,
        20.8237588
      ]
    },
    "d8flzfq3": {
      "coords": [
        43.3153418,
        20.8239328
      ]
    },
    "kuwg2qls": {
      "coords": [
        43.3151874,
        20.8241068
      ]
    },
    "b3i7ytjl": {
      "coords": [
        43.3150886,
        20.8243286
      ]
    },
    "ke9bayax": {
      "coords": [
        43.2801367,
        20.8331588
      ]
    },
    "aevet8h5": {
      "coords": [
        43.2804171,
        20.8339383
      ]
    },
    "1hqvftnm": {
      "coords": [
        43.2808166,
        20.8350288
      ]
    },
    "izb0tzi3": {
      "coords": [
        43.2811153,
        20.8359555
      ]
    },
    "i66sfvdx": {
      "coords": [
        43.2813982,
        20.8366515
      ]
    },
    "kqq86nxq": {
      "coords": [
        43.2815788,
        20.8371912
      ]
    },
    "azyhmqmk": {
      "coords": [
        43.2790589,
        20.8354007
      ]
    },
    "f3v7ohih": {
      "coords": [
        43.2790778,
        20.8350548
      ]
    },
    "he90pead": {
      "coords": [
        43.2790698,
        20.8346839
      ]
    },
    "2jabudxy": {
      "coords": [
        43.2790382,
        20.8342878
      ]
    },
    "6jm2zplq": {
      "coords": [
        43.2790647,
        20.8339199
      ]
    },
    "ffq9v9og": {
      "coords": [
        43.2791023,
        20.833593
      ]
    },
    "bbufhv2w": {
      "coords": [
        43.279235,
        20.8331701
      ]
    },
    "dh76yrs0": {
      "coords": [
        43.2821725,
        20.8392765
      ]
    },
    "end6g4z4": {
      "coords": [
        43.2822033,
        20.8396372
      ]
    },
    "kc7sh440": {
      "coords": [
        43.2822465,
        20.8398986
      ]
    },
    "gafddgcu": {
      "coords": [
        43.29209142179873,
        20.829282186303253
      ]
    },
    "47p7iztr": {
      "coords": [
        43.295886560079566,
        20.826854762251248
      ]
    },
    "dko95udv": {
      "coords": [
        43.29294456765531,
        20.81681824452074
      ]
    },
    "l3jlk4sg": {
      "coords": [
        43.300975656151806,
        20.838538357539655
      ]
    },
    "dui9n1oh": {
      "coords": [
        43.30552561496693,
        20.826288846228806
      ]
    },
    "enp4dorq": {
      "coords": [
        43.32123801863613,
        20.83370764309336
      ]
    },
    "go5hw00o": {
      "coords": [
        43.272934463537275,
        20.819985439299863
      ]
    },
    "gs32l0re": {
      "coords": [
        43.283729178034186,
        20.812728050719247
      ]
    },
    "8q6kon5l": {
      "coords": [
        43.2702276737091,
        20.800559736580624
      ]
    },
    "ghn0te1m": {
      "coords": [
        43.27734006312388,
        20.80932385553946
      ]
    },
    "3fz9xqm5": {
      "coords": [
        43.28265330743474,
        20.811745748525063
      ]
    },
    "ier0w20s": {
      "coords": [
        43.28656227800826,
        20.836239790158263
      ]
    },
    "7f044yzk": {
      "coords": [
        43.28333085429535,
        20.834104834415108
      ]
    },
    "79yw027k": {
      "coords": [
        43.292105087844995,
        20.830030733176216
      ]
    },
    "7abz2mdd": {
      "coords": [
        43.294807000094174,
        20.842961127280333
      ]
    },
    "8x9x4yuu": {
      "coords": [
        43.2928000998623,
        20.87574600075472
      ]
    }
  },
  "graph": {
    "2ykjwcb5": {
      "k5si1z82": {
        "weight": 11.989263364051629,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "ephltv8y": {
      "g2s7aqkx": {
        "weight": 88.32347113482827,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "5rolwty6": {
      "73h632je": {
        "weight": 14.292701834159338,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "bh159i7r": {
      "8c6bbspx": {
        "weight": 36.93970485888859,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "gstm5po8": {
      "466c2pkb": {
        "weight": 13.727183540688493,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "gzcnfsc": {
      "jgjjms6n": {
        "weight": 79.52309168002549,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "7q6c58vx": {
      "h86b6ez": {
        "weight": 54.349498086466966,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "g7hxx6mo": {
      "h83bzc9m": {
        "weight": 75.23615872541151,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "kyj3ixoc": {
      "7j5chg4p": {
        "weight": 16.777664476350044,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "ap9b4f93": {
      "ldo3e65u": {
        "weight": 44.19606372718816,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      },
      "jz7bz0y6": {
        "weight": 19.472145706741582,
        "type": "SkiTrail"
      }
    },
    "fldtmpp8": {
      "5aoxjt80": {
        "weight": 23.42099927330143,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "c2owc27d": {
      "518ozcjv": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "f584dhmv": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "3whp6ks1": {
      "456vd27o": {
        "weight": 24.806210266948003,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "8q9twuc8": {
      "culffcsd": {
        "weight": 70.08969853003484,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "hpy8swel": {
      "fegwgrbj": {
        "weight": 35.766242059625036,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "kqowivk1": {
      "2uelshpo": {
        "weight": 39.1516683734703,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "9zv9bf65": {
      "2ors0yrd": {
        "weight": 23.536298100099764,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "dabqvt0g": {
      "19a0indk": {
        "weight": 43.6696065434504,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "8cmo6s4e": {
      "ju1ejb6a": {
        "weight": 47.98973637608305,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "gw1uludv": {
        "weight": 14.790087285947864,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "h5dbsifb": {
      "as3n81ut": {
        "weight": 17.289304340715727,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "b6m9ull2": {
        "weight": 22.548987199620967,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "g0iqn11t": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "6hl8klnx": {
      "ktlt4zhb": {
        "weight": 52.57913139789696,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "a873p1z0": {
      "6iquvmv4": {
        "weight": 40.053444494528975,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "4rqlg2ky": {
        "weight": 24.86291047442703,
        "type": "SkiTrail",
        "difficulty": "0",
        "name": "Пајино пресло"
      }
    },
    "aupi9mvn": {
      "3ed74ycv": {
        "weight": 31.294676249493268,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "2ipssokt": {
      "9y8j3bkr": {
        "weight": 19.32708404048815,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "60rt1k6r": {
      "1qxsa8oi": {
        "weight": 17.327243556534597,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "5wkr6zsw": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "f584dhmv": {
      "csx8lpzt": {
        "weight": 9.953147129257783,
        "type": "SkiTrail"
      }
    },
    "csx8lpzt": {
      "1pav65go": {
        "weight": 66.4061826589589,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      },
      "f584dhmv": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "1pav65go": {
      "5c3t92pd": {
        "weight": 95.27331251802325,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      }
    },
    "5c3t92pd": {
      "4zq9055n": {
        "weight": 24.618255870887147,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      }
    },
    "4zq9055n": {
      "h5m9t68z": {
        "weight": 25.041370808606633,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      }
    },
    "h5m9t68z": {
      "fzhf9wci": {
        "weight": 55.37246777435408,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      }
    },
    "fzhf9wci": {
      "ea8kd6il": {
        "weight": 83.9180876704127,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      },
      "gw4ctf5e": {
        "weight": 81.81636292448518,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ea8kd6il": {
      "5tcqox1v": {
        "weight": 105.73571138761994,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      }
    },
    "5tcqox1v": {
      "518ozcjv": {
        "weight": 11.492194009989982,
        "type": "SkiTrail",
        "name": "Мало језеро",
        "difficulty": 1
      },
      "i31kjfkz": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "518ozcjv": {
      "c2owc27d": {
        "weight": 13.138075451824804,
        "type": "SkiTrail",
        "difficulty": "0",
        "name": "Мало језеро"
      },
      "5tcqox1v": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "abvxq1yi": {
      "ilgu2x77": {
        "weight": 43.927379599903965,
        "type": "SkiTrail",
        "name": "Црна дубока"
      },
      "ks822sf6": {
        "weight": 22.261416206948283,
        "type": "SkiTrail"
      },
      "go5hw00o": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "ilgu2x77": {
      "6jvv73dh": {
        "weight": 34.23870512629916,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "6jvv73dh": {
      "1wv4qa68": {
        "weight": 31.658094362445404,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "1wv4qa68": {
      "e1oyct6p": {
        "weight": 124.98765085737327,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "e1oyct6p": {
      "jyb7ynpc": {
        "weight": 65.73934298410266,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "jyb7ynpc": {
      "gnpkdjj9": {
        "weight": 66.27390724312693,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "gnpkdjj9": {
      "9i3ktzuh": {
        "weight": 88.60474054620371,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "9i3ktzuh": {
      "dov5tqo0": {
        "weight": 42.164253540637944,
        "type": "SkiTrail",
        "name": "Црна дубока"
      },
      "l5c7smvt": {
        "weight": 17.846268121355156,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "dov5tqo0": {
      "gabh6vgj": {
        "weight": 76.91670925328968,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "gabh6vgj": {
      "fxkmhb80": {
        "weight": 14.526598301688432,
        "type": "SkiTrail",
        "name": "Црна дубока"
      },
      "5dhndshg": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "fxkmhb80": {
      "6dhihpqz": {
        "weight": 45.55502570466824,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "6dhihpqz": {
      "8z7ruz8c": {
        "weight": 34.888694108921676,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "8z7ruz8c": {
      "ep8odf65": {
        "weight": 42.61528917990339,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "ep8odf65": {
      "18npl1sd": {
        "weight": 25.59083143717652,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "18npl1sd": {
      "kg9zvjir": {
        "weight": 22.56595718670907,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "kg9zvjir": {
      "rvcawtm": {
        "weight": 44.14394269529739,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "rvcawtm": {
      "kajfle5i": {
        "weight": 21.694362002593802,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "kajfle5i": {
      "ac7nublz": {
        "weight": 46.88769075335405,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "ac7nublz": {
      "7vuzm0zq": {
        "weight": 47.82447132739679,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "7vuzm0zq": {
      "j4fe0n9f": {
        "weight": 63.89784774274315,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "j4fe0n9f": {
      "j8d0qdg8": {
        "weight": 35.88127467489997,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "j8d0qdg8": {
      "6f8uq1l7": {
        "weight": 28.409946959028357,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "6f8uq1l7": {
      "bski1ru2": {
        "weight": 36.6583873185125,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "bski1ru2": {
      "3jst6zae": {
        "weight": 49.0551920775341,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "3jst6zae": {
      "e6nq1y6p": {
        "weight": 49.59642783364304,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "e6nq1y6p": {
      "8t2bm4da": {
        "weight": 71.76232438109983,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "8t2bm4da": {
      "ee1zvvob": {
        "weight": 25.753268644550644,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "ee1zvvob": {
      "ldo3e65u": {
        "weight": 24.802935187021035,
        "type": "SkiTrail",
        "name": "Црна дубока"
      }
    },
    "ldo3e65u": {
      "ke9bayax": {
        "weight": 50.02632666260468,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      },
      "jz7bz0y6": {
        "weight": 50.43139149190592,
        "type": "SkiTrail",
        "name": "Дубока 2"
      },
      "ee1zvvob": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "2uuwxw61": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "ap9b4f93": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "bysh2ubi": {
      "1glb3ejn": {
        "weight": 33.43441746945286,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "1glb3ejn": {
      "bgz7bcie": {
        "weight": 56.073698094574695,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "bgz7bcie": {
      "80ja29au": {
        "weight": 45.13705165561868,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "80ja29au": {
      "7yhub2um": {
        "weight": 38.48842874545285,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "7yhub2um": {
      "cb3au05l": {
        "weight": 40.81802063942898,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "cb3au05l": {
      "41b1hjcg": {
        "weight": 34.59062202311397,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "41b1hjcg": {
      "2ykjwcb5": {
        "weight": 31.831296379776138,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "9eac0dyq": {
      "2ik1j3yg": {
        "weight": 57.47463393975109,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "imw9gne1": {
        "weight": 89.75147441778209,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "2ik1j3yg": {
      "37b3gh74": {
        "weight": 58.047081020663654,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "37b3gh74": {
      "c4p4v35u": {
        "weight": 71.33916887483775,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "c4p4v35u": {
      "7qm9v12m": {
        "weight": 57.26879474559078,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "7qm9v12m": {
      "jjs5yrsq": {
        "weight": 53.75932774701767,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "jjs5yrsq": {
      "lkqdxb91": {
        "weight": 46.57099039076052,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "lkqdxb91": {
      "9o42fyhm": {
        "weight": 34.615109236886646,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "9o42fyhm": {
      "bgzs1lxn": {
        "weight": 21.267387048539852,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "bgzs1lxn": {
      "bzj317ix": {
        "weight": 59.179455004397866,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "bzj317ix": {
      "l3ff3eck": {
        "weight": 15.848121611296786,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "l3ff3eck": {
      "7twos5nr": {
        "weight": 25.47830583426081,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "7twos5nr": {
      "389nkr8h": {
        "weight": 70.98687750599794,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "389nkr8h": {
      "kd793kp4": {
        "weight": 119.19776939462524,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "kd793kp4": {
      "68zkfrsm": {
        "weight": 32.69808953903415,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "68zkfrsm": {
      "ez06xdpa": {
        "weight": 37.17606588333166,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "ez06xdpa": {
      "xyxc8o2": {
        "weight": 21.109810799620966,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "1nvh0hi3": {
      "ghcio01u": {
        "weight": 68.83286899671506,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      },
      "j27t8k3n": {
        "weight": 55.55212008114691,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "ghcio01u": {
      "xgoy3u3": {
        "weight": 56.2770168449149,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "xgoy3u3": {
      "elxuickb": {
        "weight": 31.406191026841217,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "elxuickb": {
      "ig7yxb79": {
        "weight": 278.2038198380115,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      },
      "iypxt9c7": {
        "weight": 75.64314027321811,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ig7yxb79": {
      "hgrbx0nh": {
        "weight": 145.9321734839017,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "hgrbx0nh": {
      "40afn7py": {
        "weight": 103.99395707152449,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "40afn7py": {
      "6ho2m33e": {
        "weight": 40.01174711097649,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "6ho2m33e": {
      "eyqligsy": {
        "weight": 15.736076965881397,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "ghn0te1m": {
        "weight": 56.2281929461321,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Суво рудиште"
      }
    },
    "4a1ewbaj": {
      "6ztp9onh": {
        "weight": 84.68859792456794,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "6ztp9onh": {
      "bbhpn5uj": {
        "weight": 46.97472332388102,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "bbhpn5uj": {
      "6hrv2zqz": {
        "weight": 34.325591008534104,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "6hrv2zqz": {
      "29fuek5": {
        "weight": 31.420800637297784,
        "type": "SkiTrail",
        "name": "Суво рудиште",
        "difficulty": 1
      }
    },
    "29fuek5": {
      "k5vf081p": {
        "weight": 15.852025700730298,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      },
      "f9l8j3dy": {
        "weight": 74.04707687489046,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "3q3vpwab": {},
    "h57898bw": {
      "6csu3vmq": {
        "weight": 26.12655293883032,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "6csu3vmq": {
      "bn1ufltc": {
        "weight": 22.0860621038436,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "bn1ufltc": {
      "7sy1hg0e": {
        "weight": 61.75788735086951,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "7sy1hg0e": {
      "4cdn3zz9": {
        "weight": 130.4776340176107,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "4cdn3zz9": {
      "ikg54l5k": {
        "weight": 119.67665398507793,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "ikg54l5k": {
      "liqv0u2g": {
        "weight": 83.33111284113197,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "liqv0u2g": {
      "9bmduhk8": {
        "weight": 59.81785537960047,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "9bmduhk8": {
      "7bx5ujcd": {
        "weight": 77.23392380589394,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "7bx5ujcd": {
      "h80ck36y": {
        "weight": 63.89973161613881,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "h80ck36y": {
      "l9kx47i0": {
        "weight": 49.170037404078236,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "l9kx47i0": {
      "ier0w20s": {
        "weight": 23.24372865463687,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Кнежевске баре"
      }
    },
    "h8e8wx1r": {
      "caoh7i39": {
        "weight": 12.618348948966831,
        "type": "SkiTrail",
        "name": "Кнежевске баре",
        "difficulty": 1
      }
    },
    "caoh7i39": {
      "whyzceg": {
        "weight": 16.451814187029022,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Кнежевске баре"
      }
    },
    "whyzceg": {
      "3q3vpwab": {
        "weight": 777.2302687953853,
        "type": "SkiLift",
        "name": "14 Кнежевске баре"
      }
    },
    "liqpda8": {
      "8wlz2cen": {
        "weight": 16.09041302414522,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "3l2t06xj": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "8wlz2cen": {
      "7oery7cy": {
        "weight": 21.15005316090952,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "liqpda8": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "7oery7cy": {
      "765mpfo4": {
        "weight": 21.333200629869463,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "765mpfo4": {
      "b3tjb4h5": {
        "weight": 21.909009906823375,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "b3tjb4h5": {
      "ayuvbpxl": {
        "weight": 24.51678023099915,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ayuvbpxl": {
      "5lta0whb": {
        "weight": 86.8120522088891,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "dcxe2pyv": {
        "weight": 54.986209211306615,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "5lta0whb": {
      "gm5tfs9f": {
        "weight": 59.95848597063061,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gm5tfs9f": {
      "fdr8dv1s": {
        "weight": 8.627082535796362,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "fdr8dv1s": {
      "lb42p5co": {
        "weight": 38.69023912175661,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "lb42p5co": {
      "34ty9hx": {
        "weight": 27.40862869406724,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "34ty9hx": {
      "ayqd93u": {
        "weight": 51.01546988823281,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ayqd93u": {
      "fz9nqobt": {
        "weight": 26.923747918886793,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "fz9nqobt": {
      "79ru61t3": {
        "weight": 23.515024573468615,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "79ru61t3": {
      "a6aytwrd": {
        "weight": 10.302020223430858,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "a6aytwrd": {
      "8lji11gh": {
        "weight": 33.64434838796894,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8lji11gh": {
      "40unytur": {
        "weight": 25.190268912677116,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "40unytur": {
      "juacx9r8": {
        "weight": 17.615968805406563,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "juacx9r8": {
      "adw4myou": {
        "weight": 28.849945277595594,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "adw4myou": {
      "c7ofrfij": {
        "weight": 31.47024136434146,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "c7ofrfij": {
      "dd2bzsk3": {
        "weight": 30.250987504484577,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "dd2bzsk3": {
      "hu7x2di": {
        "weight": 28.039115289617264,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "hu7x2di": {
      "a65e4nig": {
        "weight": 20.90677514253253,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "a65e4nig": {
      "8bagyrbm": {
        "weight": 28.465121276481863,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8bagyrbm": {
      "j8mplg34": {
        "weight": 18.93642085111347,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "j8mplg34": {
      "1spi2im8": {
        "weight": 27.556451610564583,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1spi2im8": {
      "795o02v1": {
        "weight": 40.59425229953487,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "795o02v1": {
      "bclp740y": {
        "weight": 16.052417036098884,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "bclp740y": {
      "kg0uwh6y": {
        "weight": 34.69470639739294,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "kg0uwh6y": {
      "adpdqli1": {
        "weight": 12.954293805518331,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "adpdqli1": {
      "buj5xf55": {
        "weight": 30.731133496895882,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "buj5xf55": {
      "sefzzxj": {
        "weight": 58.59165705576601,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "sefzzxj": {
      "jqqnfutb": {
        "weight": 99.24930312251946,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jqqnfutb": {
      "6jb3b4d9": {
        "weight": 16.496974440618473,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "6jb3b4d9": {
      "b91gpjhf": {
        "weight": 17.93116965862985,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "1jfd7j89": {
        "weight": 18.467050205365787,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "73cbylrc": {
      "dbx9vytn": {
        "weight": 73.06511526904347,
        "type": "SkiTrail",
        "name": "Леденица"
      },
      "2lq8behv": {
        "weight": 81.77283749210129,
        "type": "SkiTrail",
        "name": null
      }
    },
    "dbx9vytn": {
      "bh159i7r": {
        "weight": 53.588870175190145,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "8c6bbspx": {
      "gstm5po8": {
        "weight": 38.66749000089646,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "466c2pkb": {
      "l5vatklh": {
        "weight": 47.11551870967365,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "l5vatklh": {
      "dn2h6clx": {
        "weight": 65.38232144679498,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "dn2h6clx": {
      "u6rtx0v": {
        "weight": 39.032565988873195,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "u6rtx0v": {
      "3whp6ks1": {
        "weight": 36.64179594828582,
        "type": "SkiTrail",
        "name": "Леденица"
      }
    },
    "hscr0jqw": {
      "eep9jbu4": {
        "weight": 75.01480207125807,
        "type": "SkiTrail",
        "name": "Гвоздац"
      },
      "gafddgcu": {
        "weight": 35.645553143590064,
        "type": "SkiTrail"
      }
    },
    "k6vuqg96": {
      "7aji5bza": {
        "weight": 31.392610091937154,
        "type": "SkiTrail",
        "name": "Гвоздац"
      },
      "gafddgcu": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "79yw027k": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "7aji5bza": {
      "kivq7m1j": {
        "weight": 33.68594940278604,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "kivq7m1j": {
      "7scvxfyp": {
        "weight": 58.95149663521808,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "7scvxfyp": {
      "1fieb0e3": {
        "weight": 73.71093462146527,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "1fieb0e3": {
      "fgxiykwt": {
        "weight": 99.08155359280154,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "fgxiykwt": {
      "hgtuatyk": {
        "weight": 89.49453689679105,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "hgtuatyk": {
      "jxufn07v": {
        "weight": 85.23206430307438,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "jxufn07v": {
      "9uecbnti": {
        "weight": 37.26352862615727,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "9uecbnti": {
      "3whp6ks1": {
        "weight": 29.970570902752492,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "456vd27o": {
      "3ga18i6": {
        "weight": 65.0357189605813,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "3ga18i6": {
      "efehflci": {
        "weight": 55.27549515770812,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "efehflci": {
      "k3qtzyvq": {
        "weight": 8.776241876833105,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "k3qtzyvq": {
      "e64jkg4j": {
        "weight": 9.946380986588492,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "e64jkg4j": {
      "ixpn7uzl": {
        "weight": 31.371620078670023,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "ixpn7uzl": {
      "7t0besa7": {
        "weight": 14.839297701392205,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "7t0besa7": {
      "c31oal3b": {
        "weight": 15.36908800337518,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "c31oal3b": {
      "aw72ptgh": {
        "weight": 87.60117641670173,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "aw72ptgh": {
      "q2g9lyv": {
        "weight": 15.437059856168696,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "q2g9lyv": {
      "ax2znljy": {
        "weight": 77.08473319033767,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "ax2znljy": {
      "gx96cbp0": {
        "weight": 41.02634810978706,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "gx96cbp0": {
      "2wpkct5g": {
        "weight": 82.13363063618695,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "2wpkct5g": {
      "7dcuhzm7": {
        "weight": 84.06444899317292,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "7dcuhzm7": {
      "9zv9bf65": {
        "weight": 29.471960096919982,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "2ors0yrd": {
      "hptxsexu": {
        "weight": 21.94943348373441,
        "type": "SkiTrail"
      }
    },
    "hptxsexu": {
      "318fftrk": {
        "weight": 27.42853730031612,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "318fftrk": {
      "l3jlk4sg": {
        "weight": 19.559289896476773,
        "type": "SkiTrail"
      }
    },
    "4uggog47": {
      "hscr0jqw": {
        "weight": 1180.7055662573787,
        "type": "SkiLift",
        "name": "19 Гвоздац"
      }
    },
    "h0bikx45": {
      "gjsyw06e": {
        "weight": 23.77949539400411,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "gjsyw06e": {
      "ifn1oyd": {
        "weight": 18.530738919202804,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "ifn1oyd": {
      "gid84gig": {
        "weight": 14.880314204995358,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "gid84gig": {
      "jovuiw82": {
        "weight": 17.491259669237667,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "jovuiw82": {
      "62lnpl47": {
        "weight": 16.0475551757084,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "62lnpl47": {
      "4i7zt29l": {
        "weight": 20.54366190243089,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "4i7zt29l": {
      "4igyrg3": {
        "weight": 21.20924962824225,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "4igyrg3": {
      "9gz9q4mv": {
        "weight": 49.28926723514862,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "9gz9q4mv": {
      "lnpl8tl": {
        "weight": 30.13528353424954,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "lnpl8tl": {
      "jj1jvziw": {
        "weight": 29.358356851009813,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "jj1jvziw": {
      "d2wu0y46": {
        "weight": 31.748150096054374,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "d2wu0y46": {
      "f45yg9ui": {
        "weight": 34.93524103770332,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "f45yg9ui": {
      "3v9axeu5": {
        "weight": 38.046268845620695,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "3v9axeu5": {
      "eobclln3": {
        "weight": 79.30533336925009,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "eobclln3": {
      "2hhdjc2z": {
        "weight": 40.893235007838655,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "2hhdjc2z": {
      "epl9a13s": {
        "weight": 23.80562682152853,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "epl9a13s": {
      "g6ca8sip": {
        "weight": 20.252566565691296,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "g6ca8sip": {
      "b0pd5anj": {
        "weight": 20.695241153769068,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "b0pd5anj": {
      "93yhf8s8": {
        "weight": 26.324403057443863,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "93yhf8s8": {
      "ghqe38zm": {
        "weight": 24.538537132523274,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "ghqe38zm": {
      "u8x5ec3": {
        "weight": 30.783037035270137,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "u8x5ec3": {
      "ion5r95m": {
        "weight": 18.252795643282134,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "ion5r95m": {
      "ip6iq9rj": {
        "weight": 19.0435270630886,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "ip6iq9rj": {
      "20mae6pk": {
        "weight": 24.908617638116468,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "20mae6pk": {
      "klvq9ixr": {
        "weight": 32.7836730846187,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "klvq9ixr": {
      "gyu8hpec": {
        "weight": 16.286858360478927,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "gyu8hpec": {
      "4wskd5kh": {
        "weight": 12.778029536650093,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "4wskd5kh": {
      "l0k3lack": {
        "weight": 15.554997319938696,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "l0k3lack": {
      "9c3od4un": {
        "weight": 31.433976762514583,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "9c3od4un": {
      "jbn3gigu": {
        "weight": 42.56720405249272,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "jbn3gigu": {
      "jhwg2lz4": {
        "weight": 20.377759426798814,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "jhwg2lz4": {
      "eyfqd1f3": {
        "weight": 22.288931189049258,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "eyfqd1f3": {
      "lmaoliv8": {
        "weight": 11.224424732442614,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ka8856a9": {
      "bz21em7g": {
        "weight": 26.489797781477883,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      },
      "29fuek5": {
        "weight": 39.96310962906891,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "bz21em7g": {
      "f9l8j3dy": {
        "weight": 73.3034941078161,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "f9l8j3dy": {
      "1obg4sd6": {
        "weight": 105.76572620374824,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      },
      "kxi24qoz": {
        "weight": 35.25731500961717,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1obg4sd6": {
      "jlltzjfw": {
        "weight": 69.39512629057027,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "jlltzjfw": {
      "adtk0s1h": {
        "weight": 67.54921595347042,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "adtk0s1h": {
      "498iojn9": {
        "weight": 51.31197308178017,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "498iojn9": {
      "3e5pf1yk": {
        "weight": 15.833036176691294,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "3e5pf1yk": {
      "1u3whqi7": {
        "weight": 34.23398679687969,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      },
      "15vpgnix": {
        "weight": 68.99300898008303,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "1u3whqi7": {
      "ekcc3793": {
        "weight": 83.14953384184979,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "ekcc3793": {
      "h253bz28": {
        "weight": 80.88955375600362,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "h253bz28": {
      "8ws9tlgv": {
        "weight": 63.383390474040496,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "8ws9tlgv": {
      "9xxexrak": {
        "weight": 66.35357585036235,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "9xxexrak": {
      "7zgamxfj": {
        "weight": 35.44487346655826,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "7zgamxfj": {
      "9wje0rg4": {
        "weight": 34.62289227358425,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "9wje0rg4": {
      "2q19qnzo": {
        "weight": 15.67161981902174,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "2q19qnzo": {
      "jr3xzvrt": {
        "weight": 21.58511659414872,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "jr3xzvrt": {
      "bomz1c7i": {
        "weight": 57.30864106169171,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "bomz1c7i": {
      "3rzm7mco": {
        "weight": 32.601165664482735,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "97adle0h": {
      "2bomvkd": {
        "weight": 10.697742092618272,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      }
    },
    "2bomvkd": {
      "6sdrw3b7": {
        "weight": 23.62510449906351,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      },
      "97adle0h": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "6sdrw3b7": {
      "43wozpjt": {
        "weight": 29.046906204249673,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      }
    },
    "43wozpjt": {
      "9umeqerg": {
        "weight": 32.259754337891714,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      }
    },
    "9umeqerg": {
      "gg762zhu": {
        "weight": 46.083326368402986,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      }
    },
    "gg762zhu": {
      "hifuyeol": {
        "weight": 52.11231473527496,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      }
    },
    "hifuyeol": {
      "ce9hngz": {
        "weight": 11.587484215935483,
        "type": "SkiTrail",
        "name": "Машинац",
        "difficulty": 1
      }
    },
    "ce9hngz": {
      "4xsilxe2": {
        "weight": 17.94648308614868,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "fmtd7xml": {
        "weight": 79.74898609955189,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "4xsilxe2": {
      "du1i6h2r": {
        "weight": 18.588183066504474,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "du1i6h2r": {
      "8b2a1rrb": {
        "weight": 8.940690604417595,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8b2a1rrb": {
      "bmalbd2d": {
        "weight": 10.354993958539314,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "bmalbd2d": {
      "1in2pdzf": {
        "weight": 18.322676726390394,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1in2pdzf": {
      "ghupt1f9": {
        "weight": 16.01367889783242,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ghupt1f9": {
      "k6tlis2k": {
        "weight": 14.80093930408194,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "k6tlis2k": {
      "2u4ts622": {
        "weight": 19.264106489989874,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "2u4ts622": {
      "gs32l0re": {
        "weight": 18.757841184967777,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "bvwjj5nq": {
      "2ygfrl5": {
        "weight": 37.93863712845147,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "2ygfrl5": {
      "krzpq3pu": {
        "weight": 48.036634537699314,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "bt7dc9nn": {
        "weight": 79.16771072951289,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      },
      "f07743kg": {
        "weight": 33.07673803726239,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "b0s65bau": {
        "weight": 23.322245180563236,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      },
      "4v6l0l60": {
        "weight": 25.005190948690874,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Караман гребен"
      },
      "5dknj17m": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "bvwjj5nq": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "ex24ttfe": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "4v6l0l60": {
      "1y3zlkpo": {
        "weight": 46.95498881433596,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "2ygfrl5": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "1y3zlkpo": {
      "dafkb3vy": {
        "weight": 65.93911730799334,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "dafkb3vy": {
      "7qh0bj3z": {
        "weight": 44.79510921378754,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "7qh0bj3z": {
      "2x3mwkju": {
        "weight": 39.6489094042806,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "2x3mwkju": {
      "ephltv8y": {
        "weight": 27.004304147122046,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "g2s7aqkx": {
      "kyj3ixoc": {
        "weight": 58.03637515728287,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "7j5chg4p": {
      "kec1fb6c": {
        "weight": 12.323519035032497,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "kec1fb6c": {
      "gknacfrh": {
        "weight": 13.885434385968228,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "fs7grt5y": {
        "weight": 33.38556538862823,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "gknacfrh": {
      "3uye6a8z": {
        "weight": 49.178695023252715,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "3uye6a8z": {
      "hgl94oa1": {
        "weight": 79.5180820938899,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "hgl94oa1": {
      "5etqjvu4": {
        "weight": 56.668719385238845,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "5etqjvu4": {
      "32a5p3te": {
        "weight": 127.53830902878686,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "32a5p3te": {
      "8oj1h6v5": {
        "weight": 10.05079604461196,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "8oj1h6v5": {
      "2b42i3hw": {
        "weight": 26.923097640204134,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "2b42i3hw": {
      "g2o8uwy4": {
        "weight": 33.073770682875626,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "g2o8uwy4": {
      "nu4anvo": {
        "weight": 79.29555347662273,
        "type": "SkiTrail",
        "name": null
      },
      "723zt8j0": {
        "weight": 20.44845493847287,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "eb120vxw": {
      "g0iqn11t": {
        "weight": 37.47039263712508,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "8gkdewv1": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "5s63elxr": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "g0iqn11t": {
      "h5dbsifb": {
        "weight": 11.405973393447141,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "eb120vxw": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "as3n81ut": {
      "5uqqf8id": {
        "weight": 24.695102540387676,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "h5dbsifb": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "5uqqf8id": {
      "cycqnrkf": {
        "weight": 18.598424687763952,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "as3n81ut": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "9tk5l7bj": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "cycqnrkf": {
      "bevpi6ji": {
        "weight": 11.070470865970977,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "3ln2nlgt": {
        "weight": 27.69313992673916,
        "type": "SkiTrail",
        "difficulty": "0",
        "name": "Долина спортова"
      },
      "5uqqf8id": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "3ln2nlgt": {
      "lb9np291": {
        "weight": 20.53950887919515,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "cycqnrkf": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "lb9np291": {
      "65e7apf8": {
        "weight": 17.883226606924364,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "3ln2nlgt": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "65e7apf8": {
      "3q0bcb74": {
        "weight": 13.667081651510784,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "lb9np291": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "3q0bcb74": {
      "gxnabfni": {
        "weight": 18.957154958606473,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "65e7apf8": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "gxnabfni": {
      "jdiqx1va": {
        "weight": 26.305557515025594,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "3q0bcb74": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "jdiqx1va": {
      "80kz9ut7": {
        "weight": 24.113942725262874,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "gxnabfni": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "80kz9ut7": {
      "ev99f1t9": {
        "weight": 18.364997535261846,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "jdiqx1va": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "ev99f1t9": {
      "i31kjfkz": {
        "weight": 20.291127914622788,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "80kz9ut7": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "i31kjfkz": {
      "5tcqox1v": {
        "weight": 21.237087978967022,
        "type": "SkiTrail",
        "name": "Долина спортова",
        "difficulty": 0
      },
      "ev99f1t9": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "9w8f4x3b": {
      "83elhbi8": {
        "weight": 33.74962666557244,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "8bp0uut9": {
        "weight": 52.43913631371862,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "83elhbi8": {
      "2xr7kj25": {
        "weight": 53.93199206506852,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "2xr7kj25": {
      "j5ppim7e": {
        "weight": 32.08932877375092,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "j5ppim7e": {
      "8smfl41": {
        "weight": 24.397016871814177,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "8smfl41": {
      "82aygi08": {
        "weight": 25.46003265254171,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "82aygi08": {
      "ijbgql30": {
        "weight": 31.490952245995032,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "ijbgql30": {
      "9rkw1itv": {
        "weight": 31.531632716469865,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "9rkw1itv": {
      "9y8j3bkr": {
        "weight": 47.311804346814995,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "9y8j3bkr": {
      "exk5brmg": {
        "weight": 62.02436413268433,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "8zfku9ox": {
        "weight": 28.28966007316571,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "exk5brmg": {
      "4d5zt80z": {
        "weight": 54.02176149997492,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "4d5zt80z": {
      "f4zv4sln": {
        "weight": 81.887277134473,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "f4zv4sln": {
      "3cgzzop6": {
        "weight": 47.257928780629946,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "3cgzzop6": {
      "19a0indk": {
        "weight": 66.88914872353773,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "19a0indk": {
      "7vqhnkv8": {
        "weight": 41.41208116336529,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "7vqhnkv8": {
      "hovhviqa": {
        "weight": 54.8967949282113,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "hovhviqa": {
      "h09wbiz0": {
        "weight": 23.04407762030179,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "ljzkzmcm": {
        "weight": 50.346585095371786,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      },
      "jhq1wrhh": {
        "weight": 36.15742928162454,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "h09wbiz0": {
      "gxbh47nv": {
        "weight": 23.328161874529396,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "gxbh47nv": {
      "579cwd0y": {
        "weight": 25.6910465989449,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "579cwd0y": {
      "dvpr3vp1": {
        "weight": 20.27850938405732,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "5wkr6zsw": {
      "60rt1k6r": {
        "weight": 23.17581054662946,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "bi3eqvds": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "6s85fmfr": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "1qxsa8oi": {
      "h59hco7k": {
        "weight": 14.418344686982113,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "6ysdrnuy": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "60rt1k6r": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "h59hco7k": {
      "7qxyktm1": {
        "weight": 19.948291834329734,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7qxyktm1": {
      "cgkjm0dn": {
        "weight": 28.249586543853674,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "cgkjm0dn": {
      "wk25thj": {
        "weight": 63.56897346909721,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "wk25thj": {
      "8e6be1aw": {
        "weight": 21.29616756624077,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8e6be1aw": {
      "l9h5mgg9": {
        "weight": 12.691266615113111,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "l9h5mgg9": {
      "azfnvldk": {
        "weight": 10.013042911962584,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "azfnvldk": {
      "abc63x3w": {
        "weight": 9.786905526877147,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "abc63x3w": {
      "corohkw5": {
        "weight": 17.86812671810964,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "98vd675s": {
      "1rc5qf70": {
        "weight": 24.056248858658414,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      },
      "gafddgcu": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "0"
      }
    },
    "1rc5qf70": {
      "3ebl8p5a": {
        "weight": 32.17135079963676,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "3ebl8p5a": {
      "8q9twuc8": {
        "weight": 23.797361303632478,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "culffcsd": {
      "ezqc2oqt": {
        "weight": 50.70564372509795,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "ezqc2oqt": {
      "abc63x3w": {
        "weight": 77.41471210550854,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "bi3eqvds": {
      "2pjonz82": {
        "weight": 34.45529722451501,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "5wkr6zsw": {
        "weight": 21.711911403405725,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "6s85fmfr": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "b55p2tnd": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "2pjonz82": {
      "gtzfg54h": {
        "weight": 66.47680635234134,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gtzfg54h": {
      "3vpkh8sp": {
        "weight": 11.651552766550903,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "3vpkh8sp": {
      "888guui4": {
        "weight": 51.388393602159134,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "888guui4": {
      "atmh0706": {
        "weight": 56.02959238123939,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "atmh0706": {
      "dhsk5774": {
        "weight": 54.08318029717761,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "dhsk5774": {
      "1qihaie4": {
        "weight": 44.501822632371464,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1qihaie4": {
      "j9w9fpgn": {
        "weight": 20.06272609883949,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "j9w9fpgn": {
      "1e5ixud6": {
        "weight": 19.8899423088214,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1e5ixud6": {
      "3j7qlkwb": {
        "weight": 25.066873097904274,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "3j7qlkwb": {
      "j1xm6ytx": {
        "weight": 33.38716770746303,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "j1xm6ytx": {
      "irjyk3va": {
        "weight": 34.58516239913009,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "irjyk3va": {
      "ej1n2jfd": {
        "weight": 36.0715992674567,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ej1n2jfd": {
      "jtc6bsjs": {
        "weight": 63.635796236008055,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jtc6bsjs": {
      "8caiu3p7": {
        "weight": 81.01222515768306,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8caiu3p7": {
      "ldbg65eq": {
        "weight": 35.452599639231615,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ldbg65eq": {
      "fyus8rz6": {
        "weight": 40.21916718447217,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "fyus8rz6": {
      "dc2gxao1": {
        "weight": 34.884447804639294,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "dc2gxao1": {
      "e4b8j5o1": {
        "weight": 39.953871377268705,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "e4b8j5o1": {
      "drz1liz": {
        "weight": 59.806099289415975,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "drz1liz": {
      "fksa888j": {
        "weight": 35.347786600748215,
        "type": "SkiTrail",
        "name": null
      },
      "etgink5a": {
        "weight": 17.070606666894303,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "3r9dezf6": {
      "5o7i7scz": {
        "weight": 11.229137074333062,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      },
      "cms2kz8i": {
        "weight": 35.206226839296384,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "5o7i7scz": {
      "enpriihr": {
        "weight": 11.835171151730592,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "enpriihr": {
      "3fyfudkq": {
        "weight": 21.28646081475356,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "3fyfudkq": {
      "rw1oxc9": {
        "weight": 31.80293884309575,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "rw1oxc9": {
      "a6z1wdsk": {
        "weight": 23.606549339922775,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "a6z1wdsk": {
      "9v5kgzjy": {
        "weight": 44.58662624796879,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "9v5kgzjy": {
      "llzy5ozv": {
        "weight": 48.91896298004887,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "llzy5ozv": {
      "i1ztbwu9": {
        "weight": 15.792533639921436,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "i1ztbwu9": {
      "ev0d4rvv": {
        "weight": 93.95822987424143,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "ev0d4rvv": {
      "kswyuue5": {
        "weight": 98.88922524019173,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "kswyuue5": {
      "2uhdgsh1": {
        "weight": 76.22010431437717,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "2uhdgsh1": {
      "b3lsjfy0": {
        "weight": 88.31161620766319,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "b3lsjfy0": {
      "7jct38ik": {
        "weight": 15.733506376757429,
        "type": "SkiTrail",
        "name": "Јарам",
        "difficulty": 1
      }
    },
    "7jct38ik": {
      "dui9n1oh": {
        "weight": 18.869225818616837,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "bonea9nq": {
      "3r9dezf6": {
        "weight": 566.3102237391261,
        "type": "SkiLift",
        "name": "11 Јарам"
      },
      "dui9n1oh": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "ii5o8v2i": {
      "6kot4qu5": {
        "weight": 63.034669526768134,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      },
      "jbm2rgt4": {
        "weight": 147.31132463098407,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "6kot4qu5": {
      "7670f7d0": {
        "weight": 47.45179189539648,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "7670f7d0": {
      "d71nitbt": {
        "weight": 80.03327853318012,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "d71nitbt": {
      "47hkucy8": {
        "weight": 28.09614367052403,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "47hkucy8": {
      "4oyw6gso": {
        "weight": 91.87708466568925,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "4oyw6gso": {
      "azwaeoiu": {
        "weight": 43.767565555144195,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "azwaeoiu": {
      "4y0da8bv": {
        "weight": 85.70737681739703,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "4y0da8bv": {
      "fu7l2wvh": {
        "weight": 54.25301751466915,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "fu7l2wvh": {
      "48j3024i": {
        "weight": 63.77247483305318,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "48j3024i": {
      "6c37kiad": {
        "weight": 28.910017027892934,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "6c37kiad": {
      "ijfdoukp": {
        "weight": 28.213893431928355,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "ijfdoukp": {
      "iqyevlcd": {
        "weight": 13.689794102350618,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "iqyevlcd": {
      "kec1fb6c": {
        "weight": 22.892294290014824,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Караман гребен",
        "difficulty": 1
      }
    },
    "j34svbtn": {
      "fdb8qeim": {
        "weight": 27.145182198314213,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "a8quxc2k": {
        "weight": 21.90246378263321,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      },
      "gafddgcu": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "fdb8qeim": {
      "cjo41isq": {
        "weight": 107.7303578454811,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "cjo41isq": {
      "gp5p6e9": {
        "weight": 120.09670549971672,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gp5p6e9": {
      "bliq7b27": {
        "weight": 159.97789382046085,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "bliq7b27": {
      "57s96g1q": {
        "weight": 197.91050909008703,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "57s96g1q": {
      "7m5y3xkn": {
        "weight": 70.36088297961858,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7m5y3xkn": {
      "ex24ttfe": {
        "weight": 27.786602134242205,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ex24ttfe": {
      "2ygfrl5": {
        "weight": 43.72487164673455,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "krzpq3pu": {
      "34eo5vvd": {
        "weight": 56.68807439470095,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "34eo5vvd": {
      "bup86w22": {
        "weight": 73.64592909571292,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "bup86w22": {
      "e9sn83ol": {
        "weight": 83.41702047529076,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "e9sn83ol": {
      "jbfhthd8": {
        "weight": 90.00202182182005,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jbfhthd8": {
      "b5hphptd": {
        "weight": 107.5910249697867,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "b5hphptd": {
      "4pipxyzq": {
        "weight": 43.52140754781714,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "4pipxyzq": {
      "4wb15q20": {
        "weight": 66.89631316547066,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "4wb15q20": {
      "j34svbtn": {
        "weight": 129.0264169444783,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "h30y202k": {
      "21bf7axp": {
        "weight": 48.88191536174776,
        "type": "SkiTrail",
        "name": "Бела река 2"
      },
      "eu954rlr": {
        "weight": 17.161380129280264,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      },
      "7abz2mdd": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "21bf7axp": {
      "cjuz0pgo": {
        "weight": 41.786454932311926,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "cjuz0pgo": {
      "h3j50msu": {
        "weight": 43.72361495647849,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "h3j50msu": {
      "1449aiak": {
        "weight": 55.02478913568883,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "1449aiak": {
      "58ufvqsl": {
        "weight": 54.90531747118229,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "58ufvqsl": {
      "3t5ym5h7": {
        "weight": 43.68121941435564,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "3t5ym5h7": {
      "eln6k08g": {
        "weight": 25.926720273513205,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "eln6k08g": {
      "5spj4ain": {
        "weight": 64.92304810160482,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "5spj4ain": {
      "gk0or15n": {
        "weight": 35.202733227635804,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "gk0or15n": {
      "6r9voij3": {
        "weight": 30.239884215245144,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "6r9voij3": {
      "affvxi7h": {
        "weight": 39.021827876239264,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "affvxi7h": {
      "2pfr4ta3": {
        "weight": 36.34393097733879,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "2pfr4ta3": {
      "1khb9v3h": {
        "weight": 152.49310199772773,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "1khb9v3h": {
      "giiitu8u": {
        "weight": 91.46548067138431,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "giiitu8u": {
      "3114hmde": {
        "weight": 50.79462204882904,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "3114hmde": {
      "j5m7p19v": {
        "weight": 79.1304946458802,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "j5m7p19v": {
      "43ru024x": {
        "weight": 29.117642334624517,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "43ru024x": {
      "80xtn5a9": {
        "weight": 53.26104911935781,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "80xtn5a9": {
      "k1vgn901": {
        "weight": 43.04206376016449,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "k1vgn901": {
      "dhxj0god": {
        "weight": 44.942797842348206,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "dhxj0god": {
      "3crvf88p": {
        "weight": 34.74897287903241,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "3crvf88p": {
      "aybr4tsm": {
        "weight": 36.77650973523419,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "aybr4tsm": {
      "6o971f1d": {
        "weight": 62.97047912310316,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "6o971f1d": {
      "2fu5e3sy": {
        "weight": 52.62929985420899,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "2fu5e3sy": {
      "117l2rq4": {
        "weight": 52.362734124668265,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "117l2rq4": {
      "80gpahv4": {
        "weight": 37.090773296613115,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "80gpahv4": {
      "u56t4sv": {
        "weight": 37.48630587741233,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "u56t4sv": {
      "jw6xq0l5": {
        "weight": 29.209814278198536,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "jw6xq0l5": {
      "96aaxxw1": {
        "weight": 65.21091316303479,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "96aaxxw1": {
      "jnsky1i3": {
        "weight": 24.6007677031691,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "jnsky1i3": {
      "3s845jx": {
        "weight": 5.7111849912800405,
        "type": "SkiTrail",
        "name": "Бела река 2"
      }
    },
    "3s845jx": {
      "8lezntcj": {
        "weight": 14.766023089444055,
        "type": "SkiTrail",
        "name": "Бела река 2"
      },
      "i0ahxljr": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "8lezntcj": {
      "7kr7l864": {
        "weight": 65.5316121324862,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "73h632je": {
      "ft7q1mc8": {
        "weight": 650.3084231242866,
        "type": "SkiLift",
        "name": "12 Гобеља релеј"
      },
      "a5qx89br": {
        "weight": 19.219228166928747,
        "type": "SkiTrail",
        "difficulty": "2"
      },
      "9rcghnb9": {
        "weight": 12.007619961740915,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "a5qx89br": {
      "69kgedqu": {
        "weight": 24.389301061831564,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "69kgedqu": {
      "jts3vj7u": {
        "weight": 85.39551345555034,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "jts3vj7u": {
      "5mu1epx8": {
        "weight": 94.26394640231614,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "5mu1epx8": {
      "6crfil1": {
        "weight": 60.78085210847858,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "6crfil1": {
      "b2n0nmfq": {
        "weight": 93.01364054508807,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "b2n0nmfq": {
      "8uwltaqu": {
        "weight": 108.70920949724577,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "8uwltaqu": {
      "8wbsl0k5": {
        "weight": 38.3991097059662,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "8wbsl0k5": {
      "b2gx2vgb": {
        "weight": 52.23861319154752,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "b2gx2vgb": {
      "a1qjvlnd": {
        "weight": 32.48171025794389,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "a1qjvlnd": {
      "l48crzsu": {
        "weight": 24.742199556307252,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 2
      }
    },
    "l48crzsu": {
      "8bglz01e": {
        "weight": 26.915611215837224,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "9rcghnb9": {
      "lkksc4q6": {
        "weight": 29.57849359377237,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "lkksc4q6": {
      "9unk2o6p": {
        "weight": 45.441207436445396,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "9unk2o6p": {
      "ik2tpwe0": {
        "weight": 47.909818710952166,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "ik2tpwe0": {
      "ggma6b7j": {
        "weight": 32.31392553148949,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "ggma6b7j": {
      "1jelqwm8": {
        "weight": 27.66425963187113,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "1jelqwm8": {
      "aetdl2nr": {
        "weight": 30.01966208915295,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "aetdl2nr": {
      "28muxpb9": {
        "weight": 16.897770520961643,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "28muxpb9": {
      "4tf5lud9": {
        "weight": 58.264964268883915,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "4tf5lud9": {
      "2mgw6xk5": {
        "weight": 83.2123619093042,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "2mgw6xk5": {
      "jdak3u4i": {
        "weight": 37.25973361585381,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "jdak3u4i": {
      "5g9hd829": {
        "weight": 36.84687943799711,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "5g9hd829": {
      "9rligajj": {
        "weight": 41.2813530338545,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "9rligajj": {
      "8f3t68vu": {
        "weight": 18.260058188382146,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "8f3t68vu": {
      "gusz2p4o": {
        "weight": 19.119289155652414,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "gusz2p4o": {
      "elp1546z": {
        "weight": 15.59083045546886,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "elp1546z": {
      "b07447ev": {
        "weight": 16.018270235780438,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "b07447ev": {
      "75rj9fhn": {
        "weight": 29.632353788722856,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "75rj9fhn": {
      "iy0j85gb": {
        "weight": 69.08840489122491,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "iy0j85gb": {
      "hhe686ig": {
        "weight": 20.944720528696838,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "hhe686ig": {
      "c897bgc0": {
        "weight": 25.240522829983924,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "c897bgc0": {
      "l48crzsu": {
        "weight": 35.67195895028469,
        "type": "SkiTrail",
        "name": "Гобеља релеј",
        "difficulty": 1
      }
    },
    "8bglz01e": {
      "2o2q42e1": {
        "weight": 23.953421310754287,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Гобеља релеј"
      }
    },
    "2o2q42e1": {
      "do25a38z": {
        "weight": 11.267231593959817,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "cms2kz8i": {
      "b6n81qih": {
        "weight": 53.87082843611278,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "b6n81qih": {
      "35nlmj7p": {
        "weight": 44.226430404026246,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "35nlmj7p": {
      "czshx8pk": {
        "weight": 89.72886869864458,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "czshx8pk": {
      "cwdhq5v9": {
        "weight": 135.69220616601328,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "cwdhq5v9": {
      "e4wemq2s": {
        "weight": 85.53415395818585,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "e4wemq2s": {
      "jbkv7n5s": {
        "weight": 46.71069522246533,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "jbkv7n5s": {
      "78sjxfv1": {
        "weight": 41.76633606807755,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "78sjxfv1": {
      "ev5j8fup": {
        "weight": 90.99318876005037,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "ev5j8fup": {
      "3xpsokog": {
        "weight": 31.12670592857213,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "3xpsokog": {
      "dtkjph9m": {
        "weight": 63.535532183839,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "dtkjph9m": {
      "5xq0sbym": {
        "weight": 52.16163043679262,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "5xq0sbym": {
      "i34u9l5": {
        "weight": 36.4512492969829,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "i34u9l5": {
      "1wf5dnsp": {
        "weight": 34.802096747284885,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "1wf5dnsp": {
      "c0jvxd2y": {
        "weight": 55.071409884064956,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "c0jvxd2y": {
      "1hmfsz2o": {
        "weight": 49.663262728761666,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "1hmfsz2o": {
      "l48crzsu": {
        "weight": 17.339361769448416,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гобеља",
        "difficulty": 2
      }
    },
    "do25a38z": {
      "b4i4g27d": {
        "weight": 9.70342463560458,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "b4i4g27d": {
      "69qodj4z": {
        "weight": 21.190934809453616,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "69qodj4z": {
      "d9x03lf7": {
        "weight": 24.104470887334422,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "d9x03lf7": {
      "5sulqzdv": {
        "weight": 23.84367653686741,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "5sulqzdv": {
      "kkuism1p": {
        "weight": 20.996484047155032,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "kkuism1p": {
      "hi7pv5lb": {
        "weight": 21.457558573667413,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "hi7pv5lb": {
      "bcul0e6g": {
        "weight": 43.01239421504795,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "bcul0e6g": {
      "ap1yjg1l": {
        "weight": 29.021513186927393,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "ap1yjg1l": {
      "ic49exm0": {
        "weight": 34.5938501888965,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "ic49exm0": {
      "8udy9evv": {
        "weight": 51.146853517680725,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "8udy9evv": {
      "fldtmpp8": {
        "weight": 39.344035259088095,
        "type": "SkiTrail",
        "name": [
          13
        ],
        "difficulty": 2
      }
    },
    "5aoxjt80": {
      "enp4dorq": {
        "weight": 23.5585979560037,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "13"
      }
    },
    "gbvk14cl": {
      "e9b3r2bg": {
        "weight": 833.9022773082555,
        "type": "SkiLift",
        "name": "13 Гобеља гребен"
      }
    },
    "e9b3r2bg": {
      "1tmniq08": {
        "weight": 22.180278061849418,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "1tmniq08": {
      "1zjyhn8z": {
        "weight": 12.521145302268863,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "i5hr6t2v": {
        "weight": 30.631830321082575,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "1zjyhn8z": {
      "dhsy4u8p": {
        "weight": 9.666783922641315,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "dhsy4u8p": {
      "6yjnsref": {
        "weight": 11.731709870250416,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "6yjnsref": {
      "i1tugba2": {
        "weight": 38.62743028618954,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "i1tugba2": {
      "agvps0tj": {
        "weight": 13.330678688484545,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "7aih0kz": {
        "weight": 28.345734779539978,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "agvps0tj": {
      "20u8xh0p": {
        "weight": 21.46080822262103,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "20u8xh0p": {
      "k7sotkta": {
        "weight": 32.05188336672376,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "k7sotkta": {
      "45bipu1n": {
        "weight": 29.742530318257643,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "45bipu1n": {
      "i21k84qm": {
        "weight": 26.003232178317642,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "gjvomdx0": {
        "weight": 15.154241612035854,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "i21k84qm": {
      "bouetxrk": {
        "weight": 37.43052100061422,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "bouetxrk": {
      "2u2ewqbq": {
        "weight": 67.23840688310985,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "2u2ewqbq": {
      "9ov54skg": {
        "weight": 14.18788854938195,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "eib9yqso": {
        "weight": 40.05596182940238,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "9ov54skg": {
      "9hks78ek": {
        "weight": 89.02828061359138,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "9hks78ek": {
      "kpwcar0x": {
        "weight": 56.72497521128152,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "kpwcar0x": {
      "65pu1es8": {
        "weight": 46.97590931326835,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "65pu1es8": {
      "87uf91r3": {
        "weight": 25.345452384117124,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "a3sno0i": {
        "weight": 22.374529647857038,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "87uf91r3": {
      "jl4o29my": {
        "weight": 33.95189420012739,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "jl4o29my": {
      "j2i2cfo8": {
        "weight": 29.41735691582458,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "j2i2cfo8": {
      "j6bvsvsa": {
        "weight": 19.817294745764467,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "j6bvsvsa": {
      "hau96cac": {
        "weight": 56.03184733852889,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "hau96cac": {
      "d74whrqq": {
        "weight": 26.39019488302323,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "d74whrqq": {
      "77f5hmn": {
        "weight": 25.270888199803046,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "77f5hmn": {
      "3yioagcj": {
        "weight": 27.232850712113756,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "3yioagcj": {
      "dhx0vcy3": {
        "weight": 19.1013555290277,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "dhx0vcy3": {
      "l60z7cmu": {
        "weight": 19.218928780010142,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "l60z7cmu": {
      "b1wm5ar7": {
        "weight": 39.4969577630016,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "b1wm5ar7": {
      "dmjz8hg1": {
        "weight": 56.14602882547421,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "dmjz8hg1": {
      "8p1nf9dz": {
        "weight": 92.87479323753095,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "8p1nf9dz": {
      "161ovlyz": {
        "weight": 53.48360423738227,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "161ovlyz": {
      "k24xudj2": {
        "weight": 58.23229856494428,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "k24xudj2": {
      "1wtk00xz": {
        "weight": 58.28868602875675,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "1wtk00xz": {
      "374ccf9w": {
        "weight": 41.438156385892356,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "374ccf9w": {
      "enp4dorq": {
        "weight": 23.22975084235687,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Гобеља"
      }
    },
    "3blpuu53": {
      "jheks940": {
        "weight": 51.298957455662226,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": "2"
      },
      "b7qtkk01": {
        "weight": 27.292972449716274,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": "2"
      }
    },
    "b7qtkk01": {
      "jheks940": {
        "weight": 31.310668114770206,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      },
      "51osgdfe": {
        "weight": 39.531399599482924,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "jheks940": {
      "aaqnqm0q": {
        "weight": 34.15990736072805,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "aaqnqm0q": {
      "gdlufm6e": {
        "weight": 181.50816070081953,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "gdlufm6e": {
      "3h5eesm0": {
        "weight": 47.292494132954715,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "3h5eesm0": {
      "17mqgbuf": {
        "weight": 154.88268885697283,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "17mqgbuf": {
      "5dksqh3m": {
        "weight": 47.67317969989868,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      },
      "97klopd": {
        "weight": 72.62119801373262,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "5dksqh3m": {
      "d8zwd9aa": {
        "weight": 65.34085605756759,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "d8zwd9aa": {
      "axgynr98": {
        "weight": 69.18405859174904,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "axgynr98": {
      "ji3fmw5t": {
        "weight": 46.453969598901935,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "ji3fmw5t": {
      "1wpinp4v": {
        "weight": 24.27616538182831,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "1wpinp4v": {
      "f53h89e9": {
        "weight": 54.8157513119255,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "f53h89e9": {
      "c8vfy27c": {
        "weight": 52.183631623948195,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "c8vfy27c": {
      "jazs0dgc": {
        "weight": 67.22728640855587,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "jazs0dgc": {
      "iy7d0f2c": {
        "weight": 47.71937300787792,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "iy7d0f2c": {
      "8ezlrmu2": {
        "weight": 88.02323090505725,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "8ezlrmu2": {
      "fc7w1r27": {
        "weight": 70.06945784392236,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "fc7w1r27": {
      "12ib00n8": {
        "weight": 31.21113517083127,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "12ib00n8": {
      "7owvbg4u": {
        "weight": 69.70934203502983,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "7owvbg4u": {
      "13ahd3ge": {
        "weight": 35.072566827092324,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "13ahd3ge": {
      "cpzayvfj": {
        "weight": 26.485800624857884,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "cpzayvfj": {
      "j5kzi59h": {
        "weight": 16.90207202733836,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "j5kzi59h": {
      "5sa0lir2": {
        "weight": 44.280113238003054,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "5sa0lir2": {
      "2rzmhdif": {
        "weight": 57.88082379114323,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "2rzmhdif": {
      "iad5p7ry": {
        "weight": 53.06101765457396,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      },
      "azyhmqmk": {
        "weight": 51.42329418308891,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "iad5p7ry": {
      "iujehuc6": {
        "weight": 83.39232496641951,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "iujehuc6": {
      "8giijbme": {
        "weight": 92.93461371279133,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "8giijbme": {
      "50o6lzwt": {
        "weight": 52.24942587471747,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "50o6lzwt": {
      "cgryav2": {
        "weight": 45.028784072753616,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "cgryav2": {
      "kgtco7xr": {
        "weight": 27.39670775636011,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "kgtco7xr": {
      "dh76yrs0": {
        "weight": 120.84715660369685,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "8p8knzgn": {
      "cdigx6xk": {
        "weight": 54.71760304918656,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "cdigx6xk": {
      "ksi3yvyr": {
        "weight": 42.50882535452103,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "ksi3yvyr": {
      "552i4fof": {
        "weight": 29.76798879832105,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "552i4fof": {
      "j7539th3": {
        "weight": 30.287644944275197,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "j7539th3": {
      "cmt1inuq": {
        "weight": 38.37532217823026,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "cmt1inuq": {
      "dhxt3vw6": {
        "weight": 63.434256481323004,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "dhxt3vw6": {
      "7o2btsv1": {
        "weight": 71.03637794814331,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "7o2btsv1": {
      "ghmzi8du": {
        "weight": 23.69210406136189,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "ghmzi8du": {
      "1j2fx3lg": {
        "weight": 61.405481152546905,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "1j2fx3lg": {
      "dla9hm8x": {
        "weight": 23.53523774732259,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "dla9hm8x": {
      "5xityey6": {
        "weight": 34.8543238759002,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "5xityey6": {
      "7f044yzk": {
        "weight": 22.699341318327175,
        "type": "SkiTrail",
        "name": "Везна стаза Кнежевске баре - Дубока 1"
      }
    },
    "bt7dc9nn": {
      "ib2qvqev": {
        "weight": 34.94940084514963,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "ib2qvqev": {
      "15mjyarc": {
        "weight": 70.08617307314687,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "15mjyarc": {
      "k8cil99t": {
        "weight": 53.9841047169489,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "k8cil99t": {
      "93kckhsr": {
        "weight": 160.81835081529772,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "93kckhsr": {
      "kqowivk1": {
        "weight": 44.38081399354236,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "2uelshpo": {
      "kmqy14oh": {
        "weight": 51.305385438113326,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "kmqy14oh": {
      "laeof1f5": {
        "weight": 55.02352524037107,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "laeof1f5": {
      "liqv0u2g": {
        "weight": 62.4668737955855,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Кнежевске баре",
        "difficulty": 1
      }
    },
    "corohkw5": {
      "l7burrwq": {
        "weight": 19.254775269654754,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "l7burrwq": {
      "7902lzuq": {
        "weight": 17.59241467573648,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7902lzuq": {
      "gdgcpsop": {
        "weight": 16.45399487299309,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gdgcpsop": {
      "3a3bz3rl": {
        "weight": 12.046664126299868,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "3a3bz3rl": {
      "9o1yzo86": {
        "weight": 20.108919224474,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "9o1yzo86": {
      "jdez01co": {
        "weight": 44.8771804002446,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jdez01co": {
      "e6cjztat": {
        "weight": 28.502803371117377,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "e6cjztat": {
      "kpuilnz1": {
        "weight": 44.21913490793388,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7kr7l864": {
      "5uewh7q": {
        "weight": 31.988921276315445,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "5uewh7q": {
      "9j6p4292": {
        "weight": 35.1524502892324,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "9j6p4292": {
      "af6kes82": {
        "weight": 27.595048414066227,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "af6kes82": {
      "6uv8a9ye": {
        "weight": 28.781700816213235,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "6uv8a9ye": {
      "nckbq7e": {
        "weight": 30.457889083653654,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "nckbq7e": {
      "crdy97f9": {
        "weight": 30.781578448408993,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "crdy97f9": {
      "f5dce9i2": {
        "weight": 14.596295420799986,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "f5dce9i2": {
      "1vs8ts21": {
        "weight": 16.965218200557413,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "1vs8ts21": {
      "1h7dneg2": {
        "weight": 38.64065154592437,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "1h7dneg2": {
      "4gug9bkh": {
        "weight": 27.91015855324378,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "4gug9bkh": {
      "joriyaps": {
        "weight": 29.126300665509692,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "joriyaps": {
      "5rluwdww": {
        "weight": 40.2404409891162,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "5rluwdww": {
      "ag3gonqk": {
        "weight": 29.75192644182472,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "ag3gonqk": {
      "91qorsbb": {
        "weight": 21.288300564505136,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "91qorsbb": {
      "bl52d2bo": {
        "weight": 18.203484136659288,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "bl52d2bo": {
      "3m9ud7al": {
        "weight": 30.159487524224144,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "3m9ud7al": {
      "dkbqaykv": {
        "weight": 29.956156416291126,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "dkbqaykv": {
      "jsp0hc9s": {
        "weight": 63.928331115951586,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "jsp0hc9s": {
      "b9fu8hil": {
        "weight": 40.53147480845703,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "b9fu8hil": {
      "7p4cc1xi": {
        "weight": 24.926409007259107,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "7p4cc1xi": {
      "52pmqmm0": {
        "weight": 47.11527741480444,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "52pmqmm0": {
      "g9p9fs3r": {
        "weight": 70.5645528272382,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "g9p9fs3r": {
      "2u7o47sq": {
        "weight": 45.885537530925234,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "2u7o47sq": {
      "9txeax49": {
        "weight": 37.973190889994726,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "9txeax49": {
      "o3fmkms": {
        "weight": 48.97653618864272,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "o3fmkms": {
      "1n82xiia": {
        "weight": 34.293440106241874,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "1n82xiia": {
      "b95g3ipe": {
        "weight": 32.49670094577706,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "b95g3ipe": {
      "csaf9spc": {
        "weight": 33.16005300053963,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "csaf9spc": {
      "5bjnvf5d": {
        "weight": 51.289081646121915,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "5bjnvf5d": {
      "by821chu": {
        "weight": 40.24542049888678,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "by821chu": {
      "nsq8awo": {
        "weight": 48.63617370182742,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "nsq8awo": {
      "7r9qe95j": {
        "weight": 41.17614701459931,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "7r9qe95j": {
      "ga3vxrmo": {
        "weight": 41.58315717847418,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "ga3vxrmo": {
      "5ocsi2wk": {
        "weight": 123.21772860763285,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "5ocsi2wk": {
      "kkwq1ctr": {
        "weight": 59.76907870414771,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "kkwq1ctr": {
      "dw1yztm6": {
        "weight": 32.82389774597836,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "dw1yztm6": {
      "h1slerck": {
        "weight": 98.65014594324414,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "h1slerck": {
      "c26qmk1t": {
        "weight": 85.23372310458072,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "c26qmk1t": {
      "2rlsaqsj": {
        "weight": 11.624364679746206,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "2rlsaqsj": {
      "5sibsyd6": {
        "weight": 14.62081275049006,
        "type": "SkiTrail",
        "name": "Бела река 1",
        "difficulty": 1
      }
    },
    "5sibsyd6": {
      "8x9x4yuu": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "l5c7smvt": {
      "hcloda6k": {
        "weight": 14.850001708123488,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "hcloda6k": {
      "1sny2ngo": {
        "weight": 23.63342365173039,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "1sny2ngo": {
      "2tqedtjw": {
        "weight": 35.913904909897056,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "2tqedtjw": {
      "f3kikh1p": {
        "weight": 35.45170439617738,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "f3kikh1p": {
      "e43f3iui": {
        "weight": 31.65727683665688,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "e43f3iui": {
      "hzo5tb8m": {
        "weight": 63.52471391387887,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "hzo5tb8m": {
      "gm10vge4": {
        "weight": 30.374957685420156,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "gm10vge4": {
      "4i0s7la8": {
        "weight": 22.008523626112932,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "4i0s7la8": {
      "8ihnk12r": {
        "weight": 38.87763914703708,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "8ihnk12r": {
      "foeajxo": {
        "weight": 51.76086749040562,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "foeajxo": {
      "28v6f55n": {
        "weight": 35.523761674009755,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "28v6f55n": {
      "9datk41k": {
        "weight": 15.29248008469792,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      },
      "fbmma5qb": {
        "weight": 53.455360101003876,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "9datk41k": {
      "9qkus7rs": {
        "weight": 39.423604865714864,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "9qkus7rs": {
      "4lfedt1u": {
        "weight": 37.774938760165284,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "4lfedt1u": {
      "ljzkzmcm": {
        "weight": 73.50407035077018,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "ljzkzmcm": {
      "7ceydulw": {
        "weight": 23.42479472870327,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      },
      "k0i6jrp7": {
        "weight": 51.6286553348681,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "7ceydulw": {
      "579cwd0y": {
        "weight": 32.514732976928656,
        "type": "SkiTrail",
        "name": "Крчмарски пут",
        "difficulty": 2
      }
    },
    "7aih0kz": {
      "6jxf1dio": {
        "weight": 28.85027768589512,
        "type": "SkiTrail",
        "name": "Гобеља"
      },
      "8vqmtqov": {
        "weight": 40.334235487508025,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "6jxf1dio": {
      "jblzp6hn": {
        "weight": 48.49781415389672,
        "type": "SkiTrail",
        "name": "Гобеља"
      },
      "1p07f0ri": {
        "weight": 43.514087937964774,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "jblzp6hn": {
      "es6ieu9": {
        "weight": 21.35107830070166,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "es6ieu9": {
      "hj4eqnsh": {
        "weight": 21.663914298454003,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "hj4eqnsh": {
      "b2y0au41": {
        "weight": 37.714121423645494,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "b2y0au41": {
      "fay3bxc5": {
        "weight": 57.82178060768195,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "fay3bxc5": {
      "6ej25z6n": {
        "weight": 93.75626280554481,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "6ej25z6n": {
      "jsqic5qd": {
        "weight": 86.16372112074816,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "jsqic5qd": {
      "7677ft6z": {
        "weight": 62.301720146972265,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "7677ft6z": {
      "1cu6v441": {
        "weight": 71.06945839013974,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "1cu6v441": {
      "jhxfwltz": {
        "weight": 41.29169009484368,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "jhxfwltz": {
      "ap1yjg1l": {
        "weight": 48.96903947135496,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "eib9yqso": {
      "f2ngtvf": {
        "weight": 64.78404019647135,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "f2ngtvf": {
      "dsneyk9y": {
        "weight": 82.83306901330167,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "dsneyk9y": {
      "6r8oex1r": {
        "weight": 106.9635038887627,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "6r8oex1r": {
      "alwvdu3l": {
        "weight": 28.075862029772967,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "alwvdu3l": {
      "lfdluvb1": {
        "weight": 31.202225451430586,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "lfdluvb1": {
      "3eilv361": {
        "weight": 72.114380911097,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "3eilv361": {
      "3xyncn46": {
        "weight": 122.62766014480867,
        "type": "SkiTrail",
        "name": "Гобеља"
      },
      "gdllk9vj": {
        "weight": 80.78246508645893,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "3xyncn46": {
      "afz5h66v": {
        "weight": 42.94452175475336,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "afz5h66v": {
      "374ccf9w": {
        "weight": 34.61941247913089,
        "type": "SkiTrail",
        "name": "Гобеља"
      }
    },
    "6ysdrnuy": {
      "1qxsa8oi": {
        "weight": 12.73858311972572,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "q1k21em": {
      "gttuv59p": {
        "weight": 44.56046414706184,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "gttuv59p": {
      "4dg36zyo": {
        "weight": 23.62991366230668,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "4dg36zyo": {
      "dnv9j69b": {
        "weight": 26.686434318052633,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "dnv9j69b": {
      "41gkrenj": {
        "weight": 22.187944664702442,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "41gkrenj": {
      "aupi9mvn": {
        "weight": 10.774578745522174,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "3ed74ycv": {
      "3xxn8wn3": {
        "weight": 41.464290989263986,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "3xxn8wn3": {
      "79lq2fw9": {
        "weight": 41.18839145918417,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "79lq2fw9": {
      "da5irfwf": {
        "weight": 21.691838995243245,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "da5irfwf": {
      "533yvq3o": {
        "weight": 20.385418135032637,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "533yvq3o": {
      "609k63v9": {
        "weight": 30.78550976622488,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "609k63v9": {
      "kl8mrvsb": {
        "weight": 41.42726166560542,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "kl8mrvsb": {
      "hy7p9dly": {
        "weight": 60.09104399349644,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "hy7p9dly": {
      "hft4zfu5": {
        "weight": 36.65584298888985,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "hft4zfu5": {
      "82vsfcdg": {
        "weight": 14.529597817267776,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "82vsfcdg": {
      "4hlmbrtx": {
        "weight": 96.20130068626675,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "4hlmbrtx": {
      "cmt8crtp": {
        "weight": 46.5022582479211,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "cmt8crtp": {
      "e3bot2gu": {
        "weight": 40.11842459690162,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "e3bot2gu": {
      "lkp1a6ad": {
        "weight": 75.37639424626911,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "lkp1a6ad": {
      "jyrurd3x": {
        "weight": 64.25523643621585,
        "type": "SkiTrail",
        "name": "Марине воде",
        "difficulty": 1
      }
    },
    "jyrurd3x": {
      "jd76578v": {
        "weight": 21.499315304791274,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "i5hr6t2v": {
      "3cx00roz": {
        "weight": 16.53590155654423,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "3cx00roz": {
      "fxevz5mf": {
        "weight": 31.74385138680702,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "bgmlrrz1": {
        "weight": 16.478926265814398,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "bgmlrrz1": {
      "6s24pcky": {
        "weight": 15.333804667605078,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "3cx00roz": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "6s24pcky": {
      "cbmfc5tk": {
        "weight": 14.099665241961564,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "bgmlrrz1": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "cbmfc5tk": {
      "khj6mkur": {
        "weight": 15.459601963254459,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "khj6mkur": {
      "i1jdivc9": {
        "weight": 25.6534885000902,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "i1jdivc9": {
      "bhk4wi4o": {
        "weight": 21.18179372131333,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "bhk4wi4o": {
      "4skd5gjx": {
        "weight": 19.393621974331126,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "1gh7u66f": {
        "weight": 71.3894539580991,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "4skd5gjx": {
      "gcfw596b": {
        "weight": 13.863809833159605,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gcfw596b": {
      "6yfzn0dl": {
        "weight": 25.559768637614074,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "6yfzn0dl": {
      "5rolwty6": {
        "weight": 25.390403645208764,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "5ocoe8h": {
      "bb0d3nee": {
        "weight": 32.48512085740035,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "1nvh0hi3": {
        "weight": 27.983356065279942,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "bb0d3nee": {
      "3uln3nmf": {
        "weight": 25.818606256886994,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "3uln3nmf": {
      "9mtbnzgo": {
        "weight": 32.594409728688454,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "9mtbnzgo": {
      "bkkzz04w": {
        "weight": 28.04106398619091,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "bkkzz04w": {
      "b2h1wcyw": {
        "weight": 87.42665101601057,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "b2h1wcyw": {
      "9jjz6qlo": {
        "weight": 65.36843166163898,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "9jjz6qlo": {
      "hw97pzt9": {
        "weight": 53.07814360975282,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "hw97pzt9": {
      "5ne5vk6r": {
        "weight": 56.52882513289318,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "5ne5vk6r": {
      "gpf0d2zb": {
        "weight": 148.9339120083691,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "dabqvt0g": {
        "weight": 53.02855554472397,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "gpf0d2zb": {
      "4okgfs6x": {
        "weight": 30.308186410310324,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "4okgfs6x": {
      "eaery6vk": {
        "weight": 42.00939464541087,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "eaery6vk": {
      "k1x68vvb": {
        "weight": 122.40447545859259,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "k1x68vvb": {
      "5wkob4my": {
        "weight": 48.31457687579329,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "a3sno0i": {
      "3j7ea93t": {
        "weight": 20.317294164591928,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "3j7ea93t": {
      "avpuktvg": {
        "weight": 19.706936562197935,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "avpuktvg": {
      "28crlqj": {
        "weight": 35.09811825310572,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "28crlqj": {
      "gqnbwb35": {
        "weight": 41.797721494535075,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "gqnbwb35": {
      "11yeypau": {
        "weight": 42.461892917202036,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "11yeypau": {
      "f0zii7rb": {
        "weight": 70.58580787321712,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "f0zii7rb": {
      "9gkoyu69": {
        "weight": 49.83789360815721,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "9gkoyu69": {
      "8lu4zrpo": {
        "weight": 27.53090979389485,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "8lu4zrpo": {
      "3eilv361": {
        "weight": 63.499470982962464,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "gdllk9vj": {
      "5gxsx66a": {
        "weight": 23.376591158428678,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "5gxsx66a": {
      "gpy4evov": {
        "weight": 31.090779653987646,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "gpy4evov": {
      "fmjismuo": {
        "weight": 12.195646782666964,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "fmjismuo": {
      "2sh2pyc3": {
        "weight": 12.53629314078067,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "2sh2pyc3": {
      "fldtmpp8": {
        "weight": 23.501208197415203,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "gg486568": {
      "azrits2m": {
        "weight": 16.41056290329353,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      },
      "b4lg41q4": {
        "weight": 16.231818250628866,
        "type": "SkiTrail",
        "name": "Везна стаза Крст - Панчићев врх",
        "difficulty": 2
      }
    },
    "azrits2m": {
      "ghn0te1m": {
        "weight": 26.80884069284797,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Крст"
      },
      "gg486568": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "9ap85u7k": {
      "22iee70u": {
        "weight": 17.19080449079235,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "22iee70u": {
      "7uuvz9w7": {
        "weight": 18.86557182882904,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "7uuvz9w7": {
      "gl4nq0vf": {
        "weight": 22.84992004991769,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "gl4nq0vf": {
      "5yaiei4a": {
        "weight": 24.50864670459927,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "5yaiei4a": {
      "8cvr282u": {
        "weight": 8.164582722685509,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      },
      "ixxk5xon": {
        "weight": 24.382252189001512,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "8cvr282u": {
      "8n3scb28": {
        "weight": 35.60785843672447,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "8n3scb28": {
      "lb0zh1cy": {
        "weight": 51.87987385034357,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "lb0zh1cy": {
      "8p2iqhkw": {
        "weight": 27.78346782690325,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "8p2iqhkw": {
      "akk6fb5r": {
        "weight": 31.264755988989616,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "akk6fb5r": {
      "31qb5uxs": {
        "weight": 52.04689815108491,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "31qb5uxs": {
      "9dk1hrka": {
        "weight": 38.93082624142793,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "9dk1hrka": {
      "g4oujoev": {
        "weight": 39.28989596171475,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "g4oujoev": {
      "jb6hsme9": {
        "weight": 41.40054352838341,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "jb6hsme9": {
      "hlb21etv": {
        "weight": 47.5247454292973,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Крст"
      }
    },
    "hlb21etv": {
      "idqovtzk": {
        "weight": 34.71251523614353,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "idqovtzk": {
      "axed4pk1": {
        "weight": 52.534348216839035,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "axed4pk1": {
      "6w27k8z4": {
        "weight": 35.4760379578299,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "6w27k8z4": {
      "8bm04vmk": {
        "weight": 21.881932968876843,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "8bm04vmk": {
      "ez06xdpa": {
        "weight": 19.32351067508108,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "xyxc8o2": {
      "5tzo1zlh": {
        "weight": 31.556109328601007,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "5tzo1zlh": {
      "g1acrjf6": {
        "weight": 40.1739176605541,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "gw4ctf5e": {
      "fgaoze61": {
        "weight": 52.378205196576594,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "fgaoze61": {
      "edrw0n56": {
        "weight": 59.84781870383561,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "7690bomv": {
      "5zidvttn": {
        "weight": 42.3395842702457,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      },
      "74kek3zl": {
        "weight": 41.39402928096862,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "5zidvttn": {
      "5djdi751": {
        "weight": 31.735613372577692,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "5djdi751": {
      "3r17bj6j": {
        "weight": 25.24593256159474,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "3r17bj6j": {
      "he6y2773": {
        "weight": 33.18428337332271,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "he6y2773": {
      "5jdhlzv0": {
        "weight": 38.68321042241374,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "5jdhlzv0": {
      "cpemj2gv": {
        "weight": 61.38751989938546,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "cpemj2gv": {
      "hlb21etv": {
        "weight": 11.310918202834321,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "ixxk5xon": {
      "9fihhtnz": {
        "weight": 24.22146713119789,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "9fihhtnz": {
      "hi7ubu1i": {
        "weight": 37.931931298604844,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "hi7ubu1i": {
      "fdk7ser7": {
        "weight": 22.38505940724146,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      },
      "a2ou6ahx": {
        "weight": 8.547273288581438,
        "type": "SkiTrail",
        "name": null
      }
    },
    "fdk7ser7": {
      "a7fs71hk": {
        "weight": 43.575279675444214,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "a7fs71hk": {
      "8c3ogwyy": {
        "weight": 27.126817225743256,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "8c3ogwyy": {
      "dzv985rb": {
        "weight": 25.41940415693168,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "dzv985rb": {
      "jtrkmlfc": {
        "weight": 38.8049685355786,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "jtrkmlfc": {
      "a2ridem6": {
        "weight": 32.94185637272001,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "a2ridem6": {
      "7690bomv": {
        "weight": 5.045529684367078,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "74kek3zl": {
      "ineq3sle": {
        "weight": 89.88959664475092,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "ineq3sle": {
      "eyvv6ikp": {
        "weight": 31.03455311912718,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "eyvv6ikp": {
      "5g0cvuza": {
        "weight": 67.10554334969605,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "5g0cvuza": {
      "fgaoze61": {
        "weight": 46.08431317031608,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "edrw0n56": {
      "3ln2nlgt": {
        "weight": 32.71732257270374,
        "type": "SkiTrail",
        "name": "Крст",
        "difficulty": 1
      }
    },
    "3rzssonl": {
      "kg615n2": {
        "weight": 145.73630434249628,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "g211i42i": {
        "weight": 69.37463159095823,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "kg615n2": {
      "6rh89srm": {
        "weight": 18.75651382385118,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "6rh89srm": {
      "6jn59bjo": {
        "weight": 42.07431590168675,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "jv1cwiz1": {
        "weight": 17.14726134256369,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "6jn59bjo": {
      "bn4315ra": {
        "weight": 83.49999800942669,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "bn4315ra": {
      "6irtg3r5": {
        "weight": 39.077504666841925,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "6irtg3r5": {
      "hyx0ok8e": {
        "weight": 41.36950789418582,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "hyx0ok8e": {
      "49myjtxi": {
        "weight": 27.648513452453322,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "49myjtxi": {
      "kjup23m": {
        "weight": 24.045567021384542,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "kjup23m": {
      "kwmgfq6h": {
        "weight": 65.05863717110692,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "cmt1d5in": {
        "weight": 57.12748839475721,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "4ik6ch3v": {
      "jp06hv5d": {
        "weight": 27.917543320155772,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      },
      "15umcuzm": {
        "weight": 32.93334847275236,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "jp06hv5d": {
      "579cwd0y": {
        "weight": 27.98842631435794,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "dvpr3vp1": {
      "iz2qoz4a": {
        "weight": 17.48983655700474,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "iz2qoz4a": {
      "z1bm0ml": {
        "weight": 75.14692397466374,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "z1bm0ml": {
      "hkxdham3": {
        "weight": 29.895503029986884,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1gh7u66f": {
      "9mny8ap1": {
        "weight": 26.223639987496973,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "9mny8ap1": {
      "ldupjuw6": {
        "weight": 38.51965053395371,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      },
      "egreq0hv": {
        "weight": 34.25494080516138,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "ldupjuw6": {
      "cqeoa1gw": {
        "weight": 47.57141141206608,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "cqeoa1gw": {
      "39cltbg": {
        "weight": 38.88525569963681,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "39cltbg": {
      "j9udbnfi": {
        "weight": 34.64946973895494,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "j9udbnfi": {
      "8ni84fu5": {
        "weight": 106.51970789885252,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "8ni84fu5": {
      "k5dk3n7c": {
        "weight": 39.99786482139916,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "k5dk3n7c": {
      "aqd6tj96": {
        "weight": 47.86578071364461,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "aqd6tj96": {
      "ftm3sy1o": {
        "weight": 82.6914641174776,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "ftm3sy1o": {
      "6uyvuise": {
        "weight": 53.12037818241593,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "6uyvuise": {
      "eihqrdj8": {
        "weight": 71.71384136892298,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "eihqrdj8": {
      "enpriihr": {
        "weight": 68.7559462607871,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "2hrrfu7w": {
      "4dndyt7y": {
        "weight": 89.02787128075978,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      },
      "k9b4x9cq": {
        "weight": 50.023928497766356,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "4dndyt7y": {
      "63dogroh": {
        "weight": 58.70363675777964,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "63dogroh": {
      "4kkwo0t9": {
        "weight": 51.453242171575624,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "4kkwo0t9": {
      "axmxf21b": {
        "weight": 38.18546129148493,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "axmxf21b": {
      "7adigb65": {
        "weight": 67.7133916594735,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "7adigb65": {
      "1gphltns": {
        "weight": 54.29754607166727,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "1gphltns": {
      "le5b13iz": {
        "weight": 53.48388819606116,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "le5b13iz": {
      "6ss5eu51": {
        "weight": 18.837273615594764,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "6ss5eu51": {
      "ecv75l5o": {
        "weight": 48.074443312520465,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "ecv75l5o": {
      "k26k9h8w": {
        "weight": 43.951118662641555,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "k26k9h8w": {
      "jiwixkfu": {
        "weight": 49.69904300410039,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "jiwixkfu": {
      "3bnuo0ys": {
        "weight": 50.329381563577506,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "3bnuo0ys": {
      "3q9xa281": {
        "weight": 32.56974058230763,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "3q9xa281": {
      "85lqv9jx": {
        "weight": 33.16619549177934,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "k5vf081p": {
      "8dx3701p": {
        "weight": 83.06207462894285,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "8dx3701p": {
      "in8j3mqv": {
        "weight": 81.69351371951785,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "in8j3mqv": {
      "2hrrfu7w": {
        "weight": 33.605950667938956,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "k9b4x9cq": {
      "eb31gqqy": {
        "weight": 116.24257353738737,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "eb31gqqy": {
      "idgfjk5y": {
        "weight": 39.9965005971697,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "idgfjk5y": {
      "7hyw3vsj": {
        "weight": 75.43154640316874,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "7hyw3vsj": {
      "agq0ghgk": {
        "weight": 40.37266911569494,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "agq0ghgk": {
      "4z6prbtj": {
        "weight": 38.75747395721584,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "4z6prbtj": {
      "bd9d6nm6": {
        "weight": 55.66509540949852,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "bd9d6nm6": {
      "dz7tomq1": {
        "weight": 55.80700329512143,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "dz7tomq1": {
      "hdjo12ky": {
        "weight": 38.07037190530715,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "hdjo12ky": {
      "ap0jiy5p": {
        "weight": 43.72713756311686,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "ap0jiy5p": {
      "3r7ans2w": {
        "weight": 52.25049710960402,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "3r7ans2w": {
      "5glem6bq": {
        "weight": 52.45020421313163,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "5glem6bq": {
      "l6ywjvk1": {
        "weight": 33.71492302168455,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "l6ywjvk1": {
      "3q9xa281": {
        "weight": 32.623434657722704,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "85lqv9jx": {
      "hudf1p98": {
        "weight": 33.49718696799678,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "hudf1p98": {
      "kb1dgab4": {
        "weight": 26.02102130966587,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "kxi24qoz": {
      "csx8lpzt": {
        "weight": 33.670597653226544,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "b4lg41q4": {
      "60r8wiqg": {
        "weight": 34.6792943820891,
        "type": "SkiTrail",
        "name": "Везна стаза Крст - Панчићев врх",
        "difficulty": 2
      },
      "gg486568": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "60r8wiqg": {
      "3p30qk4o": {
        "weight": 48.06626540158222,
        "type": "SkiTrail",
        "name": "Везна стаза Крст - Панчићев врх",
        "difficulty": 2
      },
      "b4lg41q4": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "5461f3r1": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "3p30qk4o": {
      "136xotjx": {
        "weight": 28.812149327986823,
        "type": "SkiTrail",
        "name": "Везна стаза Крст - Панчићев врх",
        "difficulty": 2
      }
    },
    "136xotjx": {
      "llk13st7": {
        "weight": 26.46580212582734,
        "type": "SkiTrail",
        "name": "Везна стаза Крст - Панчићев врх",
        "difficulty": 2
      }
    },
    "llk13st7": {
      "bysh2ubi": {
        "weight": 29.25002886318336,
        "type": "SkiTrail",
        "name": "Везна стаза Крст - Панчићев врх",
        "difficulty": 2
      }
    },
    "f07743kg": {
      "2o8ckc3j": {
        "weight": 42.52247356258538,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Караман гребен"
      },
      "2ygfrl5": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "2o8ckc3j": {
      "duo0mcy4": {
        "weight": 37.08174179899261,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "duo0mcy4": {
      "3rzssonl": {
        "weight": 22.095515178208935,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "g211i42i": {
      "crexflq8": {
        "weight": 126.37121860166806,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "crexflq8": {
      "1xxk1pps": {
        "weight": 34.3372499057935,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "1xxk1pps": {
      "g1yj3wun": {
        "weight": 47.19654493467135,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "g1yj3wun": {
      "dw34cgr0": {
        "weight": 31.820999193430428,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "dw34cgr0": {
      "g9i8a42c": {
        "weight": 43.98260284551875,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "g9i8a42c": {
      "ld3rusit": {
        "weight": 31.43174127046703,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "ld3rusit": {
      "kjup23m": {
        "weight": 20.57852027201858,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "kwmgfq6h": {
      "95eme1rw": {
        "weight": 36.15170514219359,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "95eme1rw": {
      "bzaammeg": {
        "weight": 83.00035777678941,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "bzaammeg": {
      "6ummpusf": {
        "weight": 43.28494236272039,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "6ummpusf": {
      "jm7i5he8": {
        "weight": 25.050773307430884,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "jm7i5he8": {
      "g2o8uwy4": {
        "weight": 19.755775293162312,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "fxevz5mf": {
      "kxzn0tae": {
        "weight": 19.16446243998321,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "3cx00roz": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "kxzn0tae": {
      "kzm4fob0": {
        "weight": 61.84868043885007,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "kzm4fob0": {
      "ggywmby2": {
        "weight": 54.441743856529236,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "ggywmby2": {
      "63gfgb7v": {
        "weight": 72.83688645054349,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "63gfgb7v": {
      "hdw2re3w": {
        "weight": 17.106462672854253,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "hdw2re3w": {
      "5p05fe83": {
        "weight": 58.47515048354577,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "5p05fe83": {
      "7cat9w1p": {
        "weight": 89.75046028307995,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "7cat9w1p": {
      "g3cj22a8": {
        "weight": 136.07360117083272,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "g3cj22a8": {
      "9m58ntuc": {
        "weight": 77.72543452038977,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      },
      "dbdj7p0u": {
        "weight": 71.38571020491594,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "9m58ntuc": {
      "d9qkstcf": {
        "weight": 24.519312311173397,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "d9qkstcf": {
      "e6495glh": {
        "weight": 40.23173741194759,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "e6495glh": {
      "l01dlujj": {
        "weight": 36.55314457795704,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "l01dlujj": {
      "hi7pv5lb": {
        "weight": 35.654258700132026,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "8vqmtqov": {
      "kiozw15i": {
        "weight": 28.779737023001083,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "kiozw15i": {
      "5krszm0l": {
        "weight": 31.648434850415725,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "5krszm0l": {
      "gzbxtu3b": {
        "weight": 27.464886795610795,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "gzbxtu3b": {
      "4h334y13": {
        "weight": 27.855914033432494,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "4h334y13": {
      "63gfgb7v": {
        "weight": 21.239985247843883,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "fs7grt5y": {
      "50hvdc9z": {
        "weight": 29.67949091902006,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "50hvdc9z": {
      "gwdofor3": {
        "weight": 38.42015688851501,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "gwdofor3": {
      "ene9xd1v": {
        "weight": 52.77260382706973,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "ene9xd1v": {
      "c2hwy52d": {
        "weight": 39.670901648531384,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "c2hwy52d": {
      "h32hj4lv": {
        "weight": 25.190468364371505,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "h32hj4lv": {
      "2spexg50": {
        "weight": 11.503395686377832,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "2spexg50": {
      "i1phz8wp": {
        "weight": 18.768829886571474,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Мали Караман",
        "difficulty": 1
      }
    },
    "i1phz8wp": {
      "cmwsz7tr": {
        "weight": 57.67289274641794,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "gcwdnaks": {
      "8p238m6e": {
        "weight": 95.94760942214256,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "8p238m6e": {
      "jq0gqlnk": {
        "weight": 52.33861275403602,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "jq0gqlnk": {
      "56222ilw": {
        "weight": 17.94756578096113,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "56222ilw": {
      "j1cfwu96": {
        "weight": 13.587058722693081,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "j1cfwu96": {
      "fb6xsjhj": {
        "weight": 13.93398980231406,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "fb6xsjhj": {
      "3apxvd8p": {
        "weight": 12.686966142777601,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "3apxvd8p": {
      "18oszkh6": {
        "weight": 13.09449560613045,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "18oszkh6": {
      "eai4rt48": {
        "weight": 22.128592315717057,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "eai4rt48": {
      "j97hhldo": {
        "weight": 17.28111019988781,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "j97hhldo": {
      "isaxvq6l": {
        "weight": 15.568172555223773,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "isaxvq6l": {
      "9p0k50jv": {
        "weight": 15.384593510303569,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "9p0k50jv": {
      "em9982vr": {
        "weight": 9.800634325767605,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "em9982vr": {
      "bhge753a": {
        "weight": 10.030916348122705,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "bhge753a": {
      "4fxch5gn": {
        "weight": 22.707140617595375,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "4fxch5gn": {
      "e8gaewmt": {
        "weight": 26.82116953015766,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "e8gaewmt": {
      "hpdf3vkb": {
        "weight": 16.34885604757699,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "hpdf3vkb": {
      "j6idntt4": {
        "weight": 19.600791506506273,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "j6idntt4": {
      "6qzyj9lx": {
        "weight": 20.58116447019692,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "6qzyj9lx": {
      "1i9pqwcc": {
        "weight": 16.892010900502747,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "1i9pqwcc": {
      "jogrkj7d": {
        "weight": 15.310148358559118,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "jogrkj7d": {
      "kzc6n28r": {
        "weight": 14.390668176799803,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "kzc6n28r": {
      "jfsjojhq": {
        "weight": 31.875377557482736,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "jfsjojhq": {
      "fnknjcqh": {
        "weight": 21.48392967805397,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "fnknjcqh": {
      "8v5ygpvz": {
        "weight": 38.97547227195814,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "8v5ygpvz": {
      "dis6fftm": {
        "weight": 19.87530727385756,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "dis6fftm": {
      "7se7mspv": {
        "weight": 15.181714937751453,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "7se7mspv": {
      "aywewbjb": {
        "weight": 17.923319091193346,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "aywewbjb": {
      "hegpnhar": {
        "weight": 67.30731908900043,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "hegpnhar": {
      "7w8pu4s8": {
        "weight": 21.317615820326413,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "7w8pu4s8": {
      "azethp1d": {
        "weight": 16.70467959176092,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "azethp1d": {
      "b0wul0hj": {
        "weight": 16.8052000048447,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "b0wul0hj": {
      "1zds3ln8": {
        "weight": 16.209716247887666,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "1zds3ln8": {
      "e5o0zhxp": {
        "weight": 14.03546330698639,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "e5o0zhxp": {
      "b6ob4mov": {
        "weight": 16.549518671702078,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "b6ob4mov": {
      "5llur0k8": {
        "weight": 9.222734232878743,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "5llur0k8": {
      "bxjvgphc": {
        "weight": 20.779743496711234,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "bxjvgphc": {
      "bomz1c7i": {
        "weight": 37.590809103671475,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "hxkxr56h": {
      "3jg3kkf": {
        "weight": 35.67952532936572,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      },
      "habk8k3c": {
        "weight": 21.805938099549365,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "3jg3kkf": {
      "7bcxdcfp": {
        "weight": 35.44886411870934,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "7bcxdcfp": {
      "1xl93j1o": {
        "weight": 120.15028045049718,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "1xl93j1o": {
      "isrlki1d": {
        "weight": 121.96427638697591,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "isrlki1d": {
      "hmqnhmc1": {
        "weight": 87.63562897519773,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "hmqnhmc1": {
      "7q6c58vx": {
        "weight": 111.34711503106988,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "h86b6ez": {
      "efnbm29": {
        "weight": 71.68944541054962,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "efnbm29": {
      "ft7adlzf": {
        "weight": 43.41452136368168,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "ft7adlzf": {
      "iwr5zmha": {
        "weight": 59.855868564376536,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "iwr5zmha": {
      "btgsp8n": {
        "weight": 39.25937025315629,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "btgsp8n": {
      "o1vf3fy": {
        "weight": 36.18909384141392,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "o1vf3fy": {
      "jgbr82en": {
        "weight": 77.65895051865854,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "jgbr82en": {
      "cngwk0p3": {
        "weight": 40.360650407819925,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "cngwk0p3": {
      "hve6ec83": {
        "weight": 38.20413877705481,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "hve6ec83": {
      "l5q1uzho": {
        "weight": 71.37515713239002,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "l5q1uzho": {
      "8q6kon5l": {
        "weight": 28.615097163767462,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Треска"
      }
    },
    "habk8k3c": {
      "f99rzyr4": {
        "weight": 12.26673496351292,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "f99rzyr4": {
      "x66xhw5": {
        "weight": 46.631848827684614,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "igckwk80": {
        "weight": 58.77164365287907,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      },
      "1bc4lw38": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "habk8k3c": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "x66xhw5": {
      "ao0p7op2": {
        "weight": 26.93054841018057,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ao0p7op2": {
      "b31ozod0": {
        "weight": 15.297063019749352,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "b31ozod0": {
      "8t4r3ty7": {
        "weight": 15.58321487695111,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8t4r3ty7": {
      "jvfb4qy": {
        "weight": 21.343884274622916,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jvfb4qy": {
      "3tpg707p": {
        "weight": 19.474468135175535,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "3tpg707p": {
      "hgrbx0nh": {
        "weight": 39.75807340615054,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "iypxt9c7": {
      "gxcnu4jc": {
        "weight": 83.24593509751418,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gxcnu4jc": {
      "1zl9x3ap": {
        "weight": 62.65503804851355,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1zl9x3ap": {
      "8cmo6s4e": {
        "weight": 76.52990070151267,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ju1ejb6a": {
      "7bcxdcfp": {
        "weight": 43.25374975628324,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "df6zaiaq": {
      "bp2nltu2": {
        "weight": 28.935945643107303,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      },
      "9w8f4x3b": {
        "weight": 13.452214948579709,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "bp2nltu2": {
      "cgbjykf7": {
        "weight": 74.52747061146853,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "cgbjykf7": {
      "inub27i8": {
        "weight": 11.107718232627393,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "inub27i8": {
      "8ln1kklk": {
        "weight": 118.14306565216579,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      },
      "7zigiy2o": {
        "weight": 87.22067493410084,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "8ln1kklk": {
      "ebmtg69t": {
        "weight": 51.14187734150769,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      },
      "2ipssokt": {
        "weight": 78.02007109121377,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "ebmtg69t": {
      "jkxz7941": {
        "weight": 127.59312025061692,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "jkxz7941": {
      "3aqymzd9": {
        "weight": 98.13405868558024,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "3aqymzd9": {
      "hkvuh7fk": {
        "weight": 72.34780258146769,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "hkvuh7fk": {
      "daxbmmpx": {
        "weight": 50.4564896659833,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "daxbmmpx": {
      "e1s8zjsm": {
        "weight": 38.80551386793232,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "e1s8zjsm": {
      "6i0y17kx": {
        "weight": 50.924663237430984,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "6i0y17kx": {
      "jx645nkd": {
        "weight": 58.174760936216046,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "jx645nkd": {
      "3hp761b": {
        "weight": 52.50546541681044,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "3hp761b": {
      "dnrajfg0": {
        "weight": 50.8413387285612,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "dnrajfg0": {
      "1cdf2y3o": {
        "weight": 52.60835521168463,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "1cdf2y3o": {
      "cx7y9ggw": {
        "weight": 54.411016349870074,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "cx7y9ggw": {
      "3rzpjcah": {
        "weight": 67.39405853856458,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "3rzpjcah": {
      "4jhf4ff8": {
        "weight": 147.89286527875652,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "4jhf4ff8": {
      "7w1irp4s": {
        "weight": 133.68403056492446,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "7w1irp4s": {
      "bski1ru2": {
        "weight": 23.626165600767152,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "8zfku9ox": {
      "4nm18bpk": {
        "weight": 26.283440312695543,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "4nm18bpk": {
      "gpbqq4fm": {
        "weight": 32.58051141647308,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "gpbqq4fm": {
      "49ew8s3i": {
        "weight": 33.73656845886298,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "49ew8s3i": {
      "i8dykd0q": {
        "weight": 64.81861651165922,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "i8dykd0q": {
      "2orl4eys": {
        "weight": 17.038405327384034,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "2orl4eys": {
      "i7csse9k": {
        "weight": 30.199475055535817,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "i7csse9k": {
      "28v6f55n": {
        "weight": 23.096050331854947,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "fbmma5qb": {
      "fkl7icxc": {
        "weight": 37.02623207705041,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "fkl7icxc": {
      "6hl8klnx": {
        "weight": 49.92611046782982,
        "type": "SkiTrail",
        "name": "Црвена дубока",
        "difficulty": 2
      }
    },
    "ktlt4zhb": {
      "g9rz4pi8": {
        "weight": 25.53399672425018,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "15umcuzm": {
      "86pci5sg": {
        "weight": 25.094192708314143,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "86pci5sg": {
      "k0i6jrp7": {
        "weight": 28.43882348830308,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "k0i6jrp7": {
      "40ntl6ot": {
        "weight": 37.941249580434054,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "40ntl6ot": {
      "7gky0ua": {
        "weight": 49.68517846365948,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "7gky0ua": {
      "ktlt4zhb": {
        "weight": 27.90264408066453,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "g9rz4pi8": {
      "44bp2dq4": {
        "weight": 75.00858994357203,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "44bp2dq4": {
      "3hp761b": {
        "weight": 107.63783435874407,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "nu4anvo": {
      "5ikazxyw": {
        "weight": 65.40995774318719,
        "type": "SkiTrail",
        "name": null
      }
    },
    "5ikazxyw": {
      "7usuodmt": {
        "weight": 61.68191411299976,
        "type": "SkiTrail",
        "name": null
      }
    },
    "7usuodmt": {
      "ljvs62sh": {
        "weight": 38.257298495804356,
        "type": "SkiTrail",
        "name": null
      }
    },
    "ljvs62sh": {
      "id4cj5f1": {
        "weight": 37.500372874814175,
        "type": "SkiTrail",
        "name": null
      }
    },
    "id4cj5f1": {
      "k9wwiwc4": {
        "weight": 26.75273600164915,
        "type": "SkiTrail",
        "name": null
      }
    },
    "k9wwiwc4": {
      "h4g0k3jy": {
        "weight": 34.21748866301592,
        "type": "SkiTrail",
        "name": null
      }
    },
    "h4g0k3jy": {
      "xt4a03d": {
        "weight": 61.144622604426935,
        "type": "SkiTrail",
        "name": null
      }
    },
    "xt4a03d": {
      "i7wv6asb": {
        "weight": 30.310218307649237,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "a8quxc2k": {
      "1mvepu1y": {
        "weight": 48.1290037922795,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "1mvepu1y": {
      "j9h2xoxw": {
        "weight": 105.74780700927104,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "j9h2xoxw": {
      "ii5o8v2i": {
        "weight": 76.06645851379723,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "jbm2rgt4": {
      "9aoei0zt": {
        "weight": 20.42580826593339,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "9aoei0zt": {
      "9g667nbj": {
        "weight": 140.88537575129484,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "9g667nbj": {
      "dn547h9q": {
        "weight": 199.40730512105088,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "dn547h9q": {
      "k1vci1rg": {
        "weight": 104.34314184909391,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "k1vci1rg": {
      "i1phz8wp": {
        "weight": 56.567240433561246,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "cmwsz7tr": {
      "2jfra0yt": {
        "weight": 46.925672180741536,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "2jfra0yt": {
      "k35tyid9": {
        "weight": 42.66750901267678,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "k35tyid9": {
      "iyxl6z7t": {
        "weight": 32.34867892864837,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "iyxl6z7t": {
      "xt4a03d": {
        "weight": 28.362867735562833,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "i7wv6asb": {
      "a2qskqqk": {
        "weight": 65.219901314831,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "a2qskqqk": {
      "3csnf121": {
        "weight": 24.674345390097397,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "3csnf121": {
      "197otgrg": {
        "weight": 24.31148829805479,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "197otgrg": {
      "fe3n4fwa": {
        "weight": 12.65753916901026,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      }
    },
    "fe3n4fwa": {
      "b91gpjhf": {
        "weight": 23.86713149299867,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "kemgymg": {
        "weight": 11.150579967166545,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "b91gpjhf": {},
    "2egofl2f": {
      "5f1bwos9": {
        "weight": 22.503457136498398,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      },
      "gafddgcu": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "5f1bwos9": {
      "b3tjb4h5": {
        "weight": 57.307012753488245,
        "type": "SkiTrail",
        "name": "Мали караман",
        "difficulty": 1
      },
      "2egofl2f": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "dcxe2pyv": {
      "gs73s4c5": {
        "weight": 52.13504774976365,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "gs73s4c5": {
      "1uq7453v": {
        "weight": 11.612026283772979,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      },
      "8nlefve6": {
        "weight": 22.714183107354536,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1uq7453v": {
      "8en4gq7v": {
        "weight": 44.75276040760143,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "8en4gq7v": {
      "drq0jtjc": {
        "weight": 81.40214372527416,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "drq0jtjc": {
      "a36etj2i": {
        "weight": 53.582175997096755,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "a36etj2i": {
      "dchoc1ns": {
        "weight": 33.165614900533086,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "dchoc1ns": {
      "61j5zb2s": {
        "weight": 65.8995723365766,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "61j5zb2s": {
      "82vsfcdg": {
        "weight": 50.9704061697467,
        "type": "SkiTrail",
        "name": "Везна стаза Мали караман - Марине воде",
        "difficulty": 1
      }
    },
    "2pmp1ffq": {
      "8uowrytu": {
        "weight": 63.75506674867165,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      },
      "etgink5a": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "8uowrytu": {
      "4a0j1vjg": {
        "weight": 65.16686811308392,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "4a0j1vjg": {
      "4brt3hk5": {
        "weight": 94.37332528375173,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "4brt3hk5": {
      "bgvevydz": {
        "weight": 207.91915164273476,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "bgvevydz": {
      "j694vfjl": {
        "weight": 109.28573018842667,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "j694vfjl": {
      "8qnvzvt7": {
        "weight": 18.44055385279445,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "8qnvzvt7": {
      "b5wvg5bv": {
        "weight": 24.381217490826298,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "b5wvg5bv": {
      "k342uz0q": {
        "weight": 34.885258918123355,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "k342uz0q": {
      "etwqw3ab": {
        "weight": 31.965208402839522,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "etwqw3ab": {
      "ah14imvq": {
        "weight": 20.78973756510413,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "ah14imvq": {
      "8f63tni5": {
        "weight": 22.56953362996161,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "8f63tni5": {
      "b2nshjs7": {
        "weight": 31.108468913790876,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "b2nshjs7": {
      "icvnimxf": {
        "weight": 7.744515634188246,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "icvnimxf": {
      "8ukevjdj": {
        "weight": 12.474485958752874,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "8ukevjdj": {
      "gx4xuj0c": {
        "weight": 6.382101536898812,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "gx4xuj0c": {
      "hx1zj14c": {
        "weight": 3.770169542752628,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "hx1zj14c": {
      "asfgdlmw": {
        "weight": 6.783043199907702,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "asfgdlmw": {
      "agjj5e4w": {
        "weight": 24.862973150153056,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "agjj5e4w": {
      "2qz3kfvu": {
        "weight": 7.703735634172473,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "2qz3kfvu": {
      "by5q7ils": {
        "weight": 8.34183001998012,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "by5q7ils": {
      "2mow5k7l": {
        "weight": 12.335896776185333,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "2mow5k7l": {
      "9fq24bg": {
        "weight": 12.806091773661198,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "9fq24bg": {
      "25il83h3": {
        "weight": 20.18886519896167,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "25il83h3": {
      "g5499lbg": {
        "weight": 31.48488498197238,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "g5499lbg": {
      "9ecjqcyy": {
        "weight": 42.679147060551564,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "9ecjqcyy": {
      "lnjsl5js": {
        "weight": 21.19197209570726,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "lnjsl5js": {
      "e8gxxrjn": {
        "weight": 17.569866129603145,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "e8gxxrjn": {
      "gaszx84s": {
        "weight": 68.4631467528263,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "gaszx84s": {
      "hbqf1tfb": {
        "weight": 40.174030452820006,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "hbqf1tfb": {
      "hdj9qfz": {
        "weight": 32.293985127480134,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "hdj9qfz": {
      "afuc91zk": {
        "weight": 30.335340298930422,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "afuc91zk": {
      "g0kfnow9": {
        "weight": 64.34831809602109,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "g0kfnow9": {
      "erq493av": {
        "weight": 47.26176231699964,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "erq493av": {
      "dvyox3le": {
        "weight": 19.136900779852848,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "dvyox3le": {
      "bpr5cm2i": {
        "weight": 23.489518308384213,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      },
      "4wq86unw": {
        "weight": 21.84206139737646,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "bpr5cm2i": {
      "vbsu4ix": {
        "weight": 41.45160496760441,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "vbsu4ix": {
      "2tnwri10": {
        "weight": 84.4163691988408,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "2tnwri10": {
      "aznu01ga": {
        "weight": 21.021188187864535,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "aznu01ga": {
      "6eb8gjzk": {
        "weight": 32.059953242626904,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "6eb8gjzk": {
      "4qr1zb49": {
        "weight": 51.36056252697669,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "4qr1zb49": {
      "dcfd3pqc": {
        "weight": 31.749868866315722,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "dcfd3pqc": {
      "q2g9lyv": {
        "weight": 14.931637532531077,
        "type": "SkiTrail",
        "name": "Везна стаза Јарам - Гвоздац"
      }
    },
    "eu954rlr": {
      "jeyhhgda": {
        "weight": 47.373238760133255,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "jeyhhgda": {
      "fkwvkfeq": {
        "weight": 66.4292613833213,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "fkwvkfeq": {
      "hpy8swel": {
        "weight": 62.23423952230513,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "fegwgrbj": {
      "hbo9j2ts": {
        "weight": 71.08265432594506,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "hbo9j2ts": {
      "81xq1e3j": {
        "weight": 39.4367630903176,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "81xq1e3j": {
      "7vxdkrdq": {
        "weight": 123.78776422021441,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "7vxdkrdq": {
      "dy36l6mx": {
        "weight": 60.90572336700522,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "dy36l6mx": {
      "61myda9": {
        "weight": 30.19349735416237,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "61myda9": {
      "k4vomxyv": {
        "weight": 11.558345484913499,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "k4vomxyv": {
      "fbioq0yd": {
        "weight": 8.852836659571965,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "fbioq0yd": {
      "8ffh80mw": {
        "weight": 10.163820325589974,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "8ffh80mw": {
      "j7d3nz9l": {
        "weight": 110.51077579853767,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "j7d3nz9l": {
      "71rsy3x3": {
        "weight": 76.27221052969826,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "71rsy3x3": {
      "k7rsdfvx": {
        "weight": 64.72968123090935,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "k7rsdfvx": {
      "cebf3fpd": {
        "weight": 78.63777964908489,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "cebf3fpd": {
      "6wrcw542": {
        "weight": 109.6049149639727,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "6wrcw542": {
      "gzcnfsc": {
        "weight": 49.592164958406,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "jgjjms6n": {
      "d9qlcxlf": {
        "weight": 144.5265344253297,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "d9qlcxlf": {
      "4mzc21k6": {
        "weight": 18.81191190025598,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "4mzc21k6": {
      "frelq81l": {
        "weight": 8.98233254880119,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "frelq81l": {
      "7okj9x0v": {
        "weight": 10.872505857806066,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "7okj9x0v": {
      "iei3pu0v": {
        "weight": 37.02398873168981,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "iei3pu0v": {
      "q2g9lyv": {
        "weight": 52.130757468119135,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Гвоздац"
      }
    },
    "dbdj7p0u": {
      "cgcoug5r": {
        "weight": 33.04787299108721,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "cgcoug5r": {
      "6j7lmqly": {
        "weight": 23.489897070593265,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "6j7lmqly": {
      "2o2q42e1": {
        "weight": 18.56548664177524,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gjvomdx0": {
      "8vzqhajy": {
        "weight": 21.134202627599933,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8vzqhajy": {
      "bhp0dyf3": {
        "weight": 23.635405163554346,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "bhp0dyf3": {
      "grfdlu2p": {
        "weight": 13.687058391379,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "grfdlu2p": {
      "hj4eqnsh": {
        "weight": 14.374204172065326,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "51hphu7t": {
      "h30y202k": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "9vxy2km4": {
      "51hphu7t": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "ayd4iiw9": {
      "9vxy2km4": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "9yu8s8qd": {
      "ayd4iiw9": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "g7ph42re": {
      "jeyy5wmo": {
        "weight": 40.78131158557412,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      },
      "9yu8s8qd": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "jeyy5wmo": {
      "bmpevj7u": {
        "weight": 57.37491784933875,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "bmpevj7u": {
      "36y6oeyg": {
        "weight": 80.33875746665596,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "36y6oeyg": {
      "fcfuvlcn": {
        "weight": 55.56316804832542,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "fcfuvlcn": {
      "avs0r2c2": {
        "weight": 38.31856021301747,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "avs0r2c2": {
      "b0ot5gvq": {
        "weight": 26.247826877068825,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "b0ot5gvq": {
      "4dat3878": {
        "weight": 38.24485135583962,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "4dat3878": {
      "606wovs5": {
        "weight": 42.56741190201027,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "606wovs5": {
      "jjv0wkul": {
        "weight": 49.85555320185618,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "jjv0wkul": {
      "dv7s1sw5": {
        "weight": 42.390301680470486,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "dv7s1sw5": {
      "ivqnid63": {
        "weight": 31.0248111240799,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "ivqnid63": {
      "kogb1pf8": {
        "weight": 35.85696475641195,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "kogb1pf8": {
      "92gc5s46": {
        "weight": 44.23967911865933,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "92gc5s46": {
      "rke4uvh": {
        "weight": 42.8757195616411,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "rke4uvh": {
      "cvqhjyab": {
        "weight": 37.91554822052153,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "cvqhjyab": {
      "6tr9ekmm": {
        "weight": 30.70477584813525,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "6tr9ekmm": {
      "i6359h85": {
        "weight": 24.243312925891193,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "i6359h85": {
      "8kmo3ec3": {
        "weight": 19.351132319502163,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "8kmo3ec3": {
      "ier0w20s": {
        "weight": 31.824739354223823,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "2lq8behv": {
      "7ef7uu7r": {
        "weight": 110.65463723997692,
        "type": "SkiTrail",
        "name": null
      }
    },
    "7ef7uu7r": {
      "1kstn08w": {
        "weight": 40.71156979442123,
        "type": "SkiTrail",
        "name": null
      }
    },
    "1kstn08w": {
      "2cb65scp": {
        "weight": 30.326992238619514,
        "type": "SkiTrail",
        "name": null
      }
    },
    "2cb65scp": {
      "39grsnbq": {
        "weight": 72.49025928478926,
        "type": "SkiTrail",
        "name": null
      }
    },
    "39grsnbq": {
      "g7ph42re": {
        "weight": 84.02868137701975,
        "type": "SkiTrail",
        "name": null
      }
    },
    "1i9tjcfh": {
      "54z8wbp4": {
        "weight": 76.74677914890934,
        "type": "SkiTrail",
        "name": null
      },
      "6lqfeau1": {
        "weight": 29.318589398177927,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "gafddgcu": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "54z8wbp4": {
      "bvvodch1": {
        "weight": 176.91666952835408,
        "type": "SkiTrail",
        "name": null
      },
      "fmg1f4wo": {
        "weight": 114.52115843239191,
        "type": "SkiTrail",
        "name": null
      }
    },
    "bvvodch1": {
      "88neq4c6": {
        "weight": 83.4775154890675,
        "type": "SkiTrail",
        "name": null
      }
    },
    "88neq4c6": {
      "73cbylrc": {
        "weight": 82.48194301333352,
        "type": "SkiTrail",
        "name": null
      }
    },
    "fmg1f4wo": {
      "3lq2p5nb": {
        "weight": 65.929216260724,
        "type": "SkiTrail",
        "name": null
      }
    },
    "3lq2p5nb": {
      "4fl0qt2h": {
        "weight": 67.19454171957122,
        "type": "SkiTrail",
        "name": null
      }
    },
    "4fl0qt2h": {
      "321c6h4g": {
        "weight": 40.66316124709293,
        "type": "SkiTrail",
        "name": null
      }
    },
    "321c6h4g": {
      "6zo01em0": {
        "weight": 50.224209982478875,
        "type": "SkiTrail",
        "name": null
      }
    },
    "6zo01em0": {
      "1v5x65ba": {
        "weight": 30.566602458343812,
        "type": "SkiTrail",
        "name": null
      }
    },
    "1v5x65ba": {
      "gstm5po8": {
        "weight": 39.525113241793974,
        "type": "SkiTrail",
        "name": null
      }
    },
    "eep9jbu4": {
      "k94w91t7": {
        "weight": 109.0448628184968,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "k94w91t7": {
      "6j9vl4vq": {
        "weight": 119.80838383247813,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "6j9vl4vq": {
      "62c6ox7u": {
        "weight": 71.75157697572284,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "62c6ox7u": {
      "9z11rpjo": {
        "weight": 36.77881007746336,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "9z11rpjo": {
      "h7lmw2": {
        "weight": 18.097114075630696,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "h7lmw2": {
      "clqdbgof": {
        "weight": 48.42900679024743,
        "type": "SkiTrail",
        "name": "Гвоздац"
      },
      "l9u30jfj": {
        "weight": 46.08222364697455,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "clqdbgof": {
      "bmv6a3mx": {
        "weight": 64.00669488491532,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "bmv6a3mx": {
      "x2haw9s": {
        "weight": 57.48957586053571,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "x2haw9s": {
      "dwcjdse1": {
        "weight": 70.25469459026557,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "dwcjdse1": {
      "7jlvgcua": {
        "weight": 33.67984487436132,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "7jlvgcua": {
      "31xrhq28": {
        "weight": 32.08118213939017,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "31xrhq28": {
      "40gpld0v": {
        "weight": 121.5694240202663,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "40gpld0v": {
      "8n082ya8": {
        "weight": 58.62474523536178,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "8n082ya8": {
      "q2g9lyv": {
        "weight": 62.16221805445418,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "l9u30jfj": {
      "5ftzal0g": {
        "weight": 50.45888268032676,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "5ftzal0g": {
      "k409vji2": {
        "weight": 57.027027218908195,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "k409vji2": {
      "1ls646po": {
        "weight": 28.096865515283998,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "1ls646po": {
      "8mf42hnl": {
        "weight": 26.20949728294789,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "8mf42hnl": {
      "2yikqdkh": {
        "weight": 43.88954967779209,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "2yikqdkh": {
      "l4l2a2zd": {
        "weight": 37.133743937400936,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "l4l2a2zd": {
      "bskgqs87": {
        "weight": 51.19973808735965,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "bskgqs87": {
      "aibz2baq": {
        "weight": 54.48960906123756,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "aibz2baq": {
      "vvkqwyd": {
        "weight": 40.69915616580368,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "vvkqwyd": {
      "hhg9igzp": {
        "weight": 40.983584476372364,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "hhg9igzp": {
      "ffbiumfk": {
        "weight": 45.71699249022451,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "ffbiumfk": {
      "jjy32h1": {
        "weight": 25.30746599485347,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "jjy32h1": {
      "dvyox3le": {
        "weight": 21.169675401930963,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "4wq86unw": {
      "jfbls12o": {
        "weight": 34.837593922391356,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "jfbls12o": {
      "4lpcfdt0": {
        "weight": 71.58226822930716,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "4lpcfdt0": {
      "8fcpkld6": {
        "weight": 68.46300719480806,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "8fcpkld6": {
      "7dcuhzm7": {
        "weight": 26.171000908716934,
        "type": "SkiTrail",
        "name": "Гвоздац"
      }
    },
    "9j9fz24d": {
      "f1ih2ftq": {
        "weight": 13.54027491559473,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      },
      "2lmzix5k": {
        "weight": 24.835823454648224,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Крчмар"
      }
    },
    "2lmzix5k": {
      "9j9fz24d": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "5dknj17m": {
        "weight": 0,
        "type": "SkiLift",
        "name": "Дубока 2"
      }
    },
    "hkxdham3": {
      "9rmz43l6": {
        "weight": 31.847151811495596,
        "type": "SkiTrail",
        "name": null
      },
      "9uwfyal3": {
        "weight": 20.973982321080815,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "9rmz43l6": {
      "33zsej83": {
        "weight": 68.58446887892387,
        "type": "SkiTrail",
        "name": null
      }
    },
    "33zsej83": {
      "7s4rg3ca": {
        "weight": 50.05824323263,
        "type": "SkiTrail",
        "name": null
      }
    },
    "7s4rg3ca": {
      "hox2k7q4": {
        "weight": 24.333750381704444,
        "type": "SkiTrail",
        "name": null
      }
    },
    "hox2k7q4": {
      "fkigteih": {
        "weight": 22.34260997673533,
        "type": "SkiTrail",
        "name": null
      }
    },
    "fkigteih": {
      "jfx7bv5e": {
        "weight": 13.730335723930452,
        "type": "SkiTrail",
        "name": null
      }
    },
    "jfx7bv5e": {
      "9on9pynu": {
        "weight": 20.87864399819068,
        "type": "SkiTrail",
        "name": null
      }
    },
    "9on9pynu": {
      "gw7f86as": {
        "weight": 30.84440953924624,
        "type": "SkiTrail",
        "name": null
      }
    },
    "gw7f86as": {
      "khkjgqgj": {
        "weight": 16.090994599265674,
        "type": "SkiTrail",
        "name": null
      }
    },
    "khkjgqgj": {
      "8i010i4n": {
        "weight": 25.641790217776244,
        "type": "SkiTrail",
        "name": null
      }
    },
    "8i010i4n": {
      "k9we1i9k": {
        "weight": 19.78344132935497,
        "type": "SkiTrail",
        "name": null
      }
    },
    "k9we1i9k": {
      "g2o8uwy4": {
        "weight": 37.81397766795554,
        "type": "SkiTrail",
        "name": null
      }
    },
    "97klopd": {
      "6opg81bh": {
        "weight": 19.64893393418246,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "6opg81bh": {
      "2mvrqwp6": {
        "weight": 128.9147287014173,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "2mvrqwp6": {
      "ajn18ob4": {
        "weight": 39.84734369313871,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "ajn18ob4": {
      "iwy3u9of": {
        "weight": 126.93124314312202,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "iwy3u9of": {
      "9ras1j26": {
        "weight": 62.92884703911271,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "9ras1j26": {
      "apsb376u": {
        "weight": 41.7662418112379,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "apsb376u": {
      "p71qn2o": {
        "weight": 73.69374521343363,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "p71qn2o": {
      "hggh1qlu": {
        "weight": 86.53674600707596,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "hggh1qlu": {
      "focr1pc5": {
        "weight": 63.44351737881779,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "focr1pc5": {
      "5ay7ob9s": {
        "weight": 88.03221831750793,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "5ay7ob9s": {
      "jjng42wg": {
        "weight": 52.99466477077611,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "jjng42wg": {
      "4qcya9wn": {
        "weight": 35.94717229539796,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "4qcya9wn": {
      "2rzmhdif": {
        "weight": 58.24829732368708,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "4pvuk88k": {
      "7nbt7hpt": {
        "weight": 14.115979533554228,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7nbt7hpt": {
      "a27u1o4l": {
        "weight": 18.995950255671413,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "a27u1o4l": {
      "2mlolel8": {
        "weight": 25.03034055066967,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "2mlolel8": {
      "4pqzbgy8": {
        "weight": 10.874722977629931,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "4pqzbgy8": {
      "emuj86dy": {
        "weight": 18.773197981941774,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "emuj86dy": {
      "slqnru8": {
        "weight": 32.52739490341773,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "slqnru8": {
      "ccdd0uqv": {
        "weight": 25.68411207776001,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ccdd0uqv": {
      "7qh24eq6": {
        "weight": 21.789903626042396,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7qh24eq6": {
      "gmzovcq7": {
        "weight": 17.30262960179298,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "gmzovcq7": {
      "fd24viuf": {
        "weight": 10.830670935976578,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "fd24viuf": {
      "7irk0hzd": {
        "weight": 16.411078142264586,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "7irk0hzd": {},
    "ivms958w": {
      "3n39agsf": {
        "weight": 44.5385537771033,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "3n39agsf": {
      "g7tywqgw": {
        "weight": 32.72922012620581,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "g7tywqgw": {
      "gxnabfni": {
        "weight": 16.073426058974455,
        "type": "SkiTrail",
        "difficulty": "0"
      }
    },
    "cmt1d5in": {
      "9vgp76bq": {
        "weight": 61.044726297341015,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "9vgp76bq": {
      "gk9cvwef": {
        "weight": 89.55690149237884,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "gk9cvwef": {
      "aax8hn5o": {
        "weight": 110.90037239633395,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "3fvieb21": {
        "weight": 118.62321253349143,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "aax8hn5o": {
      "ey1lqthi": {
        "weight": 54.19820259809324,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "ey1lqthi": {
      "5e825lbl": {
        "weight": 140.85298539643284,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "5e825lbl": {
      "58wshfsk": {
        "weight": 119.84722347690426,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "58wshfsk": {
      "juae8ays": {
        "weight": 30.250855563036684,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "3fvieb21": {
      "ko7ovmey": {
        "weight": 116.49034175599583,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "ko7ovmey": {
      "avu8ub61": {
        "weight": 86.51077774979053,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "avu8ub61": {
      "2bomvkd": {
        "weight": 49.49812367335161,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "4rqlg2ky": {
      "k2exxyum": {
        "weight": 16.48846228548788,
        "type": "SkiTrail",
        "name": "Пајино пресло",
        "difficulty": 0
      }
    },
    "k2exxyum": {
      "a7lnzgjr": {
        "weight": 32.02051293309946,
        "type": "SkiTrail",
        "name": "Пајино пресло",
        "difficulty": 0
      }
    },
    "a7lnzgjr": {
      "2wn9318w": {
        "weight": 19.61930675109362,
        "type": "SkiTrail",
        "name": "Пајино пресло",
        "difficulty": 0
      }
    },
    "2wn9318w": {
      "ipnu822h": {
        "weight": 16.208143311168236,
        "type": "SkiTrail",
        "name": "Пајино пресло",
        "difficulty": 0
      }
    },
    "ipnu822h": {},
    "41m2kxsh": {
      "h4mt38qx": {
        "weight": 51.7324009638508,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "h4mt38qx": {
      "i2e3nn04": {
        "weight": 16.888407975903156,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      },
      "edrw0n56": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "0"
      }
    },
    "i2e3nn04": {},
    "j7i28atl": {
      "gt65se4a": {
        "weight": 111.93925472499959,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      },
      "9eac0dyq": {
        "weight": 162.2716766966935,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "gt65se4a": {
      "7lmn4kz7": {
        "weight": 86.97690661420015,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "7lmn4kz7": {
      "d78spy6": {
        "weight": 172.77668536497416,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "d78spy6": {
      "k1x68vvb": {
        "weight": 114.66664146403978,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "9uwfyal3": {
      "2fie5x7n": {
        "weight": 23.071370202809984,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "2fie5x7n": {
      "fvfd9l1": {
        "weight": 29.731938828641034,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "fvfd9l1": {
      "b7uhssn2": {
        "weight": 32.2900493868664,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "b7uhssn2": {
      "kn4kcq1": {
        "weight": 24.25558562320051,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "kn4kcq1": {
      "a873p1z0": {
        "weight": 16.137580021754093,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "6iquvmv4": {
      "cdm94zp0": {
        "weight": 10.112827524281803,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "cdm94zp0": {
      "avu8ub61": {
        "weight": 10.036753676591557,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "bkatee76": {
      "df6zaiaq": {
        "weight": 30.398794872955996,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      },
      "ks822sf6": {
        "weight": 16.181977319456827,
        "type": "SkiTrail"
      },
      "go5hw00o": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "8bp0uut9": {
      "2m8iiaac": {
        "weight": 34.21184330662433,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "2m8iiaac": {
      "gczff9ys": {
        "weight": 22.244596854571085,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "gczff9ys": {
      "5ocoe8h": {
        "weight": 45.18139830645362,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "j27t8k3n": {
      "j7i28atl": {
        "weight": 175.677686456616,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "f2w27tzk": {
      "5s63elxr": {
        "weight": 46.39816495257763,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "5s63elxr": {
      "eb120vxw": {
        "weight": 29.77411390038163,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      },
      "8kydsfp2": {
        "weight": 21.7825555657795,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "g1acrjf6": {
      "9tk5l7bj": {
        "weight": 35.72422240489967,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "9tk5l7bj": {
      "5uqqf8id": {
        "weight": 12.062244156686257,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "b0s65bau": {
      "dyb9la1p": {
        "weight": 60.408381850086656,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      },
      "2ygfrl5": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "dyb9la1p": {
      "ar1f590y": {
        "weight": 53.41798915651648,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "ar1f590y": {
      "jn9aplbe": {
        "weight": 45.64106981576884,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "jn9aplbe": {
      "5hel6hwj": {
        "weight": 43.784843351436045,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "5hel6hwj": {
      "1o7c9p1f": {
        "weight": 57.70547544311153,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "1o7c9p1f": {
      "g8vhasxy": {
        "weight": 57.58520347608712,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "g8vhasxy": {
      "j3xowgvk": {
        "weight": 42.26423757228579,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "j3xowgvk": {
      "ifrz46yy": {
        "weight": 39.67915830320459,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "ifrz46yy": {
      "a36afis9": {
        "weight": 28.192442814770676,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "a36afis9": {
      "8h398evj": {
        "weight": 25.341947699348825,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "8h398evj": {
      "703ovo7i": {
        "weight": 58.741301783889135,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "703ovo7i": {
      "9f3m7rol": {
        "weight": 54.19880997174738,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "9f3m7rol": {
      "6jicf6eg": {
        "weight": 59.38732487565597,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "6jicf6eg": {
      "hpx4nq8": {
        "weight": 53.098622910944634,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "hpx4nq8": {
      "7f044yzk": {
        "weight": 57.71477179626145,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Дубока 2"
      }
    },
    "74et4w3d": {
      "kx7vaz21": {
        "weight": 29.131595510413362,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "kx7vaz21": {
      "hmyiuujq": {
        "weight": 19.45102776496986,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "hmyiuujq": {
      "g0zyoxqp": {
        "weight": 30.12796952359328,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "g0zyoxqp": {
      "dvdnjr9s": {
        "weight": 216.93720465391723,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "dvdnjr9s": {
      "ky182t0c": {
        "weight": 90.89848375157085,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "ky182t0c": {
      "1hmp20bk": {
        "weight": 24.63205461353025,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "1hmp20bk": {
      "2uuwxw61": {
        "weight": 17.500891288710662,
        "type": "SkiTrail",
        "name": "Дубока 2",
        "difficulty": 2
      }
    },
    "2uuwxw61": {
      "ldo3e65u": {
        "weight": 24.28762636317073,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Дубока 2"
      }
    },
    "igckwk80": {
      "fq2dfuv6": {
        "weight": 59.31172030197988,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "fq2dfuv6": {
      "2cg5tbvl": {
        "weight": 41.09550111178939,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "2cg5tbvl": {
      "eocwvx94": {
        "weight": 56.21044269992432,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "eocwvx94": {
      "3yam76c5": {
        "weight": 41.14885578364807,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "3yam76c5": {
      "a15swmiq": {
        "weight": 62.02989889289791,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "a15swmiq": {
      "kiduor0n": {
        "weight": 40.82158032102846,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "kiduor0n": {
      "huwmlvn9": {
        "weight": 80.73157138042514,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "huwmlvn9": {
      "b2h5j80o": {
        "weight": 37.02766421173711,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "b2h5j80o": {
      "g7hxx6mo": {
        "weight": 43.75513812232516,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "h83bzc9m": {
      "gxpl19vd": {
        "weight": 27.486732394986753,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "gxpl19vd": {
      "hraq8kit": {
        "weight": 32.29097864511982,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "hraq8kit": {
      "1nxfx410": {
        "weight": 26.590691276048297,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "1nxfx410": {
      "fmkclq4r": {
        "weight": 68.13641727714449,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "fmkclq4r": {
      "4m0mov9t": {
        "weight": 71.94297027819296,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "4m0mov9t": {
      "fcuy5ji8": {
        "weight": 97.9186574502515,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "fcuy5ji8": {
      "9fvb3tre": {
        "weight": 47.09805055919653,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "9fvb3tre": {
      "ffhnh9gs": {
        "weight": 41.62436365337857,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      }
    },
    "ffhnh9gs": {
      "8q6kon5l": {
        "weight": 31.64525142483915,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Треска"
      }
    },
    "7zigiy2o": {
      "hb69lcl7": {
        "weight": 90.60742372908587,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "hb69lcl7": {
      "fw7qiy74": {
        "weight": 109.26356495226146,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "fw7qiy74": {
      "ho9sn0j9": {
        "weight": 56.30062281416842,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "ho9sn0j9": {
      "gga0xui1": {
        "weight": 96.06646444475108,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "gga0xui1": {
      "4dcuv7yn": {
        "weight": 85.03918206319436,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "4dcuv7yn": {
      "a6q3ewcv": {
        "weight": 96.583560453426,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "a6q3ewcv": {
      "7h7j3az7": {
        "weight": 46.82453536463743,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "7h7j3az7": {
      "dnrajfg0": {
        "weight": 41.0289542271485,
        "type": "SkiTrail",
        "name": "Дубока 1",
        "difficulty": 2
      }
    },
    "51osgdfe": {
      "8xmmcxyt": {
        "weight": 34.859065601331636,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "8xmmcxyt": {
      "eskb31kc": {
        "weight": 115.88962702988665,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "eskb31kc": {
      "6yvm245o": {
        "weight": 148.36061064845174,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "6yvm245o": {
      "b7nb1vvg": {
        "weight": 136.15880592870954,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "b7nb1vvg": {
      "6opg81bh": {
        "weight": 70.35807586762951,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "5dhndshg": {
      "gabh6vgj": {
        "weight": 28.326728110228714,
        "type": "SkiTrail",
        "name": null
      },
      "a3wuj786": {
        "weight": 0,
        "type": "SkiLift",
        "name": "Дубока 1"
      }
    },
    "eyqligsy": {
      "5461f3r1": {
        "weight": 15.652721217340744,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "5461f3r1": {
      "60r8wiqg": {
        "weight": 35.68087711450056,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "eyqligsy": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "gw1uludv": {
      "al0i92gm": {
        "weight": 15.372072371294381,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      },
      "8cmo6s4e": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "al0i92gm": {
      "jdmb8led": {
        "weight": 13.485493999981996,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      },
      "gw1uludv": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "jdmb8led": {
      "72gdvrhn": {
        "weight": 12.511632253849712,
        "type": "SkiTrail",
        "name": "Треска",
        "difficulty": 1
      },
      "al0i92gm": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "72gdvrhn": {
      "1bc4lw38": {
        "weight": 10.468667604930987,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1bc4lw38": {
      "f99rzyr4": {
        "weight": 29.79746182829948,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Треска"
      },
      "8xix11cj": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "72gdvrhn": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "jv1cwiz1": {
      "a1cfxm3p": {
        "weight": 21.73734819172699,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "a1cfxm3p": {
      "88xaaebt": {
        "weight": 82.14177365506461,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "88xaaebt": {
      "dqpmunvj": {
        "weight": 125.95847094433039,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "dqpmunvj": {
      "5y60xf7z": {
        "weight": 116.01859911843164,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "5y60xf7z": {
      "f0ufcpfx": {
        "weight": 35.68896029914775,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "f0ufcpfx": {
      "a7qm1la3": {
        "weight": 85.19928901877105,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      }
    },
    "a7qm1la3": {
      "4ik6ch3v": {
        "weight": 110.08138286510109,
        "type": "SkiTrail",
        "name": "Везна стаза Караман гребен - Пајино пресло",
        "difficulty": 2
      },
      "8cjmlc6t": {
        "weight": 80.20398909733231,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "8cjmlc6t": {
      "kpav4ab4": {
        "weight": 31.203061641717074,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "kpav4ab4": {
      "2hq5vys1": {
        "weight": 19.445442322726514,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "2hq5vys1": {
      "7gky0ua": {
        "weight": 38.80997692620456,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "imw9gne1": {
      "1ge5zg51": {
        "weight": 68.6003148211494,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "1ge5zg51": {
      "d5pewfjs": {
        "weight": 57.222103627274,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "d5pewfjs": {
      "bysh2ubi": {
        "weight": 58.072152550952765,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "k5si1z82": {
      "88e2i5w8": {
        "weight": 23.944740146991723,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "88e2i5w8": {
      "b39six26": {
        "weight": 20.61125578871118,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "b39six26": {
      "1gkaifsm": {
        "weight": 28.02156570813638,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "1gkaifsm": {
      "2xdxurtq": {
        "weight": 30.439721407910113,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "2xdxurtq": {
      "8c5kjlii": {
        "weight": 33.526591229328375,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "8c5kjlii": {
      "8bm04vmk": {
        "weight": 58.4376796463588,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 2
      }
    },
    "fmtd7xml": {
      "j9w84bq1": {
        "weight": 16.969463961183187,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      },
      "3fz9xqm5": {
        "weight": 15.902686048648963,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "j9w84bq1": {
      "f2w27tzk": {
        "weight": 32.84430663279486,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 0
      }
    },
    "5dknj17m": {
      "2ygfrl5": {
        "weight": 42.73254561144764,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jz7bz0y6": {
      "ldo3e65u": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "ap9b4f93": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "5dhndshg": {
        "weight": 0,
        "type": "SkiLift",
        "name": "Дубока 1"
      }
    },
    "f1ih2ftq": {
      "kqag8w3z": {
        "weight": 30.579450090951255,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Крчмар"
      },
      "9j9fz24d": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "kqag8w3z": {
      "3blpuu53": {
        "weight": 1881.5347213440125,
        "type": "SkiLift",
        "name": "Крчмар"
      },
      "f1ih2ftq": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "essn030k": {
      "8xix11cj": {
        "weight": 1004.9054557536483,
        "type": "SkiLift",
        "name": "Треска"
      }
    },
    "jqb46oql": {
      "fn2wae99": {
        "weight": 840.9216446677699,
        "type": "SkiLift",
        "name": "9a Марине воде"
      }
    },
    "1esdyltu": {
      "9qnnsc45": {
        "weight": 840.80992321637,
        "type": "SkiLift",
        "name": "9 Марине воде"
      }
    },
    "kpuilnz1": {
      "4m3ngptb": {
        "weight": 28.368846261283192,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "4m3ngptb": {
      "jfp3rhdh": {
        "weight": 32.24947914745828,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jfp3rhdh": {
      "gs73s4c5": {
        "weight": 22.654050458873773,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "8nlefve6": {
      "1aybzh5t": {
        "weight": 22.55278803866293,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1aybzh5t": {
      "gm5tfs9f": {
        "weight": 30.479589359056817,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "lmaoliv8": {
      "82udj5l4": {
        "weight": 11.492394736112285,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "82udj5l4": {
      "ll4lz0k7": {
        "weight": 30.412717696605295,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "ll4lz0k7": {
      "1bp4r3bt": {
        "weight": 21.54341383099048,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1bp4r3bt": {
      "1cod4djw": {
        "weight": 20.181522698245033,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "1cod4djw": {
      "dko95udv": {
        "weight": 17.023206068187378,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "jd76578v": {
      "cqj2vz1d": {
        "weight": 60.03789300998921,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "cqj2vz1d": {
      "72jb6at4": {
        "weight": 13.66982648593993,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "72jb6at4": {
      "91mgatwo": {
        "weight": 36.14437225719716,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "91mgatwo": {
      "dko95udv": {
        "weight": 21.803899090563274,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "9qnnsc45": {
      "47p7iztr": {
        "weight": 16.15317932715434,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "l6e1y1jx": {
      "jdsjfx01": {
        "weight": 14.92190878450984,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "jdsjfx01": {
      "6monyzn4": {
        "weight": 61.8465600453828,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "6monyzn4": {
      "6s85fmfr": {
        "weight": 21.409859578692178,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "6s85fmfr": {
      "5wkr6zsw": {
        "weight": 14.48086180666458,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "bi3eqvds": {
        "weight": 0,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "6monyzn4": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "jym80435": {
      "b55p2tnd": {
        "weight": 9.032479289826172,
        "type": "SkiTrail"
      }
    },
    "b55p2tnd": {
      "bi3eqvds": {
        "weight": 15.650185858597842,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "jym80435": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "3l2t06xj": {
      "gafddgcu": {
        "weight": 21.102891482065957,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "liqpda8": {
        "weight": 21.80304492614392,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "6lqfeau1": {
      "h57898bw": {
        "weight": 58.22892749375679,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "3q3vpwab": {
        "weight": 25.177711487810967,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "8xix11cj": {
      "1bc4lw38": {
        "weight": 18.284740352145334,
        "type": "SkiTrail",
        "name": null
      }
    },
    "a2ou6ahx": {
      "ka8856a9": {
        "weight": 90.58040070028925,
        "type": "SkiTrail",
        "name": null
      }
    },
    "bevpi6ji": {
      "cycqnrkf": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "gg486568": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "8kydsfp2": {
      "bvwjj5nq": {
        "weight": 0,
        "type": "SkiLift"
      },
      "5s63elxr": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "b6m9ull2": {
      "h5dbsifb": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "ks822sf6": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "a3wuj786": {
      "go5hw00o": {
        "weight": 33.39337033926175,
        "type": "SkiTrail",
        "name": "Панчићев врх"
      }
    },
    "fn2wae99": {
      "47p7iztr": {
        "weight": 15.816198099528098,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "1jfd7j89": {
      "3l2t06xj": {
        "weight": 1015.717288573897,
        "type": "SkiLift",
        "name": "Мали караман"
      }
    },
    "kemgymg": {
      "1jfd7j89": {
        "weight": 43.56431068036263,
        "type": "SkiLift",
        "name": "Мали караман"
      }
    },
    "ft7q1mc8": {
      "2o2q42e1": {
        "weight": 21.50592965140336,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      }
    },
    "i0ahxljr": {
      "3s845jx": {
        "weight": 13.54093469757109,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "e81fjyon": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "e81fjyon": {
      "i0ahxljr": {
        "weight": 12.390970505179192,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "7abz2mdd": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "6qqv6hb5": {
      "ka8856a9": {
        "weight": 15.93284889331975,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "kb1dgab4": {
      "dlzqq3il": {
        "weight": 25.55007362710605,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "dlzqq3il": {
      "6qqv6hb5": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "etgink5a": {
      "fn3mh5lj": {
        "weight": 13.937421245430386,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 1
      },
      "2pmp1ffq": {
        "weight": 21.883544514887365,
        "type": "SkiTrail"
      },
      "drz1liz": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "fn3mh5lj": {
      "etgink5a": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "jym80435": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "ks822sf6": {
      "bkatee76": {
        "weight": 16.181977319456827,
        "type": "SkiTrail"
      },
      "abvxq1yi": {
        "weight": 22.261416206948283,
        "type": "SkiTrail"
      },
      "go5hw00o": {
        "weight": 24.740440799487935,
        "type": "SkiTrail"
      }
    },
    "fksa888j": {
      "27fdwji1": {
        "weight": 25.055396878690978,
        "type": "SkiTrail",
        "name": null
      },
      "2pmp1ffq": {
        "weight": 34.13017710843825,
        "type": "SkiTrail"
      }
    },
    "27fdwji1": {
      "e10iuits": {
        "weight": 30.11387960748702,
        "type": "SkiTrail",
        "name": null
      },
      "fksa888j": {
        "weight": 25.055396878690978,
        "type": "SkiTrail"
      }
    },
    "e10iuits": {
      "27fdwji1": {
        "weight": 30.11387960748702,
        "type": "SkiTrail"
      },
      "dui9n1oh": {
        "weight": 54.1010070231163,
        "type": "SkiTrail"
      }
    },
    "723zt8j0": {
      "alravs2": {
        "weight": 32.59593305192325,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "alravs2": {
      "7de9aou6": {
        "weight": 51.60785262506229,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "7de9aou6": {
      "l6i4aprw": {
        "weight": 62.687408388722915,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "l6i4aprw": {
      "8n2ydvtx": {
        "weight": 91.02877698550577,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "8n2ydvtx": {
      "58wshfsk": {
        "weight": 43.38935819334493,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "juae8ays": {
      "aqeqrdte": {
        "weight": 28.640991599569602,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "aqeqrdte": {
      "iab8oo1c": {
        "weight": 15.41555531452457,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "iab8oo1c": {
      "kjvj1o8w": {
        "weight": 14.848463780614075,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "kjvj1o8w": {
      "2sd76qzx": {
        "weight": 17.810498626928286,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      }
    },
    "2sd76qzx": {
      "gs32l0re": {
        "weight": 14.903855312877171,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Караман гребен"
      }
    },
    "e2t5je04": {
      "ijjg0s9s": {
        "weight": 17.87489016538949,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "gs32l0re": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "ijjg0s9s": {
      "8gkdewv1": {
        "weight": 41.40744138865554,
        "type": "SkiTrail",
        "name": "Караман гребен",
        "difficulty": 1
      },
      "e2t5je04": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "8gkdewv1": {
      "eb120vxw": {
        "weight": 46.256123683622086,
        "type": "SkiTrail",
        "difficulty": "0",
        "name": "Долина спортова"
      },
      "ijjg0s9s": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "jhq1wrhh": {
      "9s0bpy88": {
        "weight": 40.340521166753994,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "9s0bpy88": {
      "z1bm0ml": {
        "weight": 61.03210760625341,
        "type": "SkiTrail",
        "name": null,
        "difficulty": 2
      }
    },
    "5wkob4my": {
      "kaw3antr": {
        "weight": 30.834600704145497,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "kaw3antr": {
      "crmursnb": {
        "weight": 29.133078033568292,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "crmursnb": {
      "6p2qbtas": {
        "weight": 41.071357707118736,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "6p2qbtas": {
      "l3ff3eck": {
        "weight": 57.754009950826145,
        "type": "SkiTrail",
        "name": "Панчићев врх",
        "difficulty": 1
      }
    },
    "1p07f0ri": {
      "l2k9taby": {
        "weight": 38.917593658558836,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "l2k9taby": {
      "1cfqjb19": {
        "weight": 34.39868560802717,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "1cfqjb19": {
      "j3d1pxve": {
        "weight": 53.421172265267316,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "j3d1pxve": {
      "2ridqei5": {
        "weight": 46.1319666662446,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "2ridqei5": {
      "jooe9org": {
        "weight": 39.46083227707988,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "jooe9org": {
      "7zd8hbw8": {
        "weight": 52.46675132949803,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "7zd8hbw8": {
      "fv7cl5i1": {
        "weight": 40.40472283993038,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "fv7cl5i1": {
      "bp8in94j": {
        "weight": 38.9095469574114,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "bp8in94j": {
      "awm95no5": {
        "weight": 36.903702259878635,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "awm95no5": {
      "9m58ntuc": {
        "weight": 28.709878996575505,
        "type": "SkiTrail",
        "name": "Гобеља",
        "difficulty": 2
      }
    },
    "3rzm7mco": {
      "79j0qar9": {
        "weight": 40.835960213309,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "79j0qar9": {
      "hhxtiawx": {
        "weight": 15.825102096743514,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "hhxtiawx": {
      "kb1dgab4": {
        "weight": 16.61491186205566,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "15vpgnix": {
      "grhk1xcq": {
        "weight": 114.77642621035675,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "grhk1xcq": {
      "gcwdnaks": {
        "weight": 147.25300437901694,
        "type": "SkiTrail",
        "name": "Сунчана долина",
        "difficulty": 1
      }
    },
    "egreq0hv": {
      "endxfvoo": {
        "weight": 17.7366536494646,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "endxfvoo": {
      "d8flzfq3": {
        "weight": 28.930543188782938,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "d8flzfq3": {
      "kuwg2qls": {
        "weight": 22.20200670606665,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "kuwg2qls": {
      "b3i7ytjl": {
        "weight": 21.040501510707692,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "b3i7ytjl": {
      "39cltbg": {
        "weight": 14.14268703252781,
        "type": "SkiTrail",
        "name": "Везна стаза Гобеља - Јарам",
        "difficulty": 1
      }
    },
    "ke9bayax": {
      "aevet8h5": {
        "weight": 70.38392504392169,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      }
    },
    "aevet8h5": {
      "1hqvftnm": {
        "weight": 98.82347010083858,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      }
    },
    "1hqvftnm": {
      "izb0tzi3": {
        "weight": 82.04032750533212,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      }
    },
    "izb0tzi3": {
      "i66sfvdx": {
        "weight": 64.52785148619716,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      }
    },
    "i66sfvdx": {
      "kqq86nxq": {
        "weight": 48.082724116005394,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      }
    },
    "kqq86nxq": {
      "kgtco7xr": {
        "weight": 60.40670058124106,
        "type": "SkiTrail",
        "name": "Везна стаза Дубока 1 - Крчмар",
        "difficulty": 2
      }
    },
    "azyhmqmk": {
      "f3v7ohih": {
        "weight": 28.080221164488403,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "f3v7ohih": {
      "he90pead": {
        "weight": 30.0384514228467,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "he90pead": {
      "2jabudxy": {
        "weight": 32.257236743957506,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "2jabudxy": {
      "6jm2zplq": {
        "weight": 29.927846439364377,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "6jm2zplq": {
      "ffq9v9og": {
        "weight": 26.791598747380124,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "ffq9v9og": {
      "bbufhv2w": {
        "weight": 37.27928435682944,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "bbufhv2w": {
      "ap9b4f93": {
        "weight": 41.69496265884128,
        "type": "SkiTrail",
        "name": "Везна стаза Крчмар - Дубока 1"
      }
    },
    "dh76yrs0": {
      "end6g4z4": {
        "weight": 29.398237966739067,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "end6g4z4": {
      "kc7sh440": {
        "weight": 21.69828225814345,
        "type": "SkiTrail",
        "name": "Крчмар",
        "difficulty": 2
      }
    },
    "kc7sh440": {
      "9j9fz24d": {
        "weight": 32.15734360707014,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Крчмар"
      }
    },
    "gafddgcu": {
      "2egofl2f": {
        "weight": 34.508580120416724,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "1i9tjcfh": {
        "weight": 42.32686616823107,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "j34svbtn": {
        "weight": 38.52653416344484,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "98vd675s": {
        "weight": 42.154494645522554,
        "type": "SkiTrail",
        "difficulty": "0"
      },
      "k6vuqg96": {
        "weight": 53.180997192195235,
        "type": "SkiTrail",
        "difficulty": "0"
      },
      "hscr0jqw": {
        "weight": 35.645553143590064,
        "type": "SkiTrail"
      },
      "3l2t06xj": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "47p7iztr": {
      "q1k21em": {
        "weight": 39.97498553157609,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "l6e1y1jx": {
        "weight": 41.06234333860198,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "h0bikx45": {
        "weight": 35.542179090084886,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "dko95udv": {
      "1esdyltu": {
        "weight": 19.28926495498732,
        "type": "SkiTrail",
        "difficulty": "1"
      }
    },
    "l3jlk4sg": {
      "4uggog47": {
        "weight": 25.951437398423636,
        "type": "SkiTrail"
      }
    },
    "dui9n1oh": {
      "bonea9nq": {
        "weight": 37.0212766994564,
        "type": "SkiTrail",
        "difficulty": "1"
      },
      "e10iuits": {
        "weight": 54.1010070231163,
        "type": "SkiTrail"
      },
      "7jct38ik": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "enp4dorq": {
      "gbvk14cl": {
        "weight": 12.786717958110259,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Гобеља"
      }
    },
    "go5hw00o": {
      "abvxq1yi": {
        "weight": 30.12054275706846,
        "type": "SkiTrail",
        "name": "Црна дубока"
      },
      "ks822sf6": {
        "weight": 24.740440799487935,
        "type": "SkiTrail",
        "name": "Панчићев врх"
      },
      "bkatee76": {
        "weight": 20.158293951813395,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Панчићев врх"
      },
      "a3wuj786": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "gs32l0re": {
      "e2t5je04": {
        "weight": 15.24001737288228,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Караман гребен"
      }
    },
    "8q6kon5l": {
      "essn030k": {
        "weight": 14.765542443342957,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Треска"
      }
    },
    "ghn0te1m": {
      "4a1ewbaj": {
        "weight": 69.03968786277899,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Суво рудиште"
      },
      "9ap85u7k": {
        "weight": 14.580431053574422,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Крст"
      },
      "azrits2m": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "3fz9xqm5": {
      "97adle0h": {
        "weight": 270.1718666166662,
        "type": "SkiLift"
      },
      "fmtd7xml": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "ier0w20s": {
      "h8e8wx1r": {
        "weight": 24.83766907844683,
        "type": "SkiTrail",
        "difficulty": "1",
        "name": "Кнежевске баре"
      },
      "8p8knzgn": {
        "weight": 35.079852349631736,
        "type": "SkiTrail",
        "name": "Везна стаза Бела река - Кнежевске баре"
      }
    },
    "7f044yzk": {
      "74et4w3d": {
        "weight": 31.85368801050912,
        "type": "SkiTrail",
        "difficulty": "2",
        "name": "Дубока 2"
      }
    },
    "79yw027k": {
      "k6vuqg96": {
        "weight": 0,
        "type": "SkiTrail"
      }
    },
    "7abz2mdd": {
      "h30y202k": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "79yw027k": {
        "weight": 0,
        "type": "SkiLift"
      }
    },
    "8x9x4yuu": {
      "5sibsyd6": {
        "weight": 0,
        "type": "SkiTrail"
      },
      "e81fjyon": {
        "weight": 0,
        "type": "SkiLift"
      }
    }
  }
}