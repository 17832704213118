

class HomeModel {
    // #skiTrailsChangeListeners = [];

    async init() {
        // try {
        //     const response = await fetch('/assets/lines.geojson');
        //     if (!response.ok) {
        //         throw new Error(`HTTP error! status: ${response.status}`);
        //     }
        //     this.skiTrails = await response.json();
        // } catch (error) {
        //     console.error('Error fetching the JSON file:', error);
        // }

        // this.#skiTrailsChangeListeners.forEach(listener => listener(this.skiTrails));
    }

    // setOnSkiTrailsChangeEventListener(listener) {
    //     this.#skiTrailsChangeListeners.push(listener);
    // }

}

export default HomeModel;