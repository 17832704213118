{
"type": "FeatureCollection",
"name": "points",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "fid": 1, "full_id": "n561950352", "osm_id": "561950352", "osm_type": "node", "int_name": null, "ref": "2", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Malo jezero", "name:sr": "Мало језеро", "name": "Мало језеро", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.806546, 43.2803261 ] } },
{ "type": "Feature", "properties": { "fid": 2, "full_id": "n561950408", "osm_id": "561950408", "osm_type": "node", "int_name": null, "ref": "2", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Malo jezero", "name:sr": "Мало језеро", "name": "Мало језеро", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8077541, 43.2843948 ] } },
{ "type": "Feature", "properties": { "fid": 3, "full_id": "n561950467", "osm_id": "561950467", "osm_type": "node", "int_name": null, "ref": "21", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Krčmar", "name:sr": "Крчмар", "name": "Крчмар", "internet_access": "yes", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8258449, 43.2695951 ] } },
{ "type": "Feature", "properties": { "fid": 4, "full_id": "n561950469", "osm_id": "561950469", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Pančić ekspres", "name:sr": "Панчић експрес", "name": "Панчић експрес", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8200776, 43.2731466 ] } },
{ "type": "Feature", "properties": { "fid": 5, "full_id": "n561950607", "osm_id": "561950607", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "22 Mašinac", "name:sr": "22 Машинац", "name": "22 Машинац", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8117215, 43.2827091 ] } },
{ "type": "Feature", "properties": { "fid": 6, "full_id": "n561950671", "osm_id": "561950671", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Pančić ekspres", "name:sr": "Панчић експрес", "name": "Панчић експрес", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8107598, 43.2836212 ] } },
{ "type": "Feature", "properties": { "fid": 7, "full_id": "n561950699", "osm_id": "561950699", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Karaman greben", "name:sr": "Караман гребен", "name": "Караман гребен", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8115282, 43.2835177 ] } },
{ "type": "Feature", "properties": { "fid": 8, "full_id": "n561950876", "osm_id": "561950876", "osm_type": "node", "int_name": "Duboka 1 medjustanica", "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Duboka 1 međustanica", "name:sr": "Дубока 1 међустаница", "name": "Дубока 1 међустаница", "internet_access": "no", "aerialway:access": "both", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8251534, 43.2770991 ] } },
{ "type": "Feature", "properties": { "fid": 9, "full_id": "n561950985", "osm_id": "561950985", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "SkijalištaSrbije", "name:sr-Latn": "7 Karaman greben", "name:sr": "7 Караман гребен", "name": "7 Караман гребен", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8266269, 43.2855455 ] } },
{ "type": "Feature", "properties": { "fid": 10, "full_id": "n561951031", "osm_id": "561951031", "osm_type": "node", "int_name": null, "ref": "8a", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Mali karaman", "name:sr": "Мали караман", "name": "Мали караман", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8170815, 43.2889529 ] } },
{ "type": "Feature", "properties": { "fid": 11, "full_id": "n561951034", "osm_id": "561951034", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Mali karaman", "name:sr": "Мали караман", "name": "Мали караман", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8171233, 43.2893435 ] } },
{ "type": "Feature", "properties": { "fid": 12, "full_id": "n561951069", "osm_id": "561951069", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "9 Marine vode", "name:sr": "9 Марине воде", "name": "9 Марине воде", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8170207, 43.2930361 ] } },
{ "type": "Feature", "properties": { "fid": 13, "full_id": "n561951179", "osm_id": "561951179", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "14 Kneževske bare", "name:sr": "14 Кнежевске баре", "name": "14 Кнежевске баре", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.829784, 43.2914086 ] } },
{ "type": "Feature", "properties": { "fid": 14, "full_id": "n561951303", "osm_id": "561951303", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": null, "name:sr": null, "name": null, "internet_access": "no", "aerialway:access": "exit", "aerialway": null }, "geometry": { "type": "Point", "coordinates": [ 20.8290505, 43.2917591 ] } },
{ "type": "Feature", "properties": { "fid": 15, "full_id": "n561951364", "osm_id": "561951364", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "9 Marine vode", "name:sr": "9 Marine vode", "name": "9 Марине воде", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8266589, 43.2958586 ] } },
{ "type": "Feature", "properties": { "fid": 16, "full_id": "n561951425", "osm_id": "561951425", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "10 Karaman", "name:sr": "10 Караман", "name": "10 Караман", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8283321, 43.29712 ] } },
{ "type": "Feature", "properties": { "fid": 17, "full_id": "n561951555", "osm_id": "561951555", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "10 Karaman", "name:sr": "10 Караман", "name": "10 Караман", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.826594, 43.3042202 ] } },
{ "type": "Feature", "properties": { "fid": 18, "full_id": "n561951573", "osm_id": "561951573", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "11 Jaram", "name:sr": "11 Јарам", "name": "11 Јарам", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8266302, 43.3057473 ] } },
{ "type": "Feature", "properties": { "fid": 19, "full_id": "n561951593", "osm_id": "561951593", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "11 Jaram", "name:sr": "11 Јарам", "name": "11 Јарам", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8261694, 43.3108292 ] } },
{ "type": "Feature", "properties": { "fid": 20, "full_id": "n561951677", "osm_id": "561951677", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": null, "name:sr-Latn": "Duboka 1", "name:sr": "Дубока 1", "name": "Дубока 1", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8326249, 43.2794384 ] } },
{ "type": "Feature", "properties": { "fid": 21, "full_id": "n561951765", "osm_id": "561951765", "osm_type": "node", "int_name": null, "ref": "21", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Krčmar", "name:sr": "Крчмар", "name": "Крчмар", "internet_access": "yes", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8406593, 43.2826331 ] } },
{ "type": "Feature", "properties": { "fid": 22, "full_id": "n561951768", "osm_id": "561951768", "osm_type": "node", "int_name": null, "ref": "20", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Duboka 2", "name:sr": "Дубока 2", "name": "Дубока 2", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8400118, 43.2826083 ] } },
{ "type": "Feature", "properties": { "fid": 23, "full_id": "n561951802", "osm_id": "561951802", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "14 Kneževske bare", "name:sr": "14 Кнежевске баре", "name": "14 Кнежевске баре", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8366235, 43.2865023 ] } },
{ "type": "Feature", "properties": { "fid": 24, "full_id": "n561951970", "osm_id": "561951970", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "19 Gvozdac", "name:sr": "19 Гвоздац", "name": "19 Гвоздац", "internet_access": "wlan", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8384112, 43.3007614 ] } },
{ "type": "Feature", "properties": { "fid": 25, "full_id": "n561952129", "osm_id": "561952129", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "13 Gobelja greben", "name:sr": "13 Гобеља гребен", "name": "13 Гобеља гребен", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8335501, 43.3212287 ] } },
{ "type": "Feature", "properties": { "fid": 26, "full_id": "n648953192", "osm_id": "648953192", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "12 Gobelja relej", "name:sr": "12 Гобеља релеј", "name": "12 Гобеља релеј", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8242627, 43.316108 ] } },
{ "type": "Feature", "properties": { "fid": 27, "full_id": "n1160279788", "osm_id": "1160279788", "osm_type": "node", "int_name": null, "ref": "3", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Krst", "name:sr": "Крст", "name": "Крст", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8097522, 43.2775485 ] } },
{ "type": "Feature", "properties": { "fid": 28, "full_id": "n1160279873", "osm_id": "1160279873", "osm_type": "node", "int_name": null, "ref": "3", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Krst", "name:sr": "Крст", "name": "Крст", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8099898, 43.2835528 ] } },
{ "type": "Feature", "properties": { "fid": 29, "full_id": "n1160280472", "osm_id": "1160280472", "osm_type": "node", "int_name": null, "ref": "1", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Sunčana dolina", "name:sr": "Сунчана долина", "name": "Сунчана долина", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.807846, 43.2793744 ] } },
{ "type": "Feature", "properties": { "fid": 30, "full_id": "n1619592268", "osm_id": "1619592268", "osm_type": "node", "int_name": null, "ref": "1", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Sunčana dolina", "name:sr": "Сунчана долина", "name": "Сунчана долина", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.7964588, 43.2815602 ] } },
{ "type": "Feature", "properties": { "fid": 31, "full_id": "n4656456062", "osm_id": "4656456062", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "19 Gvozdac", "name:sr": "19 Гвоздац", "name": "19 Гвоздац", "internet_access": "wlan", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8296441, 43.2922741 ] } },
{ "type": "Feature", "properties": { "fid": 32, "full_id": "n6567943817", "osm_id": "6567943817", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Mali karaman", "name:sr": "Мали караман", "name": "Мали караман", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8290521, 43.2921807 ] } },
{ "type": "Feature", "properties": { "fid": 33, "full_id": "n6814463032", "osm_id": "6814463032", "osm_type": "node", "int_name": null, "ref": "5", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Treska", "name:sr": "Треска", "name": "Треска", "internet_access": null, "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8007231, 43.2702867 ] } },
{ "type": "Feature", "properties": { "fid": 34, "full_id": "n6814591523", "osm_id": "6814591523", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "9a Marine vode", "name:sr": "9a Марине воде", "name": "9a Марине воде", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8170375, 43.2930043 ] } },
{ "type": "Feature", "properties": { "fid": 35, "full_id": "n6814591524", "osm_id": "6814591524", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "9a Marine vode", "name:sr": "9a Марине воде", "name": "9a Марине воде", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8266722, 43.2958358 ] } },
{ "type": "Feature", "properties": { "fid": 36, "full_id": "n6814594693", "osm_id": "6814594693", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "13 Gobelja greben", "name:sr": "13 Гобеља гребен", "name": "13 Гобеља гребен", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8239535, 43.3184909 ] } },
{ "type": "Feature", "properties": { "fid": 37, "full_id": "n6814662248", "osm_id": "6814662248", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "22 Mašinac", "name:sr": "22 Машинац", "name": "22 Машинац", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8148414, 43.2817451 ] } },
{ "type": "Feature", "properties": { "fid": 38, "full_id": "n7732008563", "osm_id": "7732008563", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": null, "name:sr-Latn": "Međustanica FIS", "name:sr": "Међустаница ФИС", "name": "Међустаница ФИС", "internet_access": null, "aerialway:access": null, "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8429332, 43.2948735 ] } },
{ "type": "Feature", "properties": { "fid": 39, "full_id": "n7732008564", "osm_id": "7732008564", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": null, "name:sr-Latn": "Međustanica Struga", "name:sr": "Међустаница Струга", "name": "Међустаница Струга", "internet_access": null, "aerialway:access": null, "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8578279, 43.2944695 ] } },
{ "type": "Feature", "properties": { "fid": 40, "full_id": "n7732008566", "osm_id": "7732008566", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": null, "name:sr-Latn": "Stanica Mali karaman", "name:sr": "Станица Мали караман", "name": "Станица Мали караман", "internet_access": null, "aerialway:access": null, "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.829942, 43.2920346 ] } },
{ "type": "Feature", "properties": { "fid": 41, "full_id": "n8501596276", "osm_id": "8501596276", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "12 Gobelja relej", "name:sr": "12 Гобеља релеј", "name": "12 Гобеља релеј", "internet_access": "no", "aerialway:access": "entry", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8317952, 43.3181497 ] } },
{ "type": "Feature", "properties": { "fid": 42, "full_id": "n8542836854", "osm_id": "8542836854", "osm_type": "node", "int_name": null, "ref": "5", "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Treska", "name:sr": "Треска", "name": "Треска", "internet_access": null, "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.811636, 43.2745924 ] } },
{ "type": "Feature", "properties": { "fid": 43, "full_id": "n8568249171", "osm_id": "8568249171", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "Duboka 1", "name:sr": "Дубока 1", "name": "Дубока 1", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8203979, 43.2729357 ] } },
{ "type": "Feature", "properties": { "fid": 44, "full_id": "n8625716610", "osm_id": "8625716610", "osm_type": "node", "int_name": null, "ref": null, "public_transport": "station", "operator": "Skijališta Srbije", "name:sr-Latn": "20 Duboka 2", "name:sr": "20 Дубока 2", "name": "20 Дубока 2", "internet_access": "no", "aerialway:access": "exit", "aerialway": "station" }, "geometry": { "type": "Point", "coordinates": [ 20.8275473, 43.2857514 ] } }
]
}
