import NavigationBarModel from '../models/NavigationBarModel.js';
import NavigationBarView from '../views/NavigationBarView.js';

class NavigationBarController {
    #navigationBarModel;
    #navigationBarView = new NavigationBarView();

    #setPageActive(pageID) {
        const page = this.#navigationBarModel.pages.find(page => page[0] == pageID);
        page[2].onNavigation();
        this.#navigationBarView.renderPage(this.#navigationBarModel.pages, pageID);
    }

    constructor(pages) {
        this.#navigationBarModel = new NavigationBarModel(pages);
    }

    init() {
        this.#navigationBarModel.init();
        this.#navigationBarView.init();

        this.#navigationBarModel.setOnPageChangeEventListener(this.onNavigationBarChange);
        this.#navigationBarView.setOnClickEventListener(this.onNavigationItemClick);

        //init all page controllers
        for (const page of this.#navigationBarModel.pages) {
            const pageController = page[2];
            pageController.init();
        }

        this.#navigationBarView.renderButtons(this.#navigationBarModel.pages);
        this.#setPageActive(this.#navigationBarModel.pages[2][0]);
    }

    onNavigationBarChange = (pageID) => {
        this.#setPageActive(pageID);
    }

    onNavigationItemClick = (pageID) => {
        this.#navigationBarModel.setActivePage(pageID);
    }
}

export default NavigationBarController;
