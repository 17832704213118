
import MapModel from '../models/MapModel.js';
import MapView from '../views/MapView.js';
import { Graph, Node, NodesConnection } from '../models/SkiResortModel.js';
import { CursorMode } from '../models/MapModel.js';
import skiTrailsSurfaces from '../../assets/multipolygons.json';

class MapController {
    #mapModel = new MapModel(43.28541585112006, 20.810380223701962, 14);
    #mapView = new MapView();

    #skiResortModel;
    #skiResortSimulationModel;

    init() {
        this.#mapModel.init();
        this.#mapView.init(this.#mapModel);

        this.#mapModel.cursorMode.subscribe(cursorMode => this.onCursorModeChange(cursorMode));

        this.#mapView.setOnMouseMoveEvent((coords) => {this.onMouseMove(coords)})
        this.#mapView.setOnCameraMoveEndEvent((coords, zoom) => {this.onCameraMoveEnd(coords, zoom)});
        this.#mapView.setOnDefaultToolbarButtonClick(() => {this.onDefaultToolbarClick()});
        this.#mapView.setOnMoveToolbarButtonClick(() => {this.onMoveToolbarClick()});
        this.#mapView.setOnNodeToolbarButtonClick(() => {this.onNodeToolbarClick()});
        this.#mapView.setOnEdgeToolbarButtonClick(() => {this.onEdgeToolbarClick()});
        this.#mapView.setOnDeleteToolbarButtonClick(() => {this.onDeleteToolbarClick()});
        this.#mapView.setOnMapClick((coords) => {this.onMapClick(coords)});
        this.#mapView.setOnNodeClick((nodeID) => {this.onNodeClick(nodeID)});
        this.#mapView.setOnNodesConnectionClick((nodesConnection) => {this.onNodesConnectionClick(nodesConnection)});

        this.#skiResortModel.setOnSkiTrailsChangeEventListener(this.onSkiTrailsChange);
        this.#skiResortModel.mode.subscribe(mode => this.onSkiResortModeChange(mode));
        this.#skiResortModel.graph.subscribe(changes => this.onGraphChange(changes))

        this.#skiResortSimulationModel.pointA.subscribe(pointA => this.#mapView.renderSimulationPointA(pointA));
        this.#skiResortSimulationModel.pointB.subscribe(pointB => this.#mapView.renderSimulationPointB(pointB));
        this.#skiResortSimulationModel.setOnSimulationActiveStateChange(active => {
            this.#mapView.removeSimulationRoutePolyline();
        })
        this.#skiResortSimulationModel.subscribeToDrawRouteInvocation((coords, info) => {
            this.#mapView.removeSimulationRoutePolyline();
            this.#mapView.renderSimulationRoutePolyline(coords);    
        });
        this.#skiResortSimulationModel.subscribeToPulseElevationGridCellInvocation((coords, color, seconds) => {
            const elevationPoint = this.#skiResortModel.elevationGrid[coords[0]][coords[1]];
            this.#mapView.renderSimulationPulseElevationGridCellSquare([elevationPoint.lat, elevationPoint.lng], color, seconds);
        });
        this.#skiResortSimulationModel.subscribeToUserLocation((coords, shouldCameraFollow) => {
            this.#mapView.removeUserLocationCircle();
            this.#mapView.renderUserLocationCircle(coords);
            if (shouldCameraFollow) {
                this.#mapView.setCameraView(coords, this.#mapModel.zoom);
            }
        });
        this.#skiResortSimulationModel.subscribeToUserOrientation((compassHeading, shouldCameraFollow) => {
            if (shouldCameraFollow) {
                this.#mapView.setCameraOrientation(compassHeading);
            }
        })

        this.onCursorModeChange(this.#mapModel.cursorMode.getValue());

        this.renderSkiLifts();
        this.renderSkiTrails();
        // this.renderElevationGrid();
    }

    onGraphChange(changes) {
        if (this.#skiResortModel.mode.getValue() == 'model') {
            this.#mapView.removeGraphEdges();
            this.#mapView.renderGraphEdges(this.#skiResortModel);

            this.#mapView.removeGraphNodes();
            this.#mapView.renderGraphNodes(this.#skiResortModel);

            this.#mapView.removeSimulationSkiTrailsSurfaces();
        }
        if (this.#skiResortModel.mode.getValue() == 'simulation') {
            this.#mapView.removeGraphEdges();
            // this.#mapView.renderGraphEdges(this.#skiResortModel);

            this.#mapView.removeGraphNodes();
            // this.#mapView.renderGraphNodes(this.#skiResortModel);
            
            this.#mapView.removeSimulationSkiTrailsSurfaces();
            this.#mapView.renderSimulationSkiTrailsSurfaces(skiTrailsSurfaces);
        }
    }

    onMouseMove(coords) {
        if (this.#mapModel.cursorMode.getValue() == CursorMode.EDGE && this.#mapModel.selectedNodesIDs.length == 1) {
            const firstNode = this.#skiResortModel.graph.getNode(this.#mapModel.selectedNodesIDs[0]);
            this.#mapView.removeGraphEdgePreview();
            this.#mapView.renderGraphEdgePreview([firstNode.data.coords, coords])
        }else
        if (this.#mapModel.cursorMode.getValue() == CursorMode.MOVE && this.#mapModel.selectedNodesIDs.length == 1) {
            const firstNode = this.#skiResortModel.graph.getNode(this.#mapModel.selectedNodesIDs[0]);
            this.#mapView.removeGraphNodePreview();
            this.#mapView.renderGraphNodePreview(coords);
        }else{
            this.#mapView.removeGraphEdgePreview();
            this.#mapView.removeGraphNodePreview();
        }
    }

    onCameraMoveEnd(coords, zoom) {
        this.#mapModel.saveCameraInfo(coords, zoom);
    }

    onNodeClick(nodeID) {
        if (this.#skiResortModel.mode.getValue() == 'model' &&
            this.#mapModel.cursorMode.getValue() === CursorMode.EDGE) {
            if (this.#mapModel.selectedNodesIDs.includes(nodeID)) {
                this.#mapModel.selectedNodesIDs = [];
            }
    
            this.#mapModel.selectedNodesIDs.push(nodeID);
            if (this.#mapModel.selectedNodesIDs.length >= 2) {

                //create Edge
                this.#skiResortModel.graph.addEdge(
                    this.#skiResortModel.graph.getNode(this.#mapModel.selectedNodesIDs[0]),
                    this.#skiResortModel.graph.getNode(this.#mapModel.selectedNodesIDs[1]),
                    false,
                    {
                        weight: 0,
                        type: 'SkiTrail' 
                    }
                );

                this.#mapModel.selectedNodesIDs = [];
            }
        }else
        if (this.#mapModel.cursorMode.getValue() === CursorMode.DEFAULT) {
            this.#skiResortModel.addNodeToSelectedItems(nodeID);
        }else
        if (this.#skiResortModel.mode.getValue() == 'model' &&
            this.#mapModel.cursorMode.getValue() === CursorMode.MOVE) {
            this.#mapModel.selectedNodesIDs = [];
            if (this.#mapModel.selectedNodesIDs == 0) {
                this.#mapModel.selectedNodesIDs.push(nodeID);
            }else{
                this.#mapModel.selectedNodesIDs = [];
            }
        }
    }

    onNodesConnectionClick(nodesConnection) {
        if (this.#mapModel.cursorMode.getValue() === CursorMode.DEFAULT) {
            this.#skiResortModel.addNodesConnectionToSelectedItems(nodesConnection);
        }
    }

    onCursorModeChange(cursorMode) {
        switch(cursorMode) {
            case CursorMode.DEFAULT:
                this.#mapView.setDefaultToolbarButtonActive()
                break;
            case CursorMode.MOVE:
                this.#mapView.setMoveToolbarButtonActive()
                break;
            case CursorMode.NODE:
                this.#mapView.setNodeToolbarButtonActive()
                break;
            case CursorMode.EDGE:
                this.#mapView.setEdgeToolbarButtonActive()
                break;
            default:
                break;
        }
    }

    onDefaultToolbarClick() {
        this.#mapModel.cursorMode.setValue(CursorMode.DEFAULT);
    }

    onMoveToolbarClick() {
        this.#mapModel.cursorMode.setValue(CursorMode.MOVE);
    }

    onNodeToolbarClick() {
        this.#mapModel.cursorMode.setValue(CursorMode.NODE);
    }

    onEdgeToolbarClick() {
        this.#mapModel.cursorMode.setValue(CursorMode.EDGE);
    }

    onDeleteToolbarClick() {
        if (this.#skiResortModel.graphSelectedItems.getValue().length == 0) {
            return;
        }

        const selectedItem = this.#skiResortModel.graphSelectedItems.getValue()[0];

        if (selectedItem instanceof Node) {
            this.#skiResortModel.removeSelectedItems();
            this.#skiResortModel.graph.removeNode(selectedItem);
        }else
        if (selectedItem instanceof NodesConnection) {
            this.#skiResortModel.removeSelectedItems();
            this.#skiResortModel.graph.removeNodesConnection(selectedItem);
        }
    }

    onMapClick(coords) {
        if (this.#skiResortModel.mode.getValue() == 'simulation') {

            if (this.#skiResortSimulationModel.pointActiveIndex.getValue() != null) {
                const pointCoords = [coords.lat, coords.lng];

                if (this.#skiResortSimulationModel.pointActiveIndex.getValue() == 0) {
                    this.#skiResortSimulationModel.pointA.setValue(pointCoords);
                }else{
                    this.#skiResortSimulationModel.pointB.setValue(pointCoords);
                }
                this.#skiResortSimulationModel.pointActiveIndex.setValue(null);
            }

            return
        }

        if (this.#skiResortModel.mode.getValue() == 'model' &&
            this.#mapModel.cursorMode.getValue() === CursorMode.NODE) {
            const lat = coords.lat;
            const lng = coords.lng;

            const newNode = new Node({
                coords: [lat, lng]
            });

            this.#skiResortModel.graph.addNode(newNode);
        }else
        if (this.#mapModel.cursorMode.getValue() === CursorMode.DEFAULT) {
            this.#skiResortModel.removeSelectedItems();
        }else
        if (this.#mapModel.cursorMode.getValue() === CursorMode.MOVE) {
            if (this.#mapModel.selectedNodesIDs.length == 1) {
                const lat = coords.lat;
                const lng = coords.lng;

                const nodeToTranslate = this.#skiResortModel.graph.getNode(this.#mapModel.selectedNodesIDs[0]);
                nodeToTranslate.data.coords = [lat, lng];
                this.#mapModel.selectedNodesIDs = [];
                this.#skiResortModel.graph.notify();
            }
        }
    }

    onSkiResortModeChange(mode) {
        if (mode == 'base') {
            this.#mapView.removeSkiTrails();
            this.#mapView.removeSkiLifts();
            this.#mapView.removeElevationGrid();
            this.#mapView.removeGraphNodes();
            this.#mapView.removeGraphEdges();
            this.#mapView.removeToolbar();

            this.#mapView.renderSkiTrails(this.#skiResortModel);
            this.#mapView.renderSkiLifts(this.#skiResortModel);
            // this.#mapView.renderElevationGrid(this.#skiResortModel);
        }else
        if (mode == 'model') {
            this.#mapView.removeSkiTrails();
            this.#mapView.removeSkiLifts();
            this.#mapView.removeElevationGrid();
            this.#mapView.removeGraphNodes();
            this.#mapView.removeGraphEdges();
            this.#mapView.removeToolbar();

            this.#mapView.renderGraphNodes(this.#skiResortModel);
            this.#mapView.renderGraphEdges(this.#skiResortModel);
            this.#mapView.renderToolbar();
        }else
        if (mode == 'simulation') {
            this.#mapView.removeSkiTrails();
            this.#mapView.removeSkiLifts();
            this.#mapView.removeElevationGrid();
            this.#mapView.removeGraphNodes();
            this.#mapView.removeGraphEdges();
            this.#mapView.removeToolbar();

            // this.#mapView.renderGraphNodes(this.#skiResortModel);
            // this.#mapView.renderGraphEdges(this.#skiResortModel);
        }
    }

    onSkiTrailsChange = (skiTrails) => {
        this.renderSkiTrails();
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }

    setSkiResortSimulationModel(skiResortSimulationModel) {
        this.#skiResortSimulationModel = skiResortSimulationModel;
    }

    renderSkiTrails() {
        this.#mapView.renderSkiTrails(this.#skiResortModel);
    }

    renderSkiLifts() {
        this.#mapView.renderSkiLifts(this.#skiResortModel);
    }

    // renderElevationGrid() {
    //     this.#mapView.renderElevationGrid(this.#skiResortModel);
    // }

}

export default MapController;
