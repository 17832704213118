import Observable from '../Observable.js'

import skiingData28 from '../../assets/28.02.2023. Kopaonik.json';

class SkiResortSimulationModel {

    #simulationWorker = new Observable(null);

    pointActiveIndex = new Observable(null);

    pointA = new Observable(null);
    pointB = new Observable(null);

    #drawRouteInvocationListeners = [];
    #pulseElevationGridCellInvocationListeners = [];
    #userLocationListeners = [];
    #userOrientationListeners = [];

    //Demo App

    userLocation = null;

    following = new Observable(false);

    isOrientationListenerSetup = false;

    #mockIndex = 0;
    #userLocationInterval = 200;
    #userLocationIntervalId = null;

    #simulationRunning = new Observable(false);
    #simulationInterval = 500;
    #simulationIntervalId = null;

    workerRunning = false;

    init() {
        if (!navigator.geolocation) {
            alert('Geolocation is not supported by this browser.');
            return;
        }

        this.updateUserLocation();
        this.#userLocationIntervalId = setInterval(() => {
            this.updateUserLocation();
        }, this.#userLocationInterval);
    }

    updateUserLocation() {
        this.getUserLocation(coords => {
            const pointCoords = [coords.latitude, coords.longitude];
            this.notifyUserLocationListeners(pointCoords, this.following.getValue());
            // this.pointA.setValue(pointCoords)
            this.userLocation = pointCoords
        }, error => {
        });
    }

    #fetchingLocation = false;
    getUserLocation(successCb, errorCb) {
        if (this.#fetchingLocation) {
            return;
        }

        this.#fetchingLocation = true;

        const mock = window.location.pathname === '/dev';

        if (!mock) {
            navigator.geolocation.getCurrentPosition(pos => {
                this.#fetchingLocation = false;
                successCb(pos.coords)
            }, error => {
                this.#fetchingLocation = false;
                errorCb(error)
            }, {
                enableHighAccuracy: true,
                timeout: this.#userLocationInterval / 2,
                maximumAge: 0,
            });
        } else {
            this.#fetchingLocation = false;

            if (this.#mockIndex > skiingData28.features.length - 1) {
                this.#mockIndex = 0;
            }

            successCb({
                latitude: skiingData28.features[this.#mockIndex].geometry.coordinates[1],
                longitude: skiingData28.features[this.#mockIndex].geometry.coordinates[0]
            })

            this.#mockIndex = this.#mockIndex + 10;
        }
    }

    //Orientation
    setupOrientationListener() {
        window.addEventListener('deviceorientation', (event) => this.handleOrientationEvent(event), true);
    }

    handleOrientationEvent(event) {
        // var alpha = event.alpha;
        // var beta = event.beta;
        // var gamma = event.gamma;

        var compassHeading;

        if (event.webkitCompassHeading) {
            // Apple works only with this, alpha doesn't work
            compassHeading = event.webkitCompassHeading;
        }
        else
            compassHeading = event.alpha;

        // Adjust for landscape modes
        if (window.matchMedia("(orientation: landscape)").matches) {
            if (window.screen.orientation.angle === 90) {
                // Landscape-right
                compassHeading += 90;
            } else if (window.screen.orientation.angle === -90 || window.screen.orientation.angle === 270) {
                // Landscape-left
                compassHeading -= 90;
            }
        }

        // Ensure compassHeading is within 0-360 range
        compassHeading = (compassHeading + 360) % 360;

        // Invert the compass heading
        compassHeading = 360 - compassHeading;

        // if (event.webkitCompassHeading) {
        //     // Apple works only with this, alpha doesn't work
        //     compassHeading = event.webkitCompassHeading;
        // }
        // else
        //     compassHeading = event.alpha;

        // // Detect landscape mode
        // if (window.matchMedia("(orientation: landscape)").matches) {
        //     // Adjust for landscape mode
        //     compassHeading += 90;
        // }

        // // Ensure compassHeading is within 0-360 range
        // compassHeading = compassHeading % 360;

        // compassHeading = 360 - compassHeading;

        this.notifyUserOrientationListeners(compassHeading, this.following.getValue());
    }


    follow(follow) {
        if (follow && !this.isOrientationListenerSetup) {
            //Setup orientation mirroring
            if (window.DeviceOrientationEvent) {
                if (typeof DeviceOrientationEvent.requestPermission === 'function') {
                    // iOS 13+ device
                    DeviceOrientationEvent.requestPermission()
                        .then(response => {
                            if (response === 'granted') {
                                this.setupOrientationListener();
                                this.isOrientationListenerSetup = true;
                            } else {
                                console.error('Device Orientation permission denied');
                            }
                        })
                        .catch(error => alert(error));
                } else {
                    // Non-iOS 13 device or desktop browser
                    this.setupOrientationListener();
                    this.isOrientationListenerSetup = true;
                }
            } else {
                console.error('DeviceOrientationEvent is not supported in your browser.');
            }
        }

        this.following.setValue(follow);
    }
    //Orientation\

    subscribeToDrawRouteInvocation(listener) {
        this.#drawRouteInvocationListeners.push(listener)
    }

    subscribeToPulseElevationGridCellInvocation(listener) {
        this.#pulseElevationGridCellInvocationListeners.push(listener)
    }

    subscribeToUserLocation(listener) {
        this.#userLocationListeners.push(listener);
    }

    subscribeToUserOrientation(listener) {
        this.#userOrientationListeners.push(listener);
    }

    notifyDrawRouteInvocationListeners(geometry, info) {
        this.#drawRouteInvocationListeners.forEach(listener => listener(geometry, info))
    }

    notifyPulseElevationGridCellInvocationListeners(coords, color, seconds) {
        this.#pulseElevationGridCellInvocationListeners.forEach(listener => listener(coords, color, seconds))
    }

    notifyUserLocationListeners(coords, shouldCameraFollow) {
        this.#userLocationListeners.forEach(listener => listener(coords, shouldCameraFollow));
    }

    notifyUserOrientationListeners(orientation, shouldCameraFollow) {
        this.#userOrientationListeners.forEach(listener => listener(orientation, shouldCameraFollow));
    }

    #processMessage(message) {
        if (message.type == 'function-invocation') {
            this.#processFunctionInvocation(message.data);
        }
    }

    #processFunctionInvocation(data) {
        if (data.functionName == "drawRoute") {
            const coords = data.functionArgs.coords;
            const info = data.functionArgs.info;
            this.notifyDrawRouteInvocationListeners(coords, info);
        } else
            if (data.functionName == "pulseElevationGridCell") {
                const coords = data.functionArgs.coords;
                const color = data.functionArgs.color;
                const seconds = data.functionArgs.seconds;
                this.notifyPulseElevationGridCellInvocationListeners(coords, color, seconds);
            }
    }

    async startSimulation(code, initData) {
        this.#simulationRunning.setValue(true);
        this.#simulationIntervalId = setInterval(() => this.simulationUpdate(code, initData), this.#simulationInterval);
    }

    async simulationUpdate(code, initData) {
        if (this.workerRunning) {
            return;
        }

        if (this.#simulationWorker.getValue() != null) {
            this.#simulationWorker.getValue().terminate();
            this.#simulationWorker.setValue(null);
        }

        initData.pointA = this.userLocation;//this.pointA.getValue();

        try {
            this.workerRunning = true;
            // console.log("started working on it");
            //create worker
            const worker = new Worker(URL.createObjectURL(new Blob([code], { type: 'text/javascript' })));
            worker.onmessage = (e) => this.#processMessage(e.data);
            // worker.onerror = (event) => {
            //     console.error('An error occurred in the worker: ', event.message);
            //     event.preventDefault();

            //     this.stopSimulation();
            //     return;
            // };

            this.#simulationWorker.setValue(worker);

            this.#simulationWorker.getValue().postMessage(initData);
        } catch (e) {
            if (e.message === 'Failed to fetch') {
                alert('Failed to establish connection with script server.');
            }
            console.log(e);
        }
    }

    async stopSimulation() {
        this.workerRunning = false;
        if (this.#simulationWorker.getValue() != null) {
            this.#simulationWorker.getValue().terminate();
        }

        this.#simulationWorker.setValue(null);

        clearInterval(this.#simulationIntervalId);
        this.#simulationRunning.setValue(false);
    }

    setOnSimulationActiveStateChange(listener) {
        this.#simulationRunning.subscribe((value) => listener(value))
    }

    isSimulationActive() {
        return this.#simulationRunning.getValue();
    }

}

export default SkiResortSimulationModel;