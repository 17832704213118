import Observable from "../Observable.js";

class SimulationModel {

    init() {
        
    }
}

export default SimulationModel;
