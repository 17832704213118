import InstanceNavigationModel from "../models/InstanceNavigationModel.js";
import InstanceNavigationView from "../views/InstanceNavigationView.js";

class InstanceNavigationController {
    #instanceNavigationModel;
    #instanceNavigationView = new InstanceNavigationView();

    #skiResortModel;

    #setPageActive(pageID) {
        if (pageID == 'none-instance-page') {
            this.#instanceNavigationView.renderPage(this.#instanceNavigationModel.pages, 'none-instance-page');
            return;
        }

        const page = this.#instanceNavigationModel.pages.find(page => page[0] == pageID);
        page[2].onNavigation();
        this.#instanceNavigationView.renderPage(this.#instanceNavigationModel.pages, pageID);
    }

    constructor(pages) {
        this.#instanceNavigationModel = new InstanceNavigationModel(pages);
    }

    init() {
        this.#instanceNavigationModel.init();
        this.#instanceNavigationView.init();

        this.#instanceNavigationModel.setOnPageChangeEventListener(this.onInstanceNavigationChange);
        this.#skiResortModel.graphSelectedItems.subscribe((graphSelectedItems) => {
            if (graphSelectedItems.length >= 1) {
                const firstSelectedItem = graphSelectedItems[0];
                for (const page of this.#instanceNavigationModel.pages) {
                    const pageID = page[0];
                    const instanceType = page[3];
                    if (firstSelectedItem instanceof instanceType) {
                        this.#instanceNavigationModel.setActivePage(pageID);
                        break;
                    }
                }
            }else{
                this.#setPageActive('none-instance-page');
            }
        });

        //init all page controllers
        for (const page of this.#instanceNavigationModel.pages) {
            const pageController = page[2];
            pageController.init();
        }

        this.#setPageActive('none-instance-page');
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }

    onInstanceNavigationChange = (pageID) => {
        this.#setPageActive(pageID);
    }
}

export default InstanceNavigationController;
