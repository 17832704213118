import NodeInstanceModel from "../models/NodeInstanceModel.js";
import NodeInstanceView from "../views/NodeInstanceView.js"

class NodeInstanceController {
    #nodeInstanceModel = new NodeInstanceModel();
    #nodeInstanceView = new NodeInstanceView();

    #skiResortModel;

    init() {
        this.#nodeInstanceModel.init();
        this.#nodeInstanceView.init();
    }
    
    onNavigation() {
        this.#nodeInstanceView.renderNodeDataFields(this.#skiResortModel.graphSelectedItems.getValue()[0]);
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }

}

export default NodeInstanceController;