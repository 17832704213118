import $ from "jquery";

class HomeView {
    #skiTrailCheckChangeListeners = [];

    init() {

    }

    renderSkiTrails(skiResortModel) {
        $('#trails-list').empty();

        var sortedSkiTrails = [...skiResortModel.skiTrails].sort((a, b) => {
            let aName = a?.properties?.name || 'Nepoznata';
            let bName = b?.properties?.name || 'Nepoznata';

            if (typeof aName !== 'string') {
                aName = String(aName);
            }

            if (typeof bName !== 'string') {
                bName = String(bName);
            }

            return aName.localeCompare(bName, 'sr')
        });

        for (const skiTrail of sortedSkiTrails) {
            const fid = skiTrail.properties.fid;
            const name = skiTrail.properties.name;
            const visible = skiTrail.properties.visible;
            const filtered = skiTrail.properties.filtered;

            var rowElement = $(`
            <div class="list-group-item d-flex justify-content-between align-items-center">
                <div class="title">${name || 'Nepoznata'}</div>
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customCheck${fid}">
                    <label class="custom-control-label" for="customCheck${fid}"></label>
                </div>
            </div>
            `);
            rowElement.find("#customCheck" + skiTrail.properties.fid).prop("checked", visible);
            rowElement.find(`#customCheck${fid}`).on('change', (e) => {
                if ($('#customCheck' + fid).is(":checked")) {
                    this.#skiTrailCheckChangeListeners.forEach(listener => listener(fid, true));
                } else {
                    this.#skiTrailCheckChangeListeners.forEach(listener => listener(fid, false));
                }
            });

            if (filtered) {
                continue;
            }

            $('#trails-list').append(rowElement);
        }
    }

    setOnTrailsSearchEdit(listener) {
        $('#search-trails-input-field').keyup(function() {
            var inputValue = $(this).val();
            listener(inputValue);
        });  
    }

    setOnToggleAllButtonClick(listener) {
        $('#toggle-all-button').on('click', (e) => listener(e));
    }

    setOnSkiTrailCheckButtonClick(listener) {
        this.#skiTrailCheckChangeListeners.push(listener);
    }

}

export default HomeView;