
import SimulationModel from "../models/SimulationModel";
import SimulationView from "../views/SimulationView";

import prodRouteSolver from '../../assets/prod-routesolver.txt';

class SimulationController {
    #simulationModel = new SimulationModel();
    #simulationView = new SimulationView();

    #skiResortModel;
    #skiResortSimulationModel;

    init() {
        this.#simulationModel.init();
        this.#simulationView.init();

        this.#simulationView.setOnPointAButtonClick(() => this.onPointAButtonClick())
        this.#simulationView.setOnPointBButtonClick(() => this.onPointBButtonClick())
        this.#simulationView.setOnClearPointsButtonClick(() => this.onClearPointsClick())
        this.#simulationView.setOnFollowButtonClick(() => this.onFollowClick())
        this.#simulationView.setOnToggleSimulationButtonClick(() => this.onToggleSimulationClick());

        this.#skiResortSimulationModel.pointActiveIndex.subscribe(value => this.onPointActiveIndexChange(value))
        this.#skiResortSimulationModel.pointA.subscribe(value => this.#simulationView.renderPointAFilled(value != null))
        this.#skiResortSimulationModel.pointB.subscribe(value => this.#simulationView.renderPointBFilled(value != null))
        this.#skiResortSimulationModel.following.subscribe(following => {
            this.#simulationView.setFollowButtonMode(following);
        })
        this.#skiResortSimulationModel.setOnSimulationActiveStateChange((active) => {
            this.#simulationView.setToggleSimulationButtonMode(active);
        })
        this.#skiResortSimulationModel.subscribeToDrawRouteInvocation((coords, info) => {
            this.#skiResortSimulationModel.workerRunning = false;
            this.#simulationView.renderTimeAndDistance(info.time, info.distance);
        });
    }
    
    onNavigation() {
        this.#skiResortModel.mode.setValue('simulation');
        this.#skiResortSimulationModel.init();
    }

    onPointActiveIndexChange(value) {
        if (value == 0) {
            this.#simulationView.renderPointAOutline(true)
            this.#simulationView.renderPointBOutline(false)
        }else
        if (value == 1) {
            this.#simulationView.renderPointBOutline(true)
            this.#simulationView.renderPointAOutline(false)
        }else{
            this.#simulationView.renderPointAOutline(false)
            this.#simulationView.renderPointBOutline(false)
        }
    }

    async onPointAButtonClick() {
        if (this.#skiResortSimulationModel.pointActiveIndex.getValue() == 0) {
            this.#skiResortSimulationModel.pointActiveIndex.setValue(null);
            return
        }

        this.#skiResortSimulationModel.pointActiveIndex.setValue(0);
    }

    async onPointBButtonClick() {
        if (this.#skiResortSimulationModel.pointActiveIndex.getValue() == 1) {
            this.#skiResortSimulationModel.pointActiveIndex.setValue(null);
            return
        }

        this.#skiResortSimulationModel.pointActiveIndex.setValue(1);
    }

    async onClearPointsClick() {
        this.#skiResortSimulationModel.pointA.setValue(null)
        this.#skiResortSimulationModel.pointB.setValue(null)
        this.#skiResortSimulationModel.pointActiveIndex.setValue(null);
    }

    async onFollowClick() {
        if (this.#skiResortSimulationModel.following.getValue()) {
            this.#skiResortSimulationModel.follow(false);
        }else{
            this.#skiResortSimulationModel.follow(true);
        }
    }

    async onToggleSimulationClick() {
        if (this.#skiResortSimulationModel.isSimulationActive()) {
            this.#skiResortSimulationModel.stopSimulation();
        }else{
            //fetch script

            var url = null;
            if (process.env.NODE_ENV === 'development') {
                url = 'http://localhost:4001/';
            }else{
                url = prodRouteSolver;
            }
            
            const response = await fetch(url);
            if (!response.ok) {
                alert('Error ' + response.status);
                return
            }
            const code = await response.text();

            //create context
            let initData = {
                graph: await this.#skiResortModel.graph.getSkiResortModel(),
                elevationGrid: this.#skiResortModel.elevationGrid,
                pointA: this.#skiResortSimulationModel.pointA.getValue(),
                pointB: this.#skiResortSimulationModel.pointB.getValue()
            };

            this.#skiResortSimulationModel.startSimulation(code, initData);
        }
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }

    setSkiResortSimulationModel(skiResortSimulationModel) {
        this.#skiResortSimulationModel = skiResortSimulationModel;
    }
}

export default SimulationController;
