import $ from "jquery";

class NodeInstanceView {

    init() {

    }

    renderNodeDataFields(node) {
        console.log(node);
        $('#node-id-input-field').val(node.id);
        $('#node-latitude-input-field').val(node.data.coords[0]);
        $('#node-longitude-input-field').val(node.data.coords[1]);
    }

}

export default NodeInstanceView;