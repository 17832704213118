import skiTrailsRawData from '../lines.json';
import $ from "jquery";
import Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';

class GraphModelView {

    #fileUploadListeners = [];

    notifyFileUploadListeners(file) {
        this.#fileUploadListeners.forEach(listener => listener(file));
    }

    init() {
        $('#open-button').on('click', () => {
            $('#fileInput').click();
        });

        $('#fileInput').change((e) => {
            let file = e.target.files[0];
            this.notifyFileUploadListeners(file);
            e.target.value = '';
        });
    }
    
    renderCode(code) {
        $('#jsonCode').text(code);
        // Prism.highlightAll();
    }

    setOnFileUpload(listener) {
        this.#fileUploadListeners.push(listener);
    }

    setOnImportFromBaseButtonClick(listener) {
        $('#import-from-base-button').on('click', () => listener());
    }

    setOnExportButtonClick(listener) {
        $('#export-button').on('click', () => listener());
    }

    setOnClearButtonClick(listener) {
        $('#clear-button').on('click', () => listener());
    }
    
    setOnCalculateWeightsButtonClick(listener) {
        $('#calculate-weights-button').on('click', () => listener());
    }

}

export default GraphModelView;
