import Observable from "../Observable.js";

class MapModel {
    lat
    lng
    zoom

    //toolbar selected/clicked item
    cursorMode = new Observable(CursorMode.DEFAULT);
    selectedNodesIDs = [];

    constructor(lat, lng, zoom) {
        this.lat = lat;
        this.lng = lng;
        this.zoom = zoom;
    }

    init() {
        const cameraInfo = this.getCameraInfo();

        if (cameraInfo != null) {
            this.lat = cameraInfo.coords[0];
            this.lng = cameraInfo.coords[1];
            this.zoom = cameraInfo.zoom
        }
    }

    saveCameraInfo(cameraCoords, cameraZoom) {
        const cameraInfo = {
            coords: cameraCoords,
            zoom: cameraZoom
        };

        const cameraInfoJSON = JSON.stringify(cameraInfo);

        localStorage.setItem("camera_info", cameraInfoJSON);
    }

    getCameraInfo() {
        const cameraInfoJSON = localStorage.getItem("camera_info");
        const cameraInfo = JSON.parse(cameraInfoJSON);
        return cameraInfo;
    }

}

class CursorMode {
    static DEFAULT = new CursorMode(0, 'DEFAULT');
    static MOVE = new CursorMode(1, 'MOVE');
    static NODE = new CursorMode(2, 'NODE');
    static EDGE = new CursorMode(3, 'EDGE');

    constructor(value, name) {
        this.value = value;
        this.name = name;
    }

    toString() {
        return this.name
    }

    valueOf() {
        return this.value;
    }

    static fromValue(value) {
        switch(value) {
        case 0: 
        case "0":
            return CursorMode.DEFAULT;
        case 1: 
        case "1":
            return CursorMode.MOVE;
        case 2:
        case "2":
            return CursorMode.NODE;
        case 3: 
        case "3":
            return CursorMode.EDGE;
        default: throw new Error('Invalid value for CursorMode: ' + value);
        }
    }

    static fromName(name) {
        switch(name) {
        case 'DEFAULT': return Mode.DEFAULT;
        case 'MOVE': return Mode.MOVE;
        case 'NODE': return Mode.NODE;
        case 'EDGE': return Mode.EDGE;
        default: throw new Error('Invalid name for CursorMode: ' + name);
        }
    }
}

export { CursorMode };
export default MapModel;
