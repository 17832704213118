import $ from "jquery";

class SimulationView {

    init() {


    }
    
    setFollowButtonMode(follow) {
        if (follow) {
            $("#follow-button").text("Stop Following");
        }else{
            $("#follow-button").text("Follow");
        }
    }

    setToggleSimulationButtonMode(start) {
        if (start) {
            $("#toggle-simulation-button").removeClass("btn-primary")
            $("#toggle-simulation-button").addClass("btn-danger")
            $("#toggle-simulation-button").text("Stop");
        }else{
            $("#toggle-simulation-button").removeClass("btn-danger")
            $("#toggle-simulation-button").addClass("btn-primary")
            $("#toggle-simulation-button").text("Start");
        }
    }

    renderPointAOutline(render) {
        if (render) {
            $("#point-a-button").addClass("btn-dark");
        }else{
            $("#point-a-button").removeClass("btn-dark");
        }
    }

    renderPointAFilled(filled) {
        if (filled) {
            $("#point-a-button").addClass("btn-primary");
            $("#point-a-button").removeClass("btn-light");
        }else{
            $("#point-a-button").addClass("btn-light");
            $("#point-a-button").removeClass("btn-primary");
        }
    }

    renderPointBOutline(render) {
        if (render) {
            $("#point-b-button").addClass("btn-dark");
        }else{
            $("#point-b-button").removeClass("btn-dark");
        }
    }

    renderPointBFilled(filled) {
        if (filled) {
            $("#point-b-button").addClass("btn-primary");
            $("#point-b-button").removeClass("btn-light");
        }else{
            $("#point-b-button").addClass("btn-light");
            $("#point-b-button").removeClass("btn-primary");
        }
    }

    renderTimeAndDistance(time, distance) {
        $('#time-label').text("Time: " + time.toFixed(2) + " min");
        $('#distance-label').text("Distance: " + distance.toFixed(2) + "m");
    }

    setOnPointAButtonClick(listener) {
        $('#point-a-button').on('click', () => listener());
    }

    setOnPointBButtonClick(listener) {
        $('#point-b-button').on('click', () => listener());
    }

    setOnClearPointsButtonClick(listener) {
        $('#clear-points-button').on('click', () => listener());
    }

    setOnFollowButtonClick(listener) {
        $('#follow-button').on('click', () => listener());
    }

    setOnToggleSimulationButtonClick(listener) {
        $('#toggle-simulation-button').on('click', () => listener());
    }

}

export default SimulationView;
