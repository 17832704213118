import GraphModelModel from "../models/GraphModelModel";
import GraphModelView from "../views/GraphModelView";

class GraphModelController {
    #graphModelModel = new GraphModelModel();
    #graphModelView = new GraphModelView();

    #skiResortModel;

    init() {
        this.#graphModelModel.init();
        this.#graphModelView.init();

        // this.#graphModelView.setOnOpenButtonClick(() => this.onOpenClick());
        this.#graphModelView.setOnFileUpload(file => this.onFileUpload(file));
        this.#graphModelView.setOnImportFromBaseButtonClick(() => this.onImportFromBaseClick());
        this.#graphModelView.setOnExportButtonClick(() => this.onExportClick());
        this.#graphModelView.setOnClearButtonClick(() => this.onClearClick());
        this.#graphModelView.setOnCalculateWeightsButtonClick(() => this.onCalculateWeightsClick());

        this.#skiResortModel.graph.subscribe(changes => this.onGraphChange(changes))
    }
    
    onNavigation() {
        this.#skiResortModel.mode.setValue('model');
    }

    onGraphChange(changes) {
        this.#skiResortModel.graph.getJSON().then(json => {
            this.#graphModelView.renderCode(json);
        });
    }

    onFileUpload(file) {
        let reader = new FileReader();

        reader.onload = (e) => {
            let fileContent = e.target.result;
            this.#skiResortModel.graph.loadFromJSON(fileContent);
        };

        reader.readAsText(file);
    }

    onImportFromBaseClick() {
        this.#skiResortModel.graph.importFromBase(this.#skiResortModel.skiTrails, this.#skiResortModel.skiLifts);
    }

    async onExportClick() {
        let data = await this.#skiResortModel.graph.getJSON();
        let blob = new Blob([data], {type: 'text/application/json'});
        let url = URL.createObjectURL(blob);
        let downloadLink = document.createElement('a');
        downloadLink.download = 'SkiResort.json';
        downloadLink.href = url;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    onClearClick() {
        if(confirm("Are you sure you want to delete this graph?")) {
            this.#skiResortModel.graph.clear();
        }
    }

    onCalculateWeightsClick() {
        for (const nodesConnection of this.#skiResortModel.graph.getNodesConnections()) {
            const weight = this.#skiResortModel.graph.getDistanceM(nodesConnection.data.firstNode, nodesConnection.data.secondNode);

            var firstEdgeProperties = nodesConnection.data.properties[0];
            firstEdgeProperties.weight = weight;

            this.#skiResortModel.graph.setEdgeProperties(
                nodesConnection.data.firstNode,
                nodesConnection.data.secondNode,
                firstEdgeProperties);

            if (nodesConnection.data.bidirectional) {
                var secondEdgeProperties = nodesConnection.data.properties[1];
                secondEdgeProperties.weight = weight;

                this.#skiResortModel.graph.setEdgeProperties(
                    nodesConnection.data.secondNode,
                    nodesConnection.data.firstNode,
                    secondEdgeProperties);
            }
        }

        this.#skiResortModel.graph.notify();
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }
}

export default GraphModelController;
