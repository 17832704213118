import Observable from '../Observable.js'

class SkiResortModel {

    mode = new Observable(null);

    //-- BASE --
    skiTrails = []
    #skiTrailsChangeListeners = [];

    skiLifts = []
    #skiLiftsChangeListeners = [];

    //2d array
    elevationGrid = [];

    notifySkiTrailsChange() {
        this.#skiTrailsChangeListeners.forEach(listener => listener(this.skiTrails));
    }

    setOnSkiTrailsChangeEventListener(listener) {
        this.#skiTrailsChangeListeners.push(listener);
    }

    searchFilter(input) {
        this.skiTrails.forEach(skiTrail => {
            var name = skiTrail?.properties?.name || 'Nepoznata';

            if (typeof name !== 'string') {
                name = String(name);
            }

            input = input.toLowerCase();
            name = name.toLowerCase();

            const nameCyrilic = name;
            const nameLatin = this.convertCyrillicToLatin(name);
            const nameTrimmedLatin = this.convertCyrillicToTrimmedLatin(name);

            if (nameCyrilic.includes(input) || nameLatin.includes(input) || nameTrimmedLatin.includes(input)) {
                skiTrail.properties.filtered = false;
            }else{
                skiTrail.properties.filtered = true;
            }
        });
        this.notifySkiTrailsChange();
    }

    setSkiTrailVisibility(fid, visibility) {
        const skiTrail = this.skiTrails.find(skiTrail => skiTrail.properties.fid === fid);
        skiTrail.properties.visible = visibility;

        this.notifySkiTrailsChange();
    }

    convertCyrillicToLatin(text) {
        let cyrillic = ['а', 'б', 'в', 'г', 'д', 'ђ', 'е', 'ж', 'з', 'и', 'ј', 'к', 'л', 'љ', 'м', 'н', 'њ', 'о', 'п', 'р', 'с', 'т', 'ћ', 'у', 'ф', 'х', 'ц', 'ч', 'џ', 'ш'];
        let latin = ['a', 'b', 'v', 'g', 'd', 'đ', 'e', 'ž', 'z', 'i', 'j', 'k', 'l', 'lj', 'm', 'n', 'nj', 'o', 'p', 'r', 's', 't', 'ć', 'u', 'f', 'h', 'c', 'č', 'dž', 'š'];
    
        return text.split('').map(function (char) {
            let index = cyrillic.indexOf(char.toLowerCase());
            return index !== -1 ? latin[index] : char;
        }).join('');
    }

    convertCyrillicToTrimmedLatin(text) {
        let cyrillic = ['а', 'б', 'в', 'г', 'д', 'ђ', 'е', 'ж', 'з', 'и', 'ј', 'к', 'л', 'љ', 'м', 'н', 'њ', 'о', 'п', 'р', 'с', 'т', 'ћ', 'у', 'ф', 'х', 'ц', 'ч', 'џ', 'ш'];
        let latin = ['a', 'b', 'v', 'g', 'd', 'dj', 'e', 'z', 'z', 'i', 'j', 'k', 'l', 'lj', 'm', 'n', 'nj', 'o', 'p', 'r', 's', 't', 'c', 'u', 'f', 'h', 'c', 'c', 'dz', 's'];
    
        return text.split('').map(function (char) {
            let index = cyrillic.indexOf(char.toLowerCase());
            return index !== -1 ? latin[index] : char;
        }).join('');
    }

    //-- BASE -- END

    //-- GRAPH MODEL -- 

    graph = new Graph();
    graphSelectedItems = new Observable([]);

    addNodeToSelectedItems(nodeID) {
        const node = this.graph.getNode(nodeID);
        this.graphSelectedItems.setValue([node]);
    }

    addNodesConnectionToSelectedItems(nodesConnection) {
        this.graphSelectedItems.setValue([nodesConnection]);
    }

    removeSelectedItems() {
        this.graphSelectedItems.setValue([]);
    }

    //-- GRAPH MODEL -- END

}

class Node {
    id;
    data;

    static generateRandomId() {
        return Math.floor(Math.random() * Date.now()).toString(36);
    }
    
    constructor(data) {
        this.id = Node.generateRandomId();
        this.data = data;
    }
    
}

class NodesConnection {
    constructor(data) {
        this.data = data;
    }
}

class Graph {
    //old
    //nodeA: [nodeB, nodeC, ...]

    //new:
    //nodeA: [{adjNode: nodeB, properties: {weight: 1, type: 'SkiTrail'}}, {adjNode: nodeC, ...}, ...]

    //This is called edge
    //{adjNode: nodeB, properties: {weight: 1, type: 'SkiTrail'}}
    nodes = new Map();
    #observers = [];
    
    subscribe(listener) {
        this.#observers.push(listener);
    }
    
    notify(changes) {
        this.#observers.forEach(listener => listener(changes));
    }

    //This function alongside with importFrombase should be placed in Graph Application.
    //Because they're specific use of graph.
    getDistanceM(node1, node2) {
        var deg2rad = function(deg) {
            return deg * (Math.PI/180)
        }

        const lat1 = node1.data.coords[0];
        const lon1 = node1.data.coords[1];
        const lat2 = node2.data.coords[0];
        const lon2 = node2.data.coords[1];
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1); 
        var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d * 1000;
    }

    #addNodeToMap(node) {
        if (this.nodes.has(node)) {
            return;
        }
        this.nodes.set(node, []);
    }
    
    #addEdgesToMap(source, destination, bidirectional, properties) {
        if (this.nodes.get(source).find(edge => edge.adjNode == destination)) {
            return;
        }
        
        this.nodes.get(source).push({
            adjNode: destination,
            properties: properties
        });
        if (bidirectional) {
            this.nodes.get(destination).push({
                adjNode: source,
                properties: properties
            });    
        }
    }

    clear() {
        this.nodes = new Map();
        this.notify();
    }
    
    loadFromJSON(json) {
        if (json == null) {
            return
        }
        const skiResort = JSON.parse(json);

        if (skiResort == null) {
            return;
        }
        
        this.nodes = new Map();

        for (const [nodeID, data] of Object.entries(skiResort.nodes)) {
            const node = new Node(data);
            node.id = nodeID;
            this.#addNodeToMap(node);
        }
        
        for (const [nodeID, edges] of Object.entries(skiResort.graph)) {
            const source = this.getNode(nodeID);
        
            for (const [adjNodeID, properties] of Object.entries(edges)) {
                const destination = this.getNode(adjNodeID);
        
                this.#addEdgesToMap(source, destination, false, properties);
            }
        }

        this.notify([]);
    }

    importFromBase(skiTrails, skiLifts) {
        this.nodes = new Map();

        //join nodes that have same coords.

        var simpleHash = function (str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                const charCode = str.charCodeAt(i);
                hash = ((hash << 5) - hash) + charCode;
                hash = hash & hash; // Convert to 32-bit integer
            }
            return hash.toString(16);
        }

        var getPointHash = function(lat, lng) {
            return simpleHash("lat" + lat.toString() + "lng" + lng.toString());
        }

        var tempGraph = {};
        var tempNodes = {};

        for (const feature of skiTrails) {
            // console.log(feature.properties['piste:difficulty']);
            const coordinates = feature.geometry.coordinates;

            let prevPointHash = null;

            for (const coordsRev of coordinates) {
                const coords = [coordsRev[1], coordsRev[0]];

                const pointHash = getPointHash(coords[0], coords[1]);

                if (!tempGraph[pointHash]) {
                    tempGraph[pointHash] = [];
                    tempNodes[pointHash] = new Node({
                        coords: coords
                    });
                }

                if (prevPointHash != null && !tempGraph[prevPointHash].find(edge => edge.adjHash == pointHash)) {
                    var difficulty = undefined;
                    if (feature.properties['piste:difficulty'] == 'novice') {
                        difficulty = 0;
                    }
                    if (feature.properties['piste:difficulty'] == 'easy') {
                        difficulty = 1;
                    }
                    if (feature.properties['piste:difficulty'] == 'intermediate') {
                        difficulty = 2;
                    }
                    tempGraph[prevPointHash].push({
                        adjHash: pointHash,
                        properties: {
                            name: feature.properties.name,
                            type: 'SkiTrail',
                            difficulty: difficulty
                        }
                    });
                }

                prevPointHash = pointHash;
            }
        }

        for (const feature of skiLifts) {
            const coordinates = feature.geometry.coordinates;

            let prevPointHash = null;

            for (const coordsRev of coordinates) {
                const coords = [coordsRev[1], coordsRev[0]];

                const pointHash = getPointHash(coords[0], coords[1]);

                if (!tempGraph[pointHash]) {
                    tempGraph[pointHash] = [];
                    tempNodes[pointHash] = new Node({
                        coords: coords
                    });
                }

                if (prevPointHash != null && !tempGraph[prevPointHash].find(edge => edge.adjHash == pointHash)) {
                    tempGraph[prevPointHash].push({
                        adjHash: pointHash,
                        properties: {
                            name: feature.properties.name,
                            type: 'SkiLift'
                        }
                    });
                }

                prevPointHash = pointHash;
            }
        }

        for (const node of Object.values(tempNodes)) {
            this.#addNodeToMap(node);
        }

        for (const [pointHash, node] of Object.entries(tempNodes)) {
            const edges = tempGraph[pointHash].map(edge => {
                return {
                    adjNode: tempNodes[edge.adjHash],
                    properties: edge.properties
                };
            });

            for (const edge of edges) {
                this.#addEdgesToMap(node, edge.adjNode, false, {
                    weight: this.getDistanceM(node, edge.adjNode),
                    type: edge.properties.type,
                    name: edge.properties.name,
                    difficulty: edge.properties.difficulty
                });
            }
        }

        this.notify([]);
    }
    
    addNode(node) {
        this.#addNodeToMap(node);
        this.notify();
        // this.notify([
        //     {
        //         change: "add",
        //         type: "nodes",
        //         items: [
        //             node
        //         ]
        //     }
        // ]);
    }
    
    addEdge(source, destination, bidirectional = false, properties) {
        this.#addEdgesToMap(source, destination, bidirectional, properties);
        this.notify();
    }

    setEdgeProperties(source, destination, properties) {
        let edge = this.nodes.get(source).find(edge => edge.adjNode == destination);
        edge.properties = properties;
    }

    removeNode(node) {
        this.nodes.delete(node);
        this.nodes.forEach((edges, node2) => {
            const filteredEdges = edges.filter(edge => edge.adjNode !== node);
            this.nodes.set(node2, filteredEdges);
        });
        this.notify();
    }
    
    removeEdge(source, destination, bidirectional = false) {
        this.nodes.set(source,
            this.nodes.get(source).filter(edge => edge.adjNode !== destination)
        );
        if (bidirectional) {
            this.nodes.set(destination,
                this.nodes.get(destination).filter(edge => edge.adjNode !== source)
            );
        }
        this.notify();
    }

    removeNodesConnection(nodesConnection) {
        this.removeEdge(
            nodesConnection.data.firstNode, 
            nodesConnection.data.secondNode,
            nodesConnection.data.bidirectional
        );
    }
    
    getNode(id) {
        for (let node of this.nodes.keys()) {
          if (node.id === id) {
            return node;
          }
        }
        return null;
    }
    
    getNodes() {
        return this.nodes;
    }

    getNodesConnections() {
        var nodePairs = [];

        for (const [node, edges] of this.nodes) {
            for (const edge of edges) {
                const adjNode = edge.adjNode;
                const bidirectional = this.nodes.get(adjNode).find(edge => edge.adjNode == node) != null;
                nodePairs.push([node, adjNode, bidirectional, edge.properties]);
            }
        }

        var nodesConnections = [];

        for (const nodePair of nodePairs) {
            var bidirectional = nodePair[2];
            
            var nodeConnectionCreated = nodesConnections.find(nc =>
                nc.data.firstNode === nodePair[0] && nc.data.secondNode === nodePair[1] || 
                nc.data.firstNode === nodePair[1] && nc.data.secondNode === nodePair[0]
            );
            
            if (!nodeConnectionCreated) {
                const nodesConnection = new NodesConnection({
                    firstNode: nodePair[0],
                    secondNode: nodePair[1],
                    bidirectional: bidirectional,
                    properties: [nodePair[3]]
                });
                nodesConnections.push(nodesConnection);
            }else{
                nodeConnectionCreated.data.properties.push(nodePair[3]);
            }
        }

        return nodesConnections;
    }

    async getJSON() {
        const skiResort = this.getSkiResortModel();

        const json = await JSON.stringify(skiResort, null, 2);

        return json;
    }

    getSkiResortModel() {
        var graphJSON = {};

        for (const [node, edges] of this.nodes) {
            const edgesJSON = {};

            for (const edge of edges) {
                edgesJSON[edge.adjNode.id] = edge.properties;
            }

            graphJSON[node.id] = edgesJSON;
        }

        const nodesJSON = {};

        for (const node of this.nodes.keys()) {
            nodesJSON[node.id] = node.data || {};
        }

        const skiResort = {
            nodes: nodesJSON,
            graph: graphJSON
        };

        return skiResort;
    }

    hasEdge(source, destination, bidirectional = false) {
        return this.nodes.get(source).find(edge => edge.adjNode == destination) != null && (!bidirectional || this.nodes.get(destination).find(edge => edge.adjNode == source) != null);
    }
    
}

export { Graph, Node, NodesConnection };
export default SkiResortModel;