import $ from "jquery";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-textpath';
import 'leaflet-polylinedecorator'
import '../../../vendor/leaflet/leaflet-rotate.js';

const dev = false;
const showSkiTrailName = false;

class MapView {
    #map
    #mouseMoveListeners = [];
    #cameraMoveEndListeners = [];

    #skiTrailsPolylines = [];
    #skiTrailsCircles = [];

    #skiLiftsPolylines = [];
    #skiLiftsCircles = [];

    #graphNodesCircles = [];
    #graphEdgesPolylines = [];
    #graphEdgesPolylinesDecorators = [];

    #graphEdgePreviewPolyline = null;
    #graphNodePreviewCircle = null;

    #graphNodesClickListeners = [];
    #graphNodesConnectionsClickListeners = [];

    #skiTrailsSurfacesShape = null;
    #simulationPointACircle = null;
    #simulationPointBCircle = null;
    #simulationRoutePolyline = null;

    #simulationUserLocationCircle = null;

    notifyNodeClick(nodeID) {
        this.#graphNodesClickListeners.forEach(listener => listener(nodeID));
    }

    notifyNodesConnectionClick(nodesConnection) {
        this.#graphNodesConnectionsClickListeners.forEach(listener => listener(nodesConnection));
    }

    init(mapModel) {
        this.#map = L.map('map', { rotate: true }).setView([mapModel.lat, mapModel.lng], mapModel.zoom);

        //init reverse arrow
        L.Symbol.ReverseArrow = L.Symbol.ArrowHead.extend({
            buildSymbol: function (dirPoint, latLngs, map, index, total) {
                dirPoint.heading += 180;
                return L.Symbol.ArrowHead.prototype.buildSymbol.call(this, dirPoint, latLngs, map, index, total);
            }
        });
        L.Symbol.reverseArrow = function (options) {
            return new L.Symbol.ReverseArrow(options);
        };

        if (dev) {
            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
            }).addTo(this.#map);
        } else {
            L.tileLayer('https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY29meWthIiwiYSI6ImNsanVndGkzaTFjbDkzZGxsMDc2czc5NTQifQ.FeCqFu9aCroMuHXUKeHT9g', {
                maxZoom: 19,
            }).addTo(this.#map);
            // var overlay = L.marker([51.5, -0.09]).addTo(this.#map);
        }

        $('#toolbar').on('mousedown mousemove mouseup', function (event) {
            event.stopPropagation();
        });
        $('#toolbar .btn').click(function (event) {
            event.stopPropagation();
        });

        this.#map.on('mousemove', (e) => {
            this.notifyMouseMove([e.latlng.lat, e.latlng.lng])
        });
        this.#map.on('moveend', () => {
            setTimeout(() => {
                this.#graphNodesCircles.forEach(circle => circle.bringToFront());
            }, 1);

            const center = this.#map.getCenter();
            const zoomLevel = this.#map.getZoom();

            this.notifyCameraMoveEnd([center.lat, center.lng], zoomLevel);
        });
    }

    removeSkiTrails() {
        this.#skiTrailsPolylines.forEach(polyline => {
            this.#map.removeLayer(polyline);
        });

        this.#skiTrailsCircles.forEach(circle => {
            this.#map.removeLayer(circle);
        });

        this.#skiTrailsPolylines = [];
        this.#skiTrailsCircles = [];
    }

    renderSkiTrails(skiResortModel) {
        this.removeSkiTrails();

        for (const skiTrail of skiResortModel.skiTrails) {
            const visible = skiTrail.properties.visible;
            const filtered = skiTrail.properties.filtered;
            var name = skiTrail?.properties?.name || "";
            var difficulty = skiTrail?.properties?.difficulty;

            if (typeof name !== 'string') {
                name = String(name);
            }

            if (visible == false || filtered) {
                continue;
            }
            const coords = skiTrail.geometry.coordinates.map(latLng => [latLng[1], latLng[0]]);

            //CIRCLES
            coords.forEach(coord => {
                const circle = L.circle(coord, {
                    color: 'gray',
                    fillColor: 'white',
                    fillOpacity: 1,
                    radius: 5
                }).addTo(this.#map);
                circle.bringToBack();

                // Store the circle in the array for later removal.
                this.#skiTrailsCircles.push(circle);
            });

            //POLYLINE
            const polyline = L.polyline(coords,
                {
                    color: "white",
                    weight: 5,
                    smoothFactor: 1,
                }
            ).addTo(this.#map);
            if (showSkiTrailName) {
                polyline.setText(name, {
                    repeat: false,
                    offset: 10,
                    center: true,
                    attributes: {
                        fill: 'white',
                        'font-size': '12px'
                    }
                });
            }
            polyline.bringToBack();

            this.#skiTrailsPolylines.push(polyline);

        }
    }

    removeSkiLifts() {
        this.#skiLiftsPolylines.forEach(polyline => {
            this.#map.removeLayer(polyline);
        });

        this.#skiLiftsCircles.forEach(circle => {
            this.#map.removeLayer(circle);
        });

        this.#skiLiftsPolylines = [];
        this.#skiLiftsCircles = [];
    }

    renderSkiLifts(skiResortModel) {

        for (const skiLift of skiResortModel.skiLifts) {
            // const visible = skiTrail.properties.visible;
            // const filtered = skiTrail.properties.filtered;
            // if (visible == false || filtered) {
            //     continue;
            // }
            const coords = skiLift.geometry.coordinates.map(latLng => [latLng[1], latLng[0]]);

            //POLYLINE
            const polyline = L.polyline(coords,
                {
                    color: "rgb(40, 113, 244)",
                    weight: 2,
                    smoothFactor: 1
                }
            ).addTo(this.#map);

            this.#skiLiftsPolylines.push(polyline);

            //CIRCLES
            coords.forEach((coord, index) => {
                let type = skiLift.properties.types[index];

                const circle = L.circle(coord, {
                    color: type == "entry" ? '#32de84' : '#de3232',
                    fillColor: 'rgb(40, 113, 244)',
                    fillOpacity: 0.75,
                    radius: 10
                }).addTo(this.#map);

                // Store the circle in the array for later removal.
                this.#skiLiftsCircles.push(circle);
            });

        }
    }

    removeElevationGrid() {

    }

    renderElevationGrid(skiResortModel) {
        // console.log('render')
        // console.log(skiResortModel.elevationGrid);
        var halt = 0;

        for (const row of skiResortModel.elevationGrid) {
            if (halt == 100) {
                break;
            }

            for (const elevationPoint of row) {
                // console.log(elevationPoint);

                if (halt == 100) {
                    break;
                }

                const circle = L.circle([elevationPoint.lat, elevationPoint.lng], {
                    color: 'purple',
                    fillColor: 'purple',
                    fillOpacity: 1,
                    radius: 5
                }).addTo(this.#map);

                halt = halt + 1;
            }
        }
    }

    removeGraphNodes() {
        this.#graphNodesCircles.forEach(circle => {
            this.#map.removeLayer(circle);
        });

        this.#graphNodesCircles = [];
    }

    renderGraphNodes(skiResortModel) {
        for (let [node, adjacencyNodes] of skiResortModel.graph.getNodes()) {
            const circle = L.circle(node.data.coords, {
                color: 'gray',
                fillColor: 'white',
                fillOpacity: 1,
                radius: 5
            }).addTo(this.#map);
            circle.nodeID = node.id;
            circle.on('click', (e) => {
                this.notifyNodeClick(e.target.nodeID);
                L.DomEvent.stopPropagation(e);
            })

            this.#graphNodesCircles.push(circle);
        }
    }

    removeGraphEdges() {
        this.#graphEdgesPolylines.forEach(polyline => {
            this.#map.removeLayer(polyline);
        });

        this.#graphEdgesPolylinesDecorators.forEach(polylineDecorator => {
            this.#map.removeLayer(polylineDecorator);
        });

        this.#graphEdgesPolylines = [];
        this.#graphEdgesPolylinesDecorators = [];
    }

    renderGraphEdges(skiResortModel) {
        for (const nodesConnection of skiResortModel.graph.getNodesConnections()) {
            const firstNode = nodesConnection.data.firstNode;
            const secondNode = nodesConnection.data.secondNode;
            const bidirectional = nodesConnection.data.bidirectional;
            const properties = nodesConnection.data.properties;

            const properties1 = properties[0];

            //POLYLINE
            const polyline = L.polyline([firstNode.data.coords, secondNode.data.coords],
                {
                    color: properties1.type == 'SkiTrail' ? "white" : "rgb(40, 113, 244)",
                    weight: 5,
                    smoothFactor: 1
                }
            ).addTo(this.#map);
            polyline.nodesConnection = nodesConnection;
            var arrowHead;
            var arrowHeadColor = 'rgb(40, 113, 244)';
            if (properties1.difficulty == 0) {
                arrowHeadColor = 'blue';
            } else
                if (properties1.difficulty == 1) {
                    arrowHeadColor = 'red';
                } else
                    if (properties1.difficulty == 2) {
                        arrowHeadColor = 'black';
                    }
            if (bidirectional) {
                arrowHead = L.polylineDecorator(polyline, {
                    patterns: [
                        // This defines an arrow at the start of the line.
                        {
                            offset: '10',
                            repeat: 50,
                            symbol: L.Symbol.reverseArrow({
                                pixelSize: 10,
                                polygon: false,
                                pathOptions: { stroke: true },
                            })
                        },
                        // This defines an arrow at the end of the line.
                        {
                            offset: '0',
                            repeat: 50,
                            symbol: L.Symbol.arrowHead({
                                pixelSize: 7,
                                polygon: false,
                                pathOptions: { color: arrowHeadColor, stroke: true },
                            }),
                        },
                    ],
                }).addTo(this.#map);
            } else {
                arrowHead = L.polylineDecorator(polyline, {
                    patterns: [
                        {
                            offset: '0',
                            repeat: 50,
                            symbol: L.Symbol.arrowHead({
                                pixelSize: 10,
                                polygon: false,
                                pathOptions: { color: arrowHeadColor, stroke: true },
                            }),
                        },
                    ],
                }).addTo(this.#map);
            }
            polyline.bringToBack();

            polyline.on('click', (e) => {
                this.notifyNodesConnectionClick(e.target.nodesConnection);
                L.DomEvent.stopPropagation(e);
            })

            this.#graphEdgesPolylines.push(polyline);
            this.#graphEdgesPolylinesDecorators.push(arrowHead);
        }
    }

    removeGraphEdgePreview() {
        if (this.#graphEdgePreviewPolyline) {
            this.#map.removeLayer(this.#graphEdgePreviewPolyline);
            this.#graphEdgePreviewPolyline = null;
        }
    }

    renderGraphEdgePreview(coords) {
        //POLYLINE
        const polyline = L.polyline([coords[0], coords[1]],
            {
                interactive: false,
                color: "white",
                weight: 5,
                smoothFactor: 1,
            }
        ).addTo(this.#map);
        this.#graphEdgePreviewPolyline = polyline;
    }

    removeGraphNodePreview() {
        if (this.#graphNodePreviewCircle) {
            this.#map.removeLayer(this.#graphNodePreviewCircle);
            this.#graphNodePreviewCircle = null;
        }
    }

    renderGraphNodePreview(coords) {
        const circle = L.circle(coords, {
            interactive: false,
            color: 'gray',
            fillColor: 'white',
            fillOpacity: 0.5,
            radius: 5
        }).addTo(this.#map);
        this.#graphNodePreviewCircle = circle;
    }

    removeSimulationSkiTrailsSurfaces() {
        if (this.#skiTrailsSurfacesShape) {
            this.#map.removeLayer(this.#skiTrailsSurfacesShape);
        }
    }

    renderSimulationSkiTrailsSurfaces(surfaces) {
        this.#skiTrailsSurfacesShape = L.geoJSON(
            surfaces,
            {
                style: function (feature) {
                    return {
                        stroke: false, // This disables the stroke (outline) of the shape
                        fillColor: 'white', // Optionally, set a fill color
                        fillOpacity: 0.7 // Optionally, set the fill opacity
                    };
                }
            }
        ).addTo(this.#map);
    }

    renderSimulationPointA(pointA) {
        this.removeSimulationPointACircle();

        if (pointA) {
            this.renderSimulationPointACircle(pointA)
        }
    }

    renderSimulationPointB(pointB) {
        this.removeSimulationPointBCircle();

        if (pointB) {
            this.renderSimulationPointBCircle(pointB)
        }
    }

    removeSimulationPointACircle() {
        if (this.#simulationPointACircle) {
            this.#map.removeLayer(this.#simulationPointACircle);
            this.#simulationPointACircle = null;
        }
    }

    renderSimulationPointACircle(pointA) {
        const circle = L.circle(pointA, {
            interactive: false,
            color: 'green',
            fillColor: 'green',
            fillOpacity: 0.5,
            radius: 20
        }).addTo(this.#map);
        circle.bindTooltip("A", {
            permanent: true,
            direction: 'center',
            className: 'text-label',
        });
        this.#simulationPointACircle = circle;
    }

    removeUserLocationCircle() {
        if (this.#simulationUserLocationCircle) {
            this.#map.removeLayer(this.#simulationUserLocationCircle);
            this.#simulationUserLocationCircle = null;
        }
    }

    renderUserLocationCircle(userLocation) {
        const circle = L.circle(userLocation, {
            interactive: false,
            color: '#6495ED',
            fillColor: '#0096FF',
            fillOpacity: 0.5,
            radius: 20
        }).addTo(this.#map);
        circle.bindTooltip("🏂🏻", {
            permanent: true,
            direction: 'center',
            className: 'text-label',
        });
        this.#simulationUserLocationCircle = circle;
    }

    removeSimulationPointBCircle() {
        if (this.#simulationPointBCircle) {
            this.#map.removeLayer(this.#simulationPointBCircle);
            this.#simulationPointBCircle = null;
        }
    }

    renderSimulationPointBCircle(pointB) {
        const circle = L.circle(pointB, {
            interactive: false,
            color: 'red',
            fillColor: 'red',
            fillOpacity: 0.5,
            radius: 20
        }).addTo(this.#map);
        circle.bindTooltip("B", {
            permanent: true,
            direction: 'center',
            className: 'text-label',
        });
        this.#simulationPointBCircle = circle;
    }

    removeSimulationRoutePolyline() {
        if (this.#simulationRoutePolyline) {
            this.#map.removeLayer(this.#simulationRoutePolyline);
            this.#simulationRoutePolyline = null;
        }
    }

    renderSimulationRoutePolyline(coords) {
        //POLYLINE
        const polyline = L.polyline(coords,
            {
                color: "#6495ED",
                weight: 10,
                smoothFactor: 1
            }
        ).addTo(this.#map);
        this.#simulationRoutePolyline = polyline;
    }

    renderSimulationPulseElevationGridCellSquare(coords, color, seconds) {
        //lat width     0.00027777778057469504
        //lng height    0.0002777777784821467
        var topLeft = [coords[0] - 0.00027777778057469504 / 2, coords[1] + 0.0002777777784821467 / 2];
        var bottomRight = [coords[0] + 0.00027777778057469504 / 2, coords[1] - 0.0002777777784821467 / 2];

        const rectangle = L.rectangle([topLeft, bottomRight], {
            color: color,
            weight: 1,
            fillOpacity: 1,
            fillColor: color
        }).addTo(this.#map);

        // const circle = L.circle(coords, {
        //     color: 'purple',
        //     fillColor: 'purple',
        //     fillOpacity: 1,
        //     radius: 5
        // }).addTo(this.#map);

        // Variables for the fade animation
        var opacityStep = - (1 / (1000 / 100) / seconds); // How much to change the opacity each frame

        // Start the fade animation
        var fadeInterval = setInterval(function () {
            // Decrease the opacity
            var currentOpacity = rectangle.options.fillOpacity;
            var newOpacity = currentOpacity + opacityStep;

            // If the circle has fully faded, stop the animation
            if (newOpacity <= 0) {
                clearInterval(fadeInterval);
                rectangle.remove();
                // circle.remove();
                return;
            }

            // Set the new opacity
            rectangle.setStyle({ fillOpacity: newOpacity });
            // circle.setStyle({ fillOpacity: newOpacity });
        }, 100); // Adjust this to change the speed of the fade

    }

    setCameraView(coords, zoom) {
        this.#map.setView(coords, this.#map.zoomLevel);
    }

    setCameraOrientation(compassHeading) {
        this.#map.setBearing(compassHeading);
    }

    removeToolbar() {
        $('#toolbar').hide();
    }

    renderToolbar() {
        $('#toolbar').show();
    }

    setOnDefaultToolbarButtonClick(listener) {
        $('#default-toolbar-button').on('click', listener);
    }

    setOnMoveToolbarButtonClick(listener) {
        $('#move-toolbar-button').on('click', listener);
    }

    setOnNodeToolbarButtonClick(listener) {
        $('#node-toolbar-button').on('click', listener);
    }

    setOnEdgeToolbarButtonClick(listener) {
        $('#edge-toolbar-button').on('click', listener);
    }

    setOnDeleteToolbarButtonClick(listener) {
        $('#delete-toolbar-button').on('click', listener);
    }

    setOnMapClick(listener) {
        this.#map.on('click', (e) => listener(e.latlng));
    }

    setOnNodeClick(listener) {
        this.#graphNodesClickListeners.push(listener);
    }

    setOnNodesConnectionClick(listener) {
        this.#graphNodesConnectionsClickListeners.push(listener);
    }

    //toolbar button active

    setDefaultToolbarButtonActive() {
        $('#toolbar svg path').css('fill', 'black');
        $('#default-toolbar-button').find('svg path').css('fill', 'rgb(40, 113, 244)');
    }

    setMoveToolbarButtonActive() {
        $('#toolbar svg path').css('fill', 'black');
        $('#move-toolbar-button').find('svg path').css('fill', 'rgb(40, 113, 244)');
    }

    setNodeToolbarButtonActive() {
        $('#toolbar svg path').css('fill', 'black');
        $('#node-toolbar-button').find('svg path').css('fill', 'rgb(40, 113, 244)');
    }

    setEdgeToolbarButtonActive() {
        $('#toolbar svg path').css('fill', 'black');
        $('#edge-toolbar-button').find('svg path').css('fill', 'rgb(40, 113, 244)');
    }

    setDeleteToolbarButtonActive() {
        $('#toolbar svg path').css('fill', 'black');
        $('#delete-toolbar-button').find('svg path').css('fill', 'rgb(40, 113, 244)');
    }

    //

    notifyMouseMove(coords) {
        this.#mouseMoveListeners.forEach(listener => listener(coords))
    }

    setOnMouseMoveEvent(listener) {
        this.#mouseMoveListeners.push(listener);
    }

    notifyCameraMoveEnd(coords, zoom) {
        this.#cameraMoveEndListeners.forEach(listener => listener(coords, zoom))
    }

    setOnCameraMoveEndEvent(listener) {
        this.#cameraMoveEndListeners.push(listener);
    }

}

export default MapView;
