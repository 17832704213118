import $ from "jquery";

class InstanceNavigationView {

    init() {
    }

    renderPage(pages, pageID) {
        for (const page of pages) {
            let pageID = page[0];
            
            const pageElement = $(`#${pageID}`);
            pageElement.css("visibility", "hidden");
            pageElement.hide();
        }

        const pageElement = $(`#none-instance-page`);
        pageElement.css("visibility", "hidden");
        pageElement.css("display", "none");

        if (pageID == "none-instance-page") {
            const activePageElement = $(`#${pageID}`);
            activePageElement.css("visibility", "visible");
            activePageElement.css('display', "flex");
        }else{
            const activePageElement = $(`#${pageID}`);
            activePageElement.css("visibility", "visible");
            activePageElement.show();
        }
    }

}

export default InstanceNavigationView;