import NodesConnectionInstanceModel from "../models/NodesConnectionInstanceModel.js";
import NodesConnectionInstanceView from "../views/NodesConnectionInstanceView.js"

class NodesConnectionInstanceController {
    #nodesConnectionInstanceModel = new NodesConnectionInstanceModel();
    #nodesConnectionInstanceView = new NodesConnectionInstanceView();

    #skiResortModel;

    init() {
        this.#nodesConnectionInstanceModel.init();
        this.#nodesConnectionInstanceView.init();

        this.#nodesConnectionInstanceView.setOnFirstEdgeTypeSelectionEvent((selection) => this.onFirstEdgeTypeSelection(selection));
        this.#nodesConnectionInstanceView.setOnSecondEdgeTypeSelectionEvent((selection) => this.onSecondEdgeTypeSelection(selection));
        this.#nodesConnectionInstanceView.setOnTextEditEvent((edgeIndex, key, value) => {
            console.log(edgeIndex, key, value)
            const nodesConnection = this.#skiResortModel.graphSelectedItems.getValue()[0];

            const firstNode = nodesConnection.data.firstNode;
            const secondNode = nodesConnection.data.secondNode;
    
            const properties = nodesConnection.data.properties[0];
            properties[key] = value
    
            this.#skiResortModel.graph.setEdgeProperties(firstNode, secondNode, properties);
            this.#skiResortModel.graph.notify();
        })
    }
    
    onNavigation() {
        this.#nodesConnectionInstanceView.renderNodesConnectionDataFields(this.#skiResortModel.graphSelectedItems.getValue()[0]);
    }

    onFirstEdgeTypeSelection(selection) {
        const nodesConnection = this.#skiResortModel.graphSelectedItems.getValue()[0];

        const firstNode = nodesConnection.data.firstNode;
        const secondNode = nodesConnection.data.secondNode;

        const properties = nodesConnection.data.properties[0];
        properties.type = selection

        this.#skiResortModel.graph.setEdgeProperties(firstNode, secondNode, properties);
        this.#skiResortModel.graph.notify();
    }

    onSecondEdgeTypeSelection(selection) {
        const nodesConnection = this.#skiResortModel.graphSelectedItems.getValue()[0];

        const firstNode = nodesConnection.data.firstNode;
        const secondNode = nodesConnection.data.secondNode;

        const properties = nodesConnection.data.properties[1];
        properties.type = selection

        this.#skiResortModel.graph.setEdgeProperties(secondNode, firstNode, properties);
        this.#skiResortModel.graph.notify();
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }

}

export default NodesConnectionInstanceController;