import HomeModel from '../models/HomeModel.js';
import HomeView from '../views/HomeView.js';

class HomeController {
    #homeModel = new HomeModel();
    #homeView = new HomeView();

    #skiResortModel;

    init() {
        this.#homeModel.init();
        this.#homeView.init();

        this.#homeView.setOnTrailsSearchEdit(this.onTrailsSearch);
        this.#homeView.setOnToggleAllButtonClick(this.onToggleAllClick);
        this.#homeView.setOnSkiTrailCheckButtonClick(this.onSkiTrailCheckClick)
        this.#skiResortModel.setOnSkiTrailsChangeEventListener(this.onSkiTrailsChange);

        this.renderSkiTrails();
    }

    onNavigation() {
        this.#skiResortModel.mode.setValue('base');
    }

    onTrailsSearch = (input) => {
        this.#skiResortModel.searchFilter(input);
    }

    onToggleAllClick = (e) => {

    }

    onSkiTrailCheckClick = (fid, check) => {
        this.#skiResortModel.setSkiTrailVisibility(fid, check);
    }

    onSkiTrailsChange = (skiTrails) => {
        this.renderSkiTrails();
    }

    setSkiResortModel(skiResortModel) {
        this.#skiResortModel = skiResortModel;
    }

    renderSkiTrails() {

        this.#homeView.renderSkiTrails(this.#skiResortModel);
    }

}

export default HomeController;
